import React from 'react';
import "./TopBar.scss"
import {Space} from "../../comp-shared/Space";
import jwtDecode from "jwt-decode";
import {parseJWT} from "../../util/loginManager";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
export const TopBar = () => {
  const navigate = useNavigate();
  const accessToken = parseJWT();
  
  const onClickLogout = () => {
    let cookie = new Cookies();
    cookie.remove("accessToken", {path: "/"});
    cookie.remove("refreshToken", {path: "/"});
    navigate("/")
  };
  
  return (
    <div className="TopBar container">
      <div className="container-inbox">
        <Space/>
        {
          accessToken.user === undefined ?<>
            <button className="topbar-button" style={{marginRight: "10px"}}
              onClick={()=>navigate("/user/login")}>로그인</button>
            <button className="topbar-button"
              onClick={()=>navigate("/user/join")}>회원가입</button>
          </>:
          <>
            <div className="text">{accessToken.username}님 환영합니다!</div>
            {accessToken["admin-viewer"] == true && 
                <button className="topbar-button" 
                        style={{marginRight:"10px"}}
                        onClick={()=>window.location.href = "/admin"}>현황</button>}
            <button className="topbar-button" onClick={onClickLogout}>로그아웃</button>
          </>
        }
      </div>
    </div>
  )
}

