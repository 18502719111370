import React from 'react';
import "./Map.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import infoIcon from "../../resources/images/info.svg";
import seoulMap from "../../resources/images/seoul_region_map.png";
import busanMap from "../../resources/images/busan_region_map.png";
import jejuMap from "../../resources/images/jeju_region_map.png";

export const Map = () => {
  return (
    <div className="Map">
      <PageTitle title="찾아오시는 길"/>
      <div className="section-title">
        <img src={infoIcon}/> <span>서울지방항공청</span>
      </div>
      <img className="map-image" src={seoulMap}/>
      <div className="info-grid">
        <div className="info-grid-header">주소</div>
        <div className="info-grid-description">인천광역시 중구 공항로424번길 47 6층 서울지방항공청</div>
        <div className="info-grid-header">문의전화</div>
        <div className="info-grid-description">서울 (032-740-2237)
          <br/>인천·충청 (032-740-2235)
          <br/>경기·강원·전북 (032-740-2233)</div>
        <div className="info-grid-header">팩스</div>
        <div className="info-grid-description">032-740-2119</div>
      </div>
      
      <div className="line"/>
      
      <div className="section-title">
        <img src={infoIcon}/> <span>부산지방항공청</span>
      </div>
      <img className="map-image" src={busanMap}/>
      <div className="info-grid">
        <div className="info-grid-header">주소</div>
        <div className="info-grid-description">부산광역시 강서구 공항진입로 108 김해국제공항건물 관리청사 2층</div>
        <div className="info-grid-header">문의전화</div>
        <div className="info-grid-description">부산·경북·경남 (051-974-2173)
          <br/>전남 (051-974-2191</div>
      </div>
      <div className="line"/>

      <div className="section-title">
        <img src={infoIcon}/> <span>제주지방항공청</span>
      </div>
      <img className="map-image" src={busanMap}/>
      <div className="info-grid">
        <div className="info-grid-header">주소</div>
        <div className="info-grid-description">제주특별자치도 제주시 공항로 2 제주국제공항(용담2동) 제주지방항공청</div>
        <div className="info-grid-header">문의전화</div>
        <div className="info-grid-description">064-797-1789</div>
      </div>
      <div style={{marginBottom: "100px"}}/>
    </div>
  )
}

