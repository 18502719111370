import './InquiryBox.scss'
export const InquiryBox = () => {
  return (
    <div className="InquiryBox pc-only">
      <span className="title">고객문의 전화</span>
      <br/>
      <br/>
      <span className="subtitle">- 항공장애표시등 민원 관련 문의</span>
      <br/>
      <span className="subtitle">  * 서울지방항공청:</span>
      <br/>
      <span className="subtitle">  &nbsp;- 서울 (032-740-2280)</span>
      <br/>
      <span className="subtitle">  &nbsp;- 경기·충청 (032-740-2233)</span>
      <br/>
      <span className="subtitle">  &nbsp;- 인천·강원·전북 (032-740-2237)</span>
      <br/>
      <br/>
      <span className="subtitle">  * 부산지방항공청:</span>
      <br/>
      <span className="subtitle">  &nbsp;- 부산·경북·경남 (051-974-2173)</span>
      <br/>
      <span className="subtitle">  &nbsp;- 전남 (051-974-2191)</span>
      <br/>
      <br/>
      <span className="subtitle">  * 제주지방항공청: 064-797-1789</span>
      <br/>
      <br/>
      <span className="subtitle">- 시스템 관련 문의: 042-824-6340</span>
    </div>
  )
}