import React from 'react';
import "./BrokenListPopup.scss"
import PopupPanel from "./PopupPanel";
import {useSWRBrokenMngCardList} from "../../server/server";
import {useLocalStorage} from "usehooks-ts";
import moment from "moment";

interface Props{
  isOpen:boolean;
  onClose:()=>void;
}
export const BrokenListPopup = (props:Props) => {
  const {data:brokenList} = useSWRBrokenMngCardList();
  const [lastClosed,setLastClosed] = useLocalStorage<string|null>("brokenListPopup",null)
  if(lastClosed === moment().format("YYYY-MM-DD")) return null;

  const onClickNoShowToday = () => {
    setLastClosed(moment().format("YYYY-MM-DD"))
  };
  
  return (
    <PopupPanel isOpen={props.isOpen} title="항공장애표시등 고장개소 현황" onClose={props.onClose}>
      <div className="BrokenListPopup">
        <div className="list">
          <table className="table">
            <thead>
              <tr style={{position:"sticky",top:"0"}}>
                <th>명칭</th>
                <th>주소</th>
                <th>높이(m)</th>
              </tr>
            </thead>
            <tbody>
              {brokenList?.map((item, idx)=>(
                <tr key={idx}>
                  <td>{item.obstNm}</td>
                  <td>{item.obstAddr}</td>
                  <td>{item.hgtFromGrnd}</td>
                </tr>
              ))}
            </tbody>
          </table>
          
        </div>
        <div className="noshow">
          <button className="button" onClick={onClickNoShowToday}>
          오늘 하루 안보이기
          </button>
        </div>
      </div>
    </PopupPanel>
  )
}

