import React, {useState} from 'react';
import "./MobileFoldMenu.scss"
import {Space} from "../../../comp-shared/Space";
interface Props{
  children:React.ReactNode;
  title:string;
}

export const MobileFoldMenu = (props:Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <div className="MobileFoldMenu" onClick={toggleMenu}>
      <div className="menu-header">
        <div className="title">{props.title}</div>
        <Space/>
        <div className="arrow">{isOpen? "▼":"▲"}</div>
      </div>
    </div>
    {isOpen ?
      <div className="content">{props.children}</div>
      : null
    }
    </>
  )
}

