import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from 'react';
import "./RestoreList.scss"
import {useNavigate} from "react-router-dom";
import {useSWRMyCompletedBrokenComplaint} from "../../server/server";
import {checkLoginAndRedirect} from "../../util/loginManager";
import {GetMyComplaintRes} from "../../server/user/myComplaintDto";
import {PageTitle} from "../../comp-shared/PageTitle";
import {AviationAgency, DeclarationKind} from "../../server/code";
import {timeToKoreanDate} from "../../util/timeutil";
import ListPager from "../../comp-shared/ListPager";
import clsx from "clsx";
import SearchIcon from "../../resources/icons/search_FILL0_wght400_GRAD0_opsz48.svg";

export const RestoreList = () => {
  const navigate = useNavigate();
  const [isMngCardOpen, setIsMngCardOpen] = useState(false)
  const [mngCardNum, setMngCardNum] = useState<string>("")
  const [page,setPage] = useState<number>(0)
  const {data:myComplaint} = useSWRMyCompletedBrokenComplaint(page)

  useEffect(() => {
    checkLoginAndRedirect(navigate)
  },[]);

  const onClickToRestoreComplaint = (complaint: GetMyComplaintRes) => {
    navigate(`/broken/restore/cvlcpt/${complaint.id}`)
  }

  const onChangeMngCardNum = (e: ChangeEvent<HTMLInputElement>) => setMngCardNum(e.target.value)

  const onKeyDownSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") onClickToRestoreByMngCardNum();
  }

  const onClickToRestoreByMngCardNum = () => {
    if (mngCardNum === "") return alert("관리번호를 입력해주세요.");
    navigate(`/broken/restore/mng-card/${mngCardNum}`)
  }

  return (
    <div className="RestoreList">
      <PageTitle title="복구완료 통지"/>
      <div className="flex tab-bar mb">
        <div className={clsx({"selected": !isMngCardOpen})} onClick={() => setIsMngCardOpen(false)}>
          민원접수번호로 검색
        </div>
        <div className={clsx({"selected": isMngCardOpen})} onClick={() => setIsMngCardOpen(true)}>
          관리번호로 검색
        </div>
      </div>
      {!isMngCardOpen && <>
          <table className="table clickable">
              <thead>
              <tr>
                  <th>접수번호</th>
                  <th>종류</th>
                  <th>항공청</th>
                  <th>신청일</th>
              </tr>
              </thead>
              <tbody>
              {myComplaint?.content
                .filter(complaint => complaint.dclrCd === "B" && complaint.stts === "S020800000")
                .map(complaint =>
                  <tr key={complaint.id} onClick={() => onClickToRestoreComplaint(complaint)}>
                    <td>{complaint.cvlcptNo}</td>
                    <td>{DeclarationKind[complaint.dclrCd]}</td>
                    <td>{AviationAgency[complaint.aviationAgencyCd]}</td>
                    <td>{timeToKoreanDate(complaint.regDt)}</td>
                  </tr>)}
              </tbody>
          </table>
          <ListPager page={page} pageItem={myComplaint} onChangePage={setPage}/></>}
      {isMngCardOpen && <div className="search-by-MngCard-area">
          <input className="input"
                 placeholder="관리번호 입력 (관리번호를 모를 경우 각 지방청 담당자에게 문의해주세요)"
                 value={mngCardNum}
                 onChange={onChangeMngCardNum}
                 onKeyDown={onKeyDownSearch}
                 name="mngCardNum"
          />
          <button className="button-circle mr" onClick={onClickToRestoreByMngCardNum}>
              <img src={SearchIcon} width={25} alt="search-icon"/>
          </button>
      </div>}
    </div>
  )
}

