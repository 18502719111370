import React, {ChangeEvent, useState} from 'react';
import "./JoinDetail.scss"
import {Space} from "../../../comp-shared/Space";
import {JoinReq} from "../../../server/user/joinDto";
import {types} from "sass";
import Number = types.Number;
import axios from "axios";
import {requestURL} from "../../../config";
import {Await, useNavigate} from "react-router-dom";
import DaumPostcodeEmbed, {Address, useDaumPostcodePopup} from "react-daum-postcode";
import {DaumAddressPopup} from "../../../comp-shared/Popup/DaumAddressPopup";
import {parseErrorMessage} from "../../../util/errorParser";

export const JoinDetail = () => {
  const isOpenAddressPopup = useDaumPostcodePopup();
  const navigate = useNavigate();
  
  const [joinReq, setJoinReq] = useState<JoinReq>({
    acnt: "",addr:null,eml:"",pswd:"",nm:"",coNm:null,zip:null,brno:null
  })
  const [passwordConfirm, setPasswordConfirm] = useState<string>("")
  const [isCheckAccountDup, setIsCheckAccountDup] = useState<boolean>(false)
  const [isCheckEmailDup, setIsCheckEmailDup] = useState<boolean>(false)
  
  const onChangeId = (e:ChangeEvent<HTMLInputElement>) => {
    if (isCheckAccountDup) setIsCheckAccountDup(false);
    setJoinReq({...joinReq, acnt: e.target.value});
  }

  const onChangePassword = (e:ChangeEvent<HTMLInputElement>) =>
    setJoinReq({...joinReq, pswd: e.target.value});
  const onChangePasswordConfirm = (e:ChangeEvent<HTMLInputElement>) =>
    setPasswordConfirm(e.target.value);
  const onChangeEmail = (e:ChangeEvent<HTMLInputElement>) => {
    if (isCheckEmailDup) setIsCheckEmailDup(false);
    setJoinReq({...joinReq, eml: e.target.value});
  }
  const onChangeName = (e:ChangeEvent<HTMLInputElement>) =>
    setJoinReq({...joinReq,nm:e.target.value});
  const onChangeAddress = (e:ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setJoinReq({...joinReq, addr: value != ""? value: null });
  }
  const onChangeCompanyName = (e:ChangeEvent<HTMLInputElement>) =>{
    let value = e.target.value;
    setJoinReq({...joinReq,coNm: value != ""? value: null});
  }
  const onChangeRegNumber = (e:ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setJoinReq({...joinReq, brno: value != ""? value: null});
  }
  
  const onClickCheckDuplicateId = async () => {
    if (joinReq.acnt == "") {alert("ID를 입력해주세요.");return;}
    try {
      let res = await axios.get(requestURL + `/user/check-duplicate-id/${joinReq.acnt}`)
      if (res.data == false) {
        alert("사용 가능한 ID입니다.")
        setIsCheckAccountDup(true);
      } else {
        alert("이미 사용중인 ID입니다.")
      }
    }catch (e){
      alert("에러가 발생했습니다.")
    }
  };

  const onClickCheckDuplicateEmail = async () => {
    if (joinReq.eml == "") {alert("이메일을 입력해주세요.");return;}
    if (!joinReq.eml.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)){
      alert("이메일 형식을 확인해 주세요.");return;
    }
    
    try {
      let res = await axios.get(requestURL + `/user/check-duplicate-email/${joinReq.eml}`)
      if (res.data == false) {
        alert("사용 가능한 이메일입니다.")
        setIsCheckEmailDup(true);
      } else {
        alert("이미 사용중인 이메일입니다.")
      }
    }catch (e){
      alert("에러가 발생했습니다.")
    }
  };
  
  const onSelectAddress = (address:Address) => {
    setJoinReq({...joinReq,zip:parseInt(address.zonecode),addr:address.address})
  }

  const onClickAddress = () => {
    isOpenAddressPopup({onComplete:onSelectAddress});
  };


  const onClickRegisterButton = async () => {
    if (!isCheckAccountDup) {alert("ID 중복확인을 해주세요.");return;}
    if (!isCheckEmailDup) {alert("이메일 중복확인을 해주세요.");return;}
    if (joinReq.pswd !== passwordConfirm) {alert("비밀번호와 비밀번호 확인이 다릅니다.");return;}
    try {
      await axios.post("/user/join", joinReq)
      alert("가입이 완료되었습니다.")
      navigate("/user/login")
    } catch (e) {
      alert(parseErrorMessage(e))
    }
  };
  
  return (
    <>
    <div className="JoinDetail">
      <div className="large-form-container">
        <div className="large-form-title">회원 필수정보 입력</div>
        <div className="large-form-content">
          <div className="field-title">ID</div>
          <div className="field">
            <input className="input mb full-width" placeholder="아이디"
                   value={joinReq.acnt} onChange={onChangeId}/>
            <button className="button-outline" style={{width:"100px", marginLeft:"10px"}} 
                    disabled={isCheckAccountDup}
                    onClick={onClickCheckDuplicateId}>중복확인</button>
          </div>
          <div className="field-title">비밀번호</div>
          <div className="field">
            <input className="input mb full-width" type="password"
                   value={joinReq.pswd} onChange={onChangePassword}
                   placeholder="8~20자 영문+숫자+특수문자 조합"/>
          </div>
          <div className="field-title">비밀번호 확인</div>
          <div className="field">
            <input className="input mb full-width" type="password" 
                   value={passwordConfirm} onChange={onChangePasswordConfirm}
                   placeholder="비밀번호 확인"/>
          </div>
          <div className="field-title">이메일</div>
          <div className="field">
            <input className="input mb full-width" placeholder="이메일"
                   value={joinReq.eml} onChange={onChangeEmail}
            />
            <button className="button-outline" style={{width:"100px", marginLeft:"10px"}}
                    disabled={isCheckEmailDup} onClick={onClickCheckDuplicateEmail}
            >중복확인</button>
          </div>
          <div className="field-title">이름</div>
          <div className="field">
            <input className="input mb full-width" placeholder="이름" value={joinReq.nm} onChange={onChangeName}/>
          </div>
        </div>
      </div>

      <div className="line"/>

      <div className="large-form-container">
        <div className="large-form-title">회원 선택정보 입력</div>
        <div className="large-form-content">
          <div className="field-title">법인명</div>
          <div className="field">
            <input className="input mb full-width" placeholder="oo주식회사 대표이사"
                   value={joinReq.coNm ?? undefined} onChange={onChangeCompanyName}/>
          </div>
          <div className="field-title">우편번호</div>
          <div className="field">
            <input className="input mb" placeholder="우편번호" readOnly={true} 
                   onClick={onClickAddress}
                   value={joinReq.zip ?? undefined}
                   style={{width:"100px", marginRight:"10px"}}/>
            <input className="input mb full-width" placeholder="상세주소"
                   value={joinReq.addr ?? undefined} onChange={onChangeAddress}/>
          </div>
          <div className="field-title">사업자등록번호</div>
          <div className="field">
            <input className="input mb full-width" placeholder="숫자만 입력"
                   value={joinReq.brno ?? undefined} onChange={onChangeRegNumber}/>
          </div>
        </div>
      </div>
      
      <div className="bottom-bar">
        <Space/>
        <button className="button" style={{marginRight:"10px"}}
        onClick={onClickRegisterButton}>가입</button>
        <button className="button" onClick={()=>navigate("/user/login")}>취소</button>
      </div>
    </div>
    </>
  )
}

