import React from "react";
import {QuestionBtn} from "./QuestionBtn";

export const Question1 = (props: {
  qnaCheck: string
  setQnaCheck: (value: string) => void;
}) => {
  return (
    <div>
      <h3>설치하고자 하는 대상이 아래의 어느 구역에 위치하나요?</h3>
      <div className="detail-block">
        <QuestionBtn value={'1'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'장애물 제한구역 안'}/>
        <QuestionBtn value={'2'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'장애물 제한구역 밖'}/>
        <QuestionBtn value={'3'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'비행안전구역'}/>
      </div>
    </div>
  )
}