import React, {useEffect} from 'react';
import "./Join.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Policy} from "./Join/Policy";
import {JoinDetail} from "./Join/JoinDetail";
import {parseJWT} from "../../util/loginManager";

export const Join = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = parseJWT();
    if (accessToken.user != undefined) navigate("/")
  });
  
  return (
    <div className="Join">
      <PageTitle title="회원가입"/>
      <Routes>
        <Route path="policy" element={<Policy/>}/>
        <Route path="joinDetail" element={<JoinDetail/>}/>
        <Route path="*" element={<Policy/>}/>
      </Routes>
    </div>
  )
}

