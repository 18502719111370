import {Exception} from "sass";

const swapObjectKeyValue = (obj:Object) => Object.fromEntries(Object.entries(obj).map(a => a.reverse()))

//항공청코드
export const AviationAgency = {
  "001":"서울지방항공청",
  "002":"부산지방항공청",
  "003":"제주지방항공청"
}
export type AviationAgencyCode = keyof typeof AviationAgency;

export const AviationAgencyBySubarea:{[index:string]:AviationAgencyCode} = {
  "001":"001",//서울 => 서항청
  "002":"001",//인천 => 서항청
  "003":"001",//충북 => 서항청
  "004":"001",//전북 => 서항청
  "005":"001",//강원 => 서항청
  "006":"001",//경기 => 서항청
  "007":"002",//경북 => 부항청
  "008":"001",//대전 => 서항청
  "009":"001",//세종 => 서항청
  "010":"001",//충남 => 서항청
  "011":"002",//부산 => 부항청
  "012":"002",//울산 => 부항청
  "013":"002",//경남 => 부항청
  "014":"002",//대구 => 부항청
  "015":"002",//광주 => 부항청
  "016":"002",//전남 => 부항청
  "017":"003",//제주 => 제항청
}

//제한구역 코드
export const RestrictZone = {
  "001":"장애물제한구역 안",
  "002":"장애물제한구역 밖",
  "003":"비행안전구역"
}
export type RestrictZoneCode = keyof typeof RestrictZone;

//공항 코드
export const Airport = {
  "1":"인천공항", "2":"김포공항", "3":"양양공항", "4":"원주공항", "5":"청주공항",
  "6":"군산공항", "7":"여수공항", "8":"울산공항", "9":"무안공항", "10":"대구공항",
  "11":"포항공항", "12":"광주공항", "13":"사천공항", "14":"울진공항", "15":"군비행장",
  "16":"김해공항", "17":"제주공항",
}
export type AirportCode = keyof typeof Airport;
export const AirportByAviationAgency = {
  "001":["1","2","3","4","5","6"],
  "002":["7","8","9","10","11","12","13","14","15","16"],
  "003":["17"]
}

//주간표지 종류 코드
export const AwSign = {
  "001":"도색", "002":"백색", "003":"깃발", "004":"표지구", "006":"주간표지 없음"
}
export type AwSignCode = keyof typeof AwSign;

//주간표지 대체 코드
export type AwSignReplaceCode = ""|"고광도 A로 대체"|"고광도 B로 대체"|"중광도 A로 대체"

//지역코드
export const Subarea = {
  "001": "서울", "002": "인천", "003": "충북", "004": "전북", "005": "강원",
  "006": "경기", "007": "경북", "008": "대전", "009": "세종", "010": "충남",
  "011": "부산", "012": "울산", "013": "경남", "014": "대구", "015": "광주",
  "016": "전남", "017": "제주"
}
export type SubareaCode = keyof typeof Subarea;

/**주소로부터 지역코드를 얻습니다.*/
export const getSubareaByAddress = (address:string):SubareaCode => {
  let regionList:{[index:string]:string} = swapObjectKeyValue(Subarea);
  let addr2 = address.substring(0,2)
  if (regionList.hasOwnProperty(addr2)){
    return regionList[addr2] as SubareaCode
  }
  
  let alternativeSubareas:{[index:string]:string} = {"충청북도":"003","충청남도":"010",
    "전라북도":"004","전라남도":"016","경상북도":"007","경상남도":"013","서귀포시":"017"}
  let addr4 = address.substring(0,4)
  if (alternativeSubareas.hasOwnProperty(addr4)){
    return alternativeSubareas[addr4] as SubareaCode
  }
  
  throw new Error("지역코드를 찾을 수 없습니다.");
}


export const Obstacle = {
  "001": "건축물",
  "002": "굴뚝",
  "003": "철탑",
  "004": "풍력터빈",
  "005": "방송안테나 등",
  "006": "교량",
  "007": "기타구조물",
  "008": "크레인",
  "009": "가공선",
  "010": "타워",
  "011": "건설용 크레인"
}
export type ObstacleCode = keyof typeof Obstacle;

export const AwlProductKind = {
  1:"저광도",
  2:"중광도",
  3:"고광도"
}
export type AwlProductKindCode = keyof typeof AwlProductKind;


//민원분류코드
export const DeclarationKind = {
  "I":"설치",
  "C":"변경",
  "D":"철거",
  "B":"고장"
}
export type DeclarationKindCode = keyof typeof DeclarationKind;

//처리현황 코드
export const DeclarationStts = {
  "S020100000":"접수대기",
  "S020200000":"민원접수",
  "S020520000":"최종기안",
  "S020800000":"처리완료",
  "S020910000":"임의종결",
  "S020920000":"민원취하",
}
export type DeclarationSttsCode = keyof typeof DeclarationStts;

//통지방법 코드
export const AvtsmtMthd = {
  0: "시스템 입력",
  1: "유선",
  2: "공문"
}
export type AvtsmtMthdCode = keyof typeof AvtsmtMthd;

export type ComplaintEditMode = "create"|"edit"|"readonly"