import React from 'react';
import "./Broken.scss"
import {BreadCrumb} from "../comp-shared/BreadCrumb";
import {LeftNaviagtor} from "../comp-shared/LeftNaviagtor";
import {LeftNavigatorItem} from "../comp-shared/LeftNavigator/LeftNavigatorItem";
import {Route, Routes, useLocation} from "react-router-dom";
import {Login} from "./User/Login";
import {BrokenComplaint} from "./Broken/BrokenComplaint";
import {RebrokenList} from "./Broken/RebrokenList";
import {RestoreList} from "./Broken/RestoreList";
import RebrokenComplaint from "./Broken/RebrokenComplaint";
import RestoreComplaint from "./Broken/RestoreComplaint"
import {InquiryBox} from "../comp-shared/InquiryBox";

export const Broken = () => {
  const location = useLocation();
  const getBreadcrumbPath = () => {
    if (location.pathname.includes("broken/broken")) return ["고장통지"];
    if (location.pathname.includes("rebroken")) return ["고장통지","고장 재통지"];
    if (location.pathname.includes("restore")) return ["고장통지","복구완료 통지"];
    return []
  };
  return (
    <div className="Broken">
      <BreadCrumb path={getBreadcrumbPath()}/>
      <div className="container">
        <div className="container-inbox">
          <div>
            <LeftNaviagtor title="민원신청">
              <LeftNavigatorItem path="/broken/broken"> 고장통지 </LeftNavigatorItem>
              <LeftNavigatorItem path="/broken/rebroken"> 고장 재통지 </LeftNavigatorItem>
              <LeftNavigatorItem path="/broken/restore"> 복구완료 통지 </LeftNavigatorItem>
            </LeftNaviagtor>
            <InquiryBox />
          </div>
          <Routes>
            <Route path="broken" element={<BrokenComplaint/>}/>
            <Route path="rebroken" element={<RebrokenList/>}/>
            <Route path="rebroken/cvlcpt/:id" element={<RebrokenComplaint/>}/>
            <Route path="rebroken/mng-card/:mngCardNum" element={<RebrokenComplaint/>}/>
            <Route path="restore" element={<RestoreList/>}/>
            <Route path="restore/cvlcpt/:id" element={<RestoreComplaint/>}/>
            <Route path="restore/mng-card/:mngCardNum" element={<RestoreComplaint/>}/>
            <Route path="*" element={<Login/>}/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

