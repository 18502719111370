import React from 'react';
import "./NoticeDetail.scss"
import {useNavigate, useSearchParams} from "react-router-dom";
import {PageTitle} from "../../comp-shared/PageTitle";
import {useSWRNtc} from "../../server/server";
import moment from "moment";
import {requestURL} from "../../config";
import {marginTop} from "html2canvas/dist/types/css/property-descriptors/margin";

export const NoticeDetail = () => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = parseInt(urlSearchParams.get("id") ?? "0");
  const {data:notice} = useSWRNtc(id);

  return (
    <div className="NoticeDetail">
      <PageTitle title="공지사항"/>
      <div className="declaration-grid notice-grid">
        <div className="header yellow">제목</div>
        <div className="field left">{notice?.ttl}</div>
        <div className="header yellow">작성자</div>
        <div className="field left">{notice?.wrtr}</div>
        <div className="header yellow">작성시간</div>
        <div className="field left">{moment(notice?.regDt).format("yyyy-MM-DD")}</div>
        <div className="field left" style={{gridColumn:"auto / span 2"}}>
          <pre style={{whiteSpace:"pre-wrap"}}>
            {notice?.cn}
          </pre>
        </div>
        <div className="header yellow">첨부파일</div>
        <div className="field left">
          {notice?.files.map(file => 
            <div className="download-link"
                 onClick={()=>window.location.href=`${requestURL}/download-file/${file.id}`}>
            {file.actualFileNm}
          </div>)}
        </div>
      </div>
      <button className="button" onClick={()=>navigate("/notice/list")}
              style={{marginTop:"10px"}}>목록</button>
    </div>
  )
}

