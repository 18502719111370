import React from "react";
import img06 from "../../../../resources/images/obstacle/inst/img06.jpg";
import img07 from "../../../../resources/images/obstacle/inst/img07.jpg";
import img08 from "../../../../resources/images/obstacle/inst/img08.jpg";
import img09 from "../../../../resources/images/obstacle/inst/img09.jpg";
import img10 from "../../../../resources/images/obstacle/inst/img10.jpg";
import img11 from "../../../../resources/images/obstacle/inst/img11.jpg";
import img12 from "../../../../resources/images/obstacle/inst/img12.jpg";
import MA from "../../../../resources/images/obstacle/inst/1.M.A.jpg";
import MB from "../../../../resources/images/obstacle/inst/2.M.B.jpg";
import MC from "../../../../resources/images/obstacle/inst/3.M.C.jpg";
import HA from "../../../../resources/images/obstacle/inst/6.H.A.jpg";
import HAM from "../../../../resources/images/obstacle/inst/8.H.A.M.C.jpg";
import chimney1 from "../../../../resources/images/obstacle/inst/chimney1.jpg";
import chimney2 from "../../../../resources/images/obstacle/inst/chimney2.png";
import tower from "../../../../resources/images/obstacle/inst/tower.jpg";
import crane from "../../../../resources/images/obstacle/inst/crane.jpg";
import cable from "../../../../resources/images/obstacle/inst/cable.gif";
import balloon from "../../../../resources/images/obstacle/inst/balloon.jpg";
import turbine from "../../../../resources/images/obstacle/inst/turbine.jpg";

function Answer297() {
  return <li className="answer-97">
    문의하신 장애물은 장애물제한구역 안의 장애물로서 진입, 전이, 수평, 원추표면보다 높은 경우 항공장애표시등 및 항공장애주간표지
    설치대상되므로 침투여부 확인이 필요합니다. 따라서 인천국제공항공사 또는 한국공항공사로 문의하시기 바랍니다.</li>;
}

function Answer298() {
  return <li className="answer-98">문의하신 장애물은 비행안전구역내에 있으므로 관할 군부대장에게 문의하시기 바랍니다.</li>;
}

function Answer299() {
  return <li className="answer-99">
    <div className="answer-text">군집물체인 경우에는 군집물체 안쪽의 등은 생략하고 외곽으로 배치하되 전체적인 윤곽을 나타낼 수 있도록 하고,
      배치한 등이 섬광할 경우에는 동시섬광이 되도록 합니다.
    </div>
  </li>;
}

function Answer201() {
  return <li className="answer-1">
    <div>
      <p className="answer-text">
        항공장애 주간표지의 설치(항공장애물 관리 및 비행안전확인 기준)
      </p>
      <p>제38조(주간표지의 설치) ① 표지가 필요한 모든 고정 물체는 가능한 한 색채를 입혀야 하며,
        색채를 입히는 것이 어려운 경우 장애표지물(Marker)(이하 "표지물"이라 한다) 또는
        기( 旗)를 해당 물체의 위(On)나 상부(Over)에 설치해야 한다. 단, 형태, 크기 또는 색채로
        인해 눈에 잘 띄는 장애물로서 달리 표지할 수 있는 경우에는 제외할 수 있다.<br/><br/>② 표지를 해야 하는 이동 물체는 색채로 표지하거나 기로 표지하여야 한다.
      </p>
      <p>
        제39조(색채의 표지) ① 물체가 연속된 표면을 가지고 있고 임의의 수직면상에 수직으로 투영된 물체의 투영면의 가로와 세로의 길이 모두 4.5m 이상일 경우에는 다음 각 호에 따라 바둑판
        모양으로 색채를 입혀야 한다.<br/><br/>
        1. 바둑판 모양은 한 변이 1.5m 이상 3m 이하의 길이여야 하고 모퉁이는 좀 더 어두운 색채로 표지할 것<br/>
        2. 바둑판 모양의 색채는 서로 간에 대조를 이루어야 하고 주변의 배경과도 대조를 이루어야 하며, 주황색과 흰색 또는 붉은색과 흰색을 사용할 것. 다만, 그러한 색채가 주변의 배경과 대조를
        이루지 않는 경우에는 다른 색을 사용할 수 있다.<br/>
      </p>
      <div style={{textAlign: "center"}}><img src={img06} alt="img06"/></div>
      <p>
        ② 임의의 수직면상에 수직으로 투영된 물체의 투영면의 한 변의 길이가 1.5m 보다 크고 다른 한 변의 길이가 4.5m보다 작은 연속된 표면을 가지고 있는 물체 또는 두 변 중 한 변의 크기가
        1.5m보다 큰 뼈대형 구조의 물체에는 다음 각 호에 따라 줄무늬 색채 표지를 하여야 한다.<br/><br/>

        1. 줄무늬는 그 긴 쪽 변이 장애물의 긴 쪽 변과 직각이 되도록 하여야 하고 장애물 끝단의 줄무늬는 더 진한 색으로 채색할 것<br/>
        2. 줄무늬의 색은 그 배경과 대조를 이루도록 주황색과 흰색을 사용할 것. 다만, 그 색채가 주변과 대조하여 눈에 잘 띄지 않는 경우에는 다른 색채를 사용할 수 있다.<br/>
        3. 줄무늬의 폭은 물체의 가장 긴 쪽 변의 길이에 따라 아래 표에서 정한 폭과 30m 중 작은 값으로 한다.<br/>
      </p>
      <div>
        <table
          style={{width: "600px", border: "1px solid black", borderSpacing: "0px", padding: "0px", textAlign: "center"}}>
          <tbody>
          <tr>
            <td colSpan={2}>물체의 가장 긴 편의 길이(최대크기(m))</td>
            <td colSpan={2} rowSpan={2}>줄무늬의 폭</td>
          </tr>
          <tr>
            <td style={{width: "175px;"}}>이 상</td>
            <td style={{width: "175px;"}}>이 하</td>
          </tr>
          <tr>
            <td>1.5m</td>
            <td>210m</td>
            <td style={{width: "120px;"}}>최대 길이의</td>
            <td style={{width: "120px;"}}>1/7</td>
          </tr>
          <tr>
            <td>210m</td>
            <td>270m</td>
            <td>〃</td>
            <td>1/9</td>
          </tr>
          <tr>
            <td>270m</td>
            <td>330m</td>
            <td>〃</td>
            <td>1/11</td>
          </tr>
          <tr>
            <td>330m</td>
            <td>390m</td>
            <td>〃</td>
            <td>1/13</td>
          </tr>
          <tr>
            <td>390m</td>
            <td>450m</td>
            <td>〃</td>
            <td>1/15</td>
          </tr>
          <tr>
            <td>450m</td>
            <td>510m</td>
            <td>〃</td>
            <td>1/17</td>
          </tr>
          <tr>
            <td>510m</td>
            <td>570m</td>
            <td>〃</td>
            <td>1/19</td>
          </tr>
          <tr>
            <td>570m</td>
            <td>630m</td>
            <td>〃</td>
            <td>1/21</td>
          </tr>
          </tbody>
        </table>
        <br/>

        <div style={{textAlign: "center"}}><img src={img07} alt="img07"/></div>
        <div style={{textAlign: "center"}}><img src={img08} alt="img08"/></div>
      </div>
      <p>
        ③ 임의의 수직면상에 수직으로 투영된 물체의 투영면의 두 변의 길이 모두 1.5m 미만인 경우 주황색 또는 붉은색의 단일 색으로 표지하여야 한다. 다만, 그 색채가 주변과 대조하여 눈에 잘 띄지
        않는 경우에는 다른 색을 사용할 수 있다.
      </p>
      <p>
        ⑤ 하나의 원통형 지지대(Standpipe)로 지지되는 구(球) 모양의 저장탱크는 다음 각 호에 따라 물방울모양의 줄무늬로 표시하여야 한다.<br/>
        1. 저장탱크에는 주황색과 흰색을 번갈아 표지할 것<br/>
        2. 줄무늬는 저장탱크의 꼭대기 중앙에서부터 지지대 최상부까지 표지되도록 할 것<br/>
        3. 각 줄무늬의 폭은 동일하여야 하며 탱크의 가장 넒은 배 부분에서의 줄무늬 폭은 1.5m 이상, 4.5m 이하가 되도록 할 것<br/>
        4. 저장탱크에 문자 등을 표시하는 경우에는 줄무늬 모양이 끊어지도록 할 수 있으며 끊어지는 부분의 폭은 0.9m 이하가 되도록 할 것<br/><br/>
        <img src={img09} alt="img09"/><br/>
      </p>
    </div>
  </li>
}

function Answer202() {
  return <li className="answer-2">
    <div>
      <p className="answer-text">
        계류기구와 계류용 선의 항공장애 주간표지의 설치
      </p>
      <p>
        제38조(주간표지의 설치) ①표지가 필요한 모든 고정 물체는 가능한 한 색채를 입혀야 하며, 색채를 입히는 것이 어려운 경우 장애표지물(Marker)(이하 "표지물"이라 한다) 또는 기( 旗)를
        해당 물체의 위(On)나 상부(Over)에 설치해야 한다. 단, 형태, 크기 또는 색채로 인해 눈에 잘 띄는 장애물로서 달리 표지할 수 있는 경우에는 제외할 수 있다.<br/><br/>② 표지를
        해야 하는 이동 물체는 색채로 표지하거나 기로 표지하여야 한다.
      </p>
      <p>제40조(표지물의 설치) ②가공선, 케이블, 현수선 등에 설치하는 표지물은 다음 각 호의 기준을 충족하도록 설치하여야 한다.</p>
      <ol>
        <li className="answer-2">표지물은 직경이 60㎝ 이상인 구형(球形)일 것<br/><img src={img11} alt="img11"/>
        </li>
        <li className="answer-2">동일한 지지탑에 설치된 여러 개의 가공선 또는 케이블·현수선 등에 표지물을 설치하는 경우에는 표지물을 설치할 지점의 가장 높은 위치에 있는 가공선 또는
          케이블, 현수선 등에 표지물을 설치할 것<br/><img src={img12} alt="img12"/><br/>
          다만, 가장 높은 지점에 하나 이상의 선이 있는 경우, 인접한 표지물간의 간격이 제3호에 따른 설치간격 기준을 만족시킨다면 표지물을 각각의 선을 따라서 교대로 설치할 수 있다.</li>
        <li className="answer-2">두 개의 잇따른 표지물 간, 또는 하나의 표지물과 지지용 탑 간의 간격은 다음 각 호에 따라 설치할 것<br/>
          &nbsp;&nbsp;&nbsp;가. 표지물 직경이 60㎝ 이상 80㎝ 미만인 경우 30m<br/>
          &nbsp;&nbsp;&nbsp;나. 표지물 직경이 80㎝ 이상 130㎝ 미만인 경우 35m<br/>
          &nbsp;&nbsp;&nbsp;다. 표지물 직경이 130㎝ 이상인 경우 40m</li>
        <li className="answer-2">하나의 표지물은 단일 색상이어야 하며, 설치 시 흰색과 붉은색 또는 흰색과 주황색 표지물을 교대로 번갈아 가며 설치할 것. 다만, 표지물의 색채가 주변과
          대조하여 눈에 잘 띄지 아니할 경우에는 다른 색채를 사용할 수 있다.
        </li>
        <li className="answer-2">4개 미만의 표지물을 설치하게 될 경우에는 모두 붉은색이나 주황색으로 설치할 것</li>
        <li className="answer-2">선의 양 끝단에는 붉은색이나 주황색 표지물을 설치할 것</li>
      </ol>
    </div>
  </li>;
}

function Answer203() {
  return <li className="answer-3">
    <div>
      <p className="answer-text">
        풍력터빈 항공장애 주간표지의 설치
      </p>
      <p>
        제38조(주간표지의 설치) ① 표지가 필요한 모든 고정 물체는 가능한 한 색채를 입혀야 하며, 색채를 입히는 것이 어려운 경우 장애표지물(Marker)(이하 "표지물"이라 한다) 또는 기(
        旗)를 해당 물체의 위(On)나 상부(Over)에 설치해야 한다. 단, 형태, 크기 또는 색채로 인해 눈에 잘 띄는 장애물로서 달리 표지할 수 있는 경우에는 제외할 수 있다.<br/><br/>②
        표지를 해야 하는 이동 물체는 색채로 표지하거나 기로 표지하여야 한다.
      </p>
      <p>
        제39조(색채의 표지) ⑥ 풍력터빈의 경우에는 회전날개, 엔진실, 지지대의 상부 2/3는 흰색으로 채색해야 한다. 다만, 항공학적 검토결과 다른 방식으로 표시되어야 한다고 판단되는 경우는
        제외한다.<br/>
        <img src={img10} alt="img10"/><br/>
      </p>
    </div>
  </li>
}

function Answer204() {
  return <li className="answer-4">
    <p className="answer-text">
      항공장애 표지등의 설치(항공장애물 관리 및 비행안전확인 기준)
    </p>
    <p>
      제33조(고정 물체) ③ 지표 또는 수면으로부터 높이가 150m 이상인 물체에는 다음 각 호와 같이 표시등을 설치하여야 한다.
    </p>
    <ol>
      <li className="answer-4">고광도 A 형태의 표시등을 지상과 물체의 최상부 사이에 105m 이내의 균일 간격으로 설치. 다만, 표지되어야 할 물체가 건물들에 둘러싸여 있어 표시등의 설치
        층을 결정할 때 건물들의 최상부들의 높이를 지상으로 간주하여 사용할 경우는 제외한다.<br/>
        &nbsp;&nbsp;&nbsp;가. 중광도 B 형태 표시등을 사용하는 경우, 물체의 중간 중간에 표시등을 추가로 설치하여야 하며, 이렇게 추가로 설치하는 표시등은 저광도 B 형태 표시등과
        중광도 B 형태 표시등을 교대로 지상이나 근처 건물들의 최상부와 물체의 최상부에 설치하는 등들 사이에 가능한 한 균일 간격으로 설치하여야 하며 52m 간격을 넘지 않을 것<br/>
        &nbsp;&nbsp;&nbsp;나. 물체에 중광도 C 형태 표시등이 사용되는 경우, 물체의 꼭대기가 지상이나 근처 건물들(표지할 물체가 건물들로 둘러싸인 경우)의 최상부들의 높이보다 45m
        이상 높을 때 물체 중간에 추가로 중광도 C 형태 표시등을 설치하여야 하며, 이렇게 추가로 설치하는 중광도 C 형태 표시등은 지상이나 근처 건물들의 최상부와 물체의 최상부에 설치하는 등들
        사이에 가능한 한 균일 간격으로 설치해야 하며 52m 간격을 넘지 않을 것</li>
      <li className="answer-4">24시간 사용 중인 고광도 A 형태 표시등이 야간에 공항근처(약 반경 10,000m 이내)의 조종사에게 눈부심을 주거나 다음 각 호와 같은 경우로서 환경에
        심각한 피해를 줄 것이라고 지방항공청장이 판단하는 경우에는 주간 및 박명시간에는 고광도 A 형태 표시등을, 야간에는 중광도 B 형태 표시등과 저광도 B 형태 표시등을 조합하여 사용하거나 중광도
        C 형태 표시등을 단독으로 사용할 수 있도록 이중등화 시스템으로 구성할 것
      </li>
      <li className="answer-4">야간시간대용으로 사용중인 고광도 A 형태의 표시등이 공항근처(약 반경 10,000m 이내)의 조종사에게 눈부심을 주거나 다음 각 호와 같은 경우로서 중대한
        환경적 영향을 줄 것이라고 지방항공청장이 판단하는 경우에는 중광도 C 형태 표시등을 단독으로 사용하거나, 중광도 B 형태 표시등과 저광도 B 형태 표시등을 조합하여 사용할 것<br/>
        &nbsp;&nbsp;&nbsp;가. 부근에 천연기념물로 보호하는 동식물이 서식하고 있는 경우<br/>
        &nbsp;&nbsp;&nbsp;나. 인구밀집 지역에서 야간에 고광도 표시등 운영 시 수면방해 등을 발생시킬 수 있는 경우</li>
    </ol>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(고광도A형)</div>
    <div><img src={HA} alt="6.H.A"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도B형)</div>
    <div><img src={MB} alt="2.M.B"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도C형)</div>
    <div><img src={MC} alt="3.M.C"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(고광도A-중광도C형 이중등화시스템)</div>
    <div><img src={HAM} alt="8.H.A.M.C"/></div>
  </li>
}

function Answer205() {
  return <li className="answer-5">
    <p className="answer-text">
      항공장애 표지등의 설치(항공장애물 관리 및 비행안전확인 기준)
    </p>
    <p>
      제33조(고정 물체) ② 지표 또는 수면으로부터 높이가 45m 이상 150m 미만인 물체에는 다음 각 호와 같이 중광도 A나 B 또는 C 형태의 표시등을 설치하여야 한다.
    </p>
    <ol>
      <li className="answer-5">물체에 중광도 A 형태 표시등이 사용되는 경우, 물체의 꼭대기가 지상이나 근처 건물들(표지할 물체가 건물들로 둘러싸인 경우)의 최상부들의 높이보다 105m 이상
        높을 때 물체 중간에 추가로 A 형태 표시등을 설치하여야 하고 추가로 설치하는 등은 지상이나 근처 건물들의 최상부와 물체의 최상부에 설치하는 등들 사이에 가능한 한 균일 간격으로 설치하여야
        하며, 105m 간격을 넘지 않을 것. 다만, 24시간 사용 중인 중광도 A 형태 표시등이 야간에 공항근처(약 반경 10,000m 이내)의 조종사에게 눈부심을 주거나 환경에 심각한 피해를 줄
        것이라고 지방항공청장이 판단하는 경우에는 주간 및 박명시간에는 중광도 A 형태 표시등을, 야간에는 중광도 B 형태와 저광도 B 형태를 조합하여 사용하거나 중광도 C 형태 표시등을 단독으로 사용할
        수 있도록 이중등화 시스템으로 구성해야 한다.
      </li>
      <li className="answer-5">야간시간대에만 사용 중인 중광도 A 형태 표시등이 공항근처(약 반경 10,000m 이내)의 조종사에게 눈부심을 주거나 환경에 심각한 피해를 줄 것이라고
        지방항공청장이 판단하는 경우에는 중광도 C 형태 표시등을 단독으로 사용하거나, 중광도 B 형태 표시등과 저광도 B 형태 표시등을 조합하여 사용할 것
      </li>
      <li className="answer-5">물체에 중광도 B 형태 표시등이 사용되는 경우, 물체의 꼭대기가 지상이나 근처 건물들(표지할 물체가 건물들로 둘러싸인 경우)의 최상부들의 높이보다 45m 이상
        높을 때 물체 중간에 추가로 표시등을 설치하여야 하며, 이렇게 추가로 설치하는 표시등은 지상이나 근처 건물들의 최상부와 물체의 최상부에 설치하는 등들 사이에 저광도 B 형태 표시등과 중광도 B
        형태 표시등을 교대로 하여 균일 간격으로 설치해야 하며 52m 간격을 넘지 않을 것
      </li>
      <li className="answer-5">물체에 중광도 C 형태 표시등이 사용되는 경우, 물체의 꼭대기가 지상이나 근처 건물들(표지할 물체가 건물들로 둘러싸인 경우)의 최상부들의 높이보다 45m 이상
        높을 때 물체 중간에 추가로 중광도 C 형태 표시등을 설치하여야 하며, 이렇게 추가로 설치하는 중광도 C 형태 표시등은 지상이나 근처 건물들의 최상부와 물체의 최상부에 설치하는 등들 사이에
        가능한 한 균일 간격으로 설치해야 하며 52m 간격을 넘지 않을 것
      </li>
    </ol>
    <p>
      제34조(표시등의 설치) ① 표시등을 설치할 때에는 임의의 방향에서 접근하는 조종사가 표시등을 볼 수 있도록 설치위치, 수량 및 배열 등을 설정하여야 한다.
    </p>
    <p>
      ② 표시등이 인접 장애물 등에 의하여 보이지 않게 되는 경우에는 표시등이 보일 수 있도록 인접 장애물에 추가로 표시등을 설치해야 한다.
    </p>
    <p>
      ③ 표시등은 가능한 한 장애물의 정상에 가까운 곳에 설치하여야 한다. 다만, 굴뚝 또는 그와 유사한 기능을 가진 물체에 설치하는 표시등은 물체에서 방출되는 오염물, 연기 등으로 인하여 기능이
      저하되는 것을 최소화하기 위하여 정상 보다 1.5m ∼ 3m 낮은 곳에 위치하도록 설치할 수 있다.
    </p>
    <div><img src={chimney1} alt="chimney1"/></div>
    <p>
      ④ 굴뚝 또는 그와 유사한 연속된 단일 물체에 설치해야 하는 표시등의 최소 수량은 해당 물체의 최상부 직경에 따라 결정되며, 이 경우 각 층에 설치하여야 할 표시등의 최소 수량은 다음 각 호와
      같다. 다만, 철탑 등과 같은 뼈대로 이루어진 물체는 최상부 직경에 상관없이 최소 1개 이상의 표시등을 설치하여야 한다.
    </p>
    <ol>
      <li className="answer-5">직경이 6m 이하 : 한 층당 3개</li>
      <li className="answer-5">직경이 6m 초과 ~ 31m 까지 : 한 층당 4개</li>
      <li className="answer-5">직경이 31m 초과 ~ 61m 까지 : 한 층당 6개</li>
      <li className="answer-5">직경이 61m 초과 : 한 층당 8개</li>
    </ol>
    <div><img src={chimney2} alt="chimney2"/></div>
    <p>
      ⑤ 장애물제한표면이 경사가 지고 장애물제한표면보다 높거나 가장 근접한 지점이 그 물체의 정상점이 아닐 경우에는 장애물제한표면보다 높거나 가장 근접한 지점에 표시등을 설치하고 그 물체의 가장 높은
      지점에 표시등을 추가로 설치하여야 한다.
    </p>
    <p>
      ⑥ 하나의 물체에 설치된 고광도 A 형태, 중광도 A와 B 형태 표시등은 동시에 섬광되어야 한다.
    </p>
    <div><img src={tower} alt="tower"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도A형)</div>
    <div><img src={MA} alt="1.M.A"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도B형)</div>
    <div><img src={MB} alt="2.M.B"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도C형)</div>
    <div><img src={MC} alt="3.M.C"/></div>
  </li>;
}

function Answer206() {
  return <li className="answer-6">
    <p className="answer-text">
      항공장애 표지등의 설치(항공장애물 관리 및 비행안전확인 기준)
    </p>
    <p>
      제33조(고정 물체) ⑥ 건설용 크레인에는 크레인의 최상부에 중광도 A 형태 표시등을 최소 1개 이상 설치하여야 한다.
    </p>
    <div><img src={crane} alt="crane"/></div>
    <p>
      제34조(표시등의 설치) ③ 표시등은 가능한 한 장애물의 정상에 가까운 곳에 설치하여야 한다. 다만, 굴뚝 또는 그와 유사한 기능을 가진 물체에 설치하는 표시등은 물체에서 방출되는 오염물, 연기
      등으로 인하여 기능이 저하되는 것을 최소화하기 위하여 정상 보다 1.5m ∼ 3m 낮은 곳에 위치하도록 설치할 수 있다.<br/>
      ④ 굴뚝 또는 그와 유사한 연속된 단일 물체에 설치해야 하는 표시등의 최소 수량은 해당 물체의 최상부 직경에 따라 결정되며, 이 경우 각 층에 설치하여야 할 표시등의 최소 수량은 다음 각 호와
      같다. 다만, 철탑 등과 같은 뼈대로 이루어진 물체는 최상부 직경에 상관없이 최소 1개 이상의 표시등을 설치하여야 한다.
    </p>
    <ol>
      <li className="answer-6">직경이 6m 이하 : 한 층당 3개</li>
      <li className="answer-6">직경이 6m 초과 ~ 31m 까지 : 한 층당 4개</li>
      <li className="answer-6">직경이 31m 초과 ~ 61m 까지 : 한 층당 6개</li>
      <li className="answer-6">직경이 61m 초과 : 한 층당 8개</li>
    </ol>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도A형)</div>
    <div><img src={tower} alt="tower"/></div>
    <div><img src={MA} alt="1.M.A"/></div>
  </li>;
}

function Answer207() {
  return <li className="answer-7">
    <p className="answer-text">
      항공장애 표지등의 설치(항공장애물 관리 및 비행안전확인 기준)
    </p>
    <p>
      제33조(고정 물체) ⑧ 가공선이나 케이블·현수선 등에 표지를 설치하여야 하나 설치가 불가능한 경우 이들의 지지용 구조물에는 제14조제4항에 따른 고광도 B 형태 표시등을 설치하여 가공선이나
      케이블·현수선 등이 있음을 표시하여야 한다.
    </p>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(고광도B형)</div>
    <div><img src={cable} alt="cable"/></div>
  </li>;
}

function Answer208() {
  return <li className="answer-8">
    <p className="answer-text">
      항공장애 표지등의 설치(항공장애물 관리 및 비행안전확인 기준)
    </p>
    <p>
      제33조(고정 물체) ④ 계류기구에는 다음 각 호와 같이 표시등을 설치한다.
    </p>
    <ol>
      <li className="answer-8">중광도 A 형태 표시등을 기구의 정상, 돌출부, 꼬리부 및 기구로부터 4.6m 하단의 케이블 부분에 설치하여야 하며, 계류기구를 포함한 전체 높이가 105m를
        초과할 경우에는 케이블 부분에 105m를 넘지 않는 같은 간격으로 설치할 것. 다만, 24시간 사용 중인 중광도 A 형태 표시등이 야간에 항공기 조종사에게 눈부심을 주거나 환경에 심각한 피해가
        있다고 판단되는 경우에는 주간 및 박명시간에는 중광도 A 형태 표시등을, 야간에는 중광도 B 형태 표시등과 저광도 B 형태 표시등을 조합하여 사용할 수 있도록 이중등화 시스템으로 하여야
        한다.<br/>
        <div><img src={balloon} alt="balloon"/></div></li>
      <li className="answer-8">야간시간대용만으로 사용 중인 중광도 A 형태 표시등이 항공기 조종사에게 눈부심을 주거나 중대한 환경적 영향을 줄 것이라고 지방항공청장이 판단하는 경우에는 중광도
        C 형태 표시등을 단독으로 사용하거나, 중광도 B 형태 표시등과 저광도 B 형태 표시등을 조합하여 사용할 것
      </li>
      <li className="answer-8">제1호 및 제2호에 따른 표시등의 설치가 불가능한 경우에는 평균 160lux 이상의 밝기로 조명되도록 할 것</li>
    </ol>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도A형)</div>
    <div><img src={MA} alt="1.M.A"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도C형)</div>
    <div><img src={MC} alt="3.M.C"/></div>
  </li>;
}

function Answer209() {
  return <li className="answer-9">
    <p className="answer-text">
      항공장애 표지등의 설치(항공장애물 관리 및 비행안전확인 기준)
    </p>
    <p>
      제33조(고정 물체) ⑤ 풍력터빈에는 다음 각 호와 같이 표시등을 설치한다.
    </p>
    <ol>
      <li className="answer-9">2개 이하의 풍력터빈에는 다음 각 호와 같이 표시등을 설치할 것<br/>
        가. 조종사가 어느 방향에서나 볼 수 있도록 터빈 상부에 2개의 중광도 A나 B 또는 C 형태 표시등을 설치할 것. 다만, 24시간 사용 중인 중광도 A 형태 표시등이 항공기 조종사에게 눈부심을
        주거나 다음과 같은 경우로서 환경에 심각한 피해가 있다고 판단되는 경우에는 주간시간대 및 박명시간대에는 중광도 A 형태 표시등을, 야간시간대 용으로 중광 B나 C 형태 표시등을 설치하여 운용할
        수 있다.<br/>
        &nbsp;&nbsp;&nbsp;1) 부근에 천연기념물로 보호하는 동식물이 서식하고 있는 경우<br/>
        &nbsp;&nbsp;&nbsp;2) 인구밀집 지역에서 야간에 중광도 표시등 운영 시 수면방해 등을 발생시킬 수 있는 경우<br/>
        <div><img src={turbine} alt="turbine"/></div>
        나. 2개 이상의 풍력터빈의 집단에 표시등을 설치할 경우 집단의 경계가 잘 나타나도록 하여야 하며, 섬광등은 동시에 섬광되어야 함</li>
      <li className="answer-9">3개 이상의 풍력터빈이 있는 풍력발전단지에는 다음 각 호와 같이 표시등을 설치할 것<br/>
        가. 제1호에 따라 표시등을 설치할 것<br/>
        나. 풍력발전단지 내의 표시등의 설치간격은 900m 이내여야 하며 풍력발전단지의 전체적인 윤곽이 잘 나타나도록 설치할 것<br/>
        &nbsp;&nbsp;&nbsp;1) 풍력터빈이 능선 등을 따라 선형으로 배열된 풍력발전단지에는 직선 각각의 끝단이나 직선을 구성하는 일부 구간의 각 끝단에 있는 풍력터빈에 표시등을 설치할
        것<br/>
        &nbsp;&nbsp;&nbsp;2) 풍력터빈이 일정한 공간 내에 집중적으로 집단을 형성하고 있는 클러스터형 풍력발전단지에는 가장 바깥쪽 경계에 있는 풍력터빈에 표시등을 설치할 것<br/>
        &nbsp;&nbsp;&nbsp;3) 풍력터빈이 사각형 모양으로 일정 간격으로 떨어져 격자형으로 배열된 풍력발전단지에는 각각의 모서리에 표시등을 설치할 것<br/>
        &nbsp;&nbsp;&nbsp;4) 풍력발전단지를 구성하고 있는 집단 인근에 소수의 풍력터빈이 존재하는 경우에는 소수의 풍력터빈도 하나의 집단으로 간주하여 표시등을 설치할 것
      </li>
    </ol>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도A형)</div>
    <div><img src={MA} alt="1.M.A"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도B형)</div>
    <div><img src={MB} alt="2.M.B"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도C형)</div>
    <div><img src={MC} alt="3.M.C"/></div>
  </li>;
}

function Answer210() {
  return <li className="answer-10">
    <p className="answer-text">
      항공장애 표지등의 설치(항공장애물 관리 및 비행안전확인 기준)
    </p>
    <p>
      제33조(고정 물체) ⑦ 영구적인 사용을 위하여 건설 중인 구조물에는 다음 각 호와 같이 표시등을 설치하여야 한다.
    </p>
    <ol>
      <li className="answer-10">건설 중인 구조물의 높이가 150m 미만인 경우에는 구조물 최상부에 중광도 A 형태 표시등을 최소 2개 이상 설치할 것</li>
      <li className="answer-10">건설 중인 구조물의 높이가 150m 이상인 경우에는 구조물 최상부에 고광도 A 형태 표시등을 최소 2개 이상 설치할 것</li>
    </ol>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(중광도A형)</div>
    <div><img src={MA} alt="1.M.A"/></div>
    <div className="answer-image-title">※ 항공장애표시등 배열 및 설치 위치(고광도A형)</div>
    <div><img src={HA} alt="6.H.A"/></div>
  </li>;
}

export const Answer2 = (props: {
  qnaCheck1: string
  qnaCheck2: string
  qnaCheck3: string
  qnaCheck4: string
}) => {
  if(props.qnaCheck1 === '1')
    return <Answer297/>

  if(props.qnaCheck1 === '3')
    return <Answer298/>

  if(props.qnaCheck1 === '2') {
    if(['1', '2', '3', '4', '6', '8'].includes(props.qnaCheck2)){
      if(props.qnaCheck3 === '1') {
        if(props.qnaCheck4 === '1')
          return <Answer201/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer201/>
          </div>
      }
    }

    if(props.qnaCheck2 === '5') {
      if(props.qnaCheck3 === '1') {
        if(props.qnaCheck4 === '1')
          return <Answer202/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer202/>
          </div>
      }
    }

    if(props.qnaCheck2 === '7') {
      if(props.qnaCheck3 === '1') {
        if(props.qnaCheck4 === '1')
          return <Answer203/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer203/>
          </div>
      }
    }

    if(props.qnaCheck2 === '1') {
      if(props.qnaCheck3 === '2') {
        if(props.qnaCheck4 === '1')
          return <Answer204/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer204/>
          </div>
      }
    }

    if(props.qnaCheck2 === '2') {
      if(props.qnaCheck3 === '2') {
        if(props.qnaCheck4 === '1')
          return <Answer205/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer205/>
          </div>
      }
    }

    if(props.qnaCheck2 === '3') {
      if(props.qnaCheck3 === '2') {
        if(props.qnaCheck4 === '1')
          return <Answer206/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer206/>
          </div>
      }
    }

    if(props.qnaCheck2 === '4') {
      if(props.qnaCheck3 === '2') {
        if(props.qnaCheck4 === '1')
          return <Answer206/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer206/>
          </div>
      }
    }

    if(props.qnaCheck2 === '5') {
      if(props.qnaCheck3 === '2') {
        if(props.qnaCheck4 === '1')
          return <Answer207/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer207/>
          </div>
      }
    }

    if(props.qnaCheck2 === '6') {
      if(props.qnaCheck3 === '2') {
        if(props.qnaCheck4 === '1')
          return <Answer208/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer208/>
          </div>
      }
    }

    if(props.qnaCheck2 === '7') {
      if(props.qnaCheck3 === '2') {
        if(props.qnaCheck4 === '1')
          return <Answer209/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer209/>
          </div>
      }
    }

    if(props.qnaCheck2 === '8') {
      if(props.qnaCheck3 === '2') {
        if(props.qnaCheck4 === '1')
          return <Answer210/>
        if(props.qnaCheck4 === '2')
          return <div>
            <Answer299/>
            <Answer210/>
          </div>
      }
    }
  }
}