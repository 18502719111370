import React from 'react';
import "./Policy.scss"
import {PageTitle} from "../../comp-shared/PageTitle";

export const Policy = () => {
  return (
    <div className="Policy">
      <PageTitle title="이용약관"/>
      <div className="policy-box">
        <h4>제 1장 총칙</h4>
        <h5>제 1조 (목적)</h5>
        <p className="text-box">
          본 약관은 제7차 [항공장애표시등 민원서비스] 사이트
          (이하 "제7차 [항공장애표시등 민원서비스]")가 제공하는 모든 인터넷 관련 서비스(이하 "서비스")의
          이용조건 및 절차, 이용자와 제7차 [항공장애표시등 민원서비스]의 권리, 의무, 책임사항과 기타
          필요한 사항을 규정함을 목적으로 합니다.
        </p>

        <h5>제 2조 (약관의 효력과 변경)</h5>
        <div className="list-box">
          <ul><li>제7차 [항공장애표시등 민원서비스]는 귀하가 본 약관 내용에 동의하는 것을 조건으로
            귀하에게 서비스를 제공할 것이며, 귀하 가 본 약관의 내용에 동의하는 경우,
            제7차 [항공장애표시등 민원서비스]의 서비스 제공 행위 및 귀하의 서비스
            사용 행위에는 본 약관이 우선적으로 적용 될 것입니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 본 약관을 사전 고지 없이 변경할 수 있으며,
              변경된 약관은 제7차 [항공장애표시등 민원서비스] 사이트 내에 공지함으로써 이용자가
              직접 확인하도록 할 것입니다. 이용자가 변경된 약관에 동의하지 아니하는 경우, 이용자는 본인의
              회원등록을 취소(회원탈퇴)할 수 있으며, 계속 사용의 경우는 약관 변경에 대한 동의로 간주됩니다.
              변경된 약관은 공지와 동시에 그 효력이 발생됩니다.</li>
          </ul>
        </div>

        <h5>제 3조 (약관 외 준칙)</h5>
        <div className="list-box">
          <ul>
            <li>본 약관은 제7차 [항공장애표시등 민원서비스]이 제공하는 서비스에 관한
              이용규정 및 별도 약관과 함께 적용됩니다.</li>
            <li>본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신 윤리위원회 심의규정,
              정보통신 윤리강령, 프로그램 보호법 및 기타관련 법령의 규정에 의합니다.</li>
          </ul>
        </div>

        <h5>제 4조 (용어의 정의)</h5>
        <h6>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</h6>
        <div className="list-box">
          <ul>
            <li> 이용자 : 본 약관에 따라 제7차 [항공장애표시등 민원서비스]이 제공하는
              서비스를 받는 자로서 회원으로 가입한 자.</li>
            <li> 가 입 : 제7차 [항공장애표시등 민원서비스]이 제공하는 신청서 양식에
              해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료 시키는 행위</li>
            <li> 회 원 : 제7차 [항공장애표시등 민원서비스]에 개인 정보를 제공하여
              회원으로 등록을 한 자로서, 제7차 [항공장애표시등 민원서비스]의 정보를 제공 받으며,
              제7차 [항공장애표시등 민원서비스]이 제공하는 서비스를 이용할 수 있는 자.</li>
            <li> 제7차 [항공장애표시등 민원서비스] ID : 회원식별과 회원의 서비스 이용을 위하여
              회원이 신청하고 제7차 [항공장애표시등 민원서비스]이 승인하는 이메일 주소 정보</li>
            <li>비밀번호 : 이용자와 회원ID가 일치하는지를 확인하고 통신상의 자신의 비밀보호를 위하여
              이용자 자신이 선정한 문자와 숫자의 조합.</li>
            <li>탈 퇴 : 회원이 이용계약을 종료 시키는 행위</li>
            <li> 본 약관에서 정의하지 않은 용어는 개별서비스에 대한 별도약관 및 이용규정에서 정의합니다.</li>
          </ul>
        </div>

        <h4>제 2장 서비스 제공 및 이용</h4>

        <h5>제 5조 (이용 계약의 성립)</h5>
        <div className="list-box">
          <ul>
            <li>이용계약은 신청자가 온라인으로 제7차 [항공장애표시등 민원서비스] 소정의 가입신청 양식에서 요구하는
              사항을 기록하여 가입을 완료하고, 등록 이후 제7차 [항공장애표시등 민원서비스]의 검증절차를
              회원을 인증받은 때부터 성립됩니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 다음 각 호에 해당하는 이용계약에 대하여는 가입을 취소할 수 있습니다.
              <br/>
                <ul >
                  <li>1) 다른 사람의 명의를 사용하여 신청하였을 때</li>
                  <li>2) 이용 계약 신청서의 내용을 허위로 기재하였거나 허위서류를 첨부하여 신청하였을 때</li>
                  <li>3) 사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때</li>
                  <li>4) 다른 사람의 제7차 [항공장애표시등 민원서비스]서비스 이용을 방해하거나
                    그 정보를 도용하는 등의 행위를 하였을 때</li>
                  <li>5) 제7차 [항공장애표시등 민원서비스] 사이트를 이용하여 법령과 본
                    약관이 금지하는 행위를 하는 경우</li>
                  <li>6) 기타 제7차 [항공장애표시등 민원서비스]이 정한 이용신청요건이 미비 되었을 때</li>
                </ul>
                <br/></li>
            <li> 제7차 [항공장애표시등 민원서비스]는 다음 각 항에 해당하는 경우 그 사유가 해소될
              때까지 이용계약 성립을 유보할 수 있습니다.
              <br/>
                <ul >
                  <li>  1) 서비스 관련 제반 용량이 부족한 경우</li>
                  <li>2) 기술상 장애 사유가 있는 경우</li>
                </ul></li>

          </ul>
        </div>

        <h5>제 6조 (회원정보 사용에 대한 동의)</h5>
        <div className="list-box">
          <ul>
            <li> 회원의 개인정보에 대해서는 제7차 [항공장애표시등 민원서비스]사의 개인정보 보호정책이 적용됩니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]의 회원 정보는 다음과 같이 수집, 사용, 관리, 보호됩니다.
              <br/>
                <ul >
                  <li>
                    1) 개인정보의 수집 : 제7차 [항공장애표시등 민원서비스]는 귀하의 제7차
                    [항공장애표시등 민원서비스] 서비스 가입 시 귀하가 제공하는 정보, 커뮤니티 활동을 위하여
                    귀하가 제공하는 정보, 각종 이벤트 참가를 위하여 귀하가 제공하는 정보, 광고나 경품의 취득을
                    위하여 귀하가 제공하는 정보 등을 통하여 귀하에 관한 정보를 수집합니다.</li>
                  <li>2) 개인정보의 사용 : 제7차 [항공장애표시등 민원서비스]는 제7차 [항공장애표시등 민원서비스]
                    서비스 제공과 관련해서 수집된 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설,
                    배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우,
                    범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우 또는 기타 관계법령에서
                    정한 절차에 따른 요청이 있는 경우, 귀하가 제7차 [항공장애표시등 민원서비스]에 제공한
                    개인 정보를 스스로 공개한 경우에는 그러하지 않습니다.</li>
                  <li>3) 개인정보의 관리 : 귀하는 개인정보의 보호 및 관리를 위하여 서비스의 개인정보관리에서
                    수시로 귀하의 개인정보를 수정/삭제할 수 있습니다. 수신되는 정보 중 불필요하다고
                    생각되는 부분도 변경/조정할 수 있습니다.</li>
                  <li>4) 개인정보의 보호 : 귀하의 개인정보는 오직 귀하만이 열람/수정/삭제 할 수 있으며,
                    이는 전적으로 귀하의 제7차 [항공장애표시등 민원서비스] ID와 비밀번호에 의해 관리되고 있습니다.
                    따라서 타인에게 본인의 제7차 [항공장애표시등 민원서비스] ID와 비밀번호를 알려주어서는 아니 되며,
                    작업 종료 시에는 반드시 로그아웃 해 주시고, 웹브라우저의 창을 닫아 주시기 바랍니다(이는 타인과
                    컴퓨터를 공유하는 인터넷 카페나 도서관 같은 공공장소에서 컴퓨터를 사용하는 경우에
                    귀하의 정보의 보호를 위하여 필요한 사항입니다.)</li>
                  <li>5) 기타 : 게시판이나, E-mail 등 온라인 상에서 귀하가 자발적으로 제공하는 개인정보는
                    다른 사람들이 수집하여 사용할 수 있음을 인지하시기 바랍니다. 공개적인 공간에 게재되는
                    개인 정보로 인해 원하지 않는 상황이 발행할 수도 있습니다. 개인정보에 대한 비밀을 유지할
                    책임은 귀하에게 있으며, 제7차 [항공장애표시등 민원서비스]는 개인정보 유출로 인해 발생한
                    결과에 대하여 어떤 책임도 부담하지 아니합니다.</li>
                </ul></li>

            <li>회원이 제7차 [항공장애표시등 민원서비스]에 본 약관에 따라 이용신청을 하는 것은 제7차
              [항공장애표시등 민원서비스]이 본 약관에 따라 신청서에 기재된 회원 정보를 수집, 이용 및
              제공하는 것에 동의하는 것으로 간주됩니다.</li>
          </ul>
        </div>

        <h5>제 7조 (사용자의 정보 보안)</h5>
        <div className="list-box">
          <ul>
            <li> 가입 신청자가 제7차 [항공장애표시등 민원서비스] 서비스 가입 절차를 완료하는 순간부터 귀하는 입력한
              정보의 비밀을 유지 할 책임이 있으며, 회원의 제7차 [항공장애표시등 민원서비스] ID와 비밀번호를
              사용하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스] ID 와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며,
              회원의 제7차 [항공장애표시등 민원서비스] ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는
              즉시 제7차 [항공장애표시등 민원서비스]에 신고하여야 합니다. 신고를 하지 않음으로
              인한 모든 책임은 회원 본인에게 있습니다.</li>
            <li>이용자는 제7차 [항공장애표시등 민원서비스] 서비스의 사용 종료 시마다 정확히 접속을 종료하도록 해야 하며,
              정확히 종료하지 아니함으로써 제3자가 귀하에 관한 정보를 이용하게 되는 등의 결과로 인해 발생하는 손해 및
              손실에 대하여 제7차 [항공장애표시등 민원서비스]는 책임을 부담하지 아니합니다.</li>
          </ul>
        </div>

        <h5>제 8조 (서비스 이용시간)</h5>
        <div className="list-box">
          <ul>
            <li> 서비스 이용시간은 제7차 [항공장애표시등 민원서비스]의 업무상 또는
              기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</li>
            <li> 제1항의 이용시간은 정기점검 등의 필요로 인하여 제7차
              [항공장애표시등 민원서비스]이 정한 날 또는 시간은 예외로 합니다.</li>
          </ul>
        </div>

        <h5>제 9조 (서비스의 중지 및 정보의 저장과 사용)</h5>
        <div className="list-box">
          <ul>
            <li> 제7차 [항공장애표시등 민원서비스]이 정상적인 서비스 제공의 어려움으로 인하여 일시적으로
              서비스를 중지하여야 할 경우에는 서비스 중지 1주일전의 고지 후 서비스를 중지할 수 있으며,
              이 기간동안 귀하가 고지내용을 인지하지 못한데 대하여 제7차 [항공장애표시등 민원서비스]는
              책임을 부담하지 아니합니다. 부득이한 사정이 있을 경우 위 사전 고지기간은 감축되거나 생략될 수 있습니다.
              또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의
              내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에
              대하여도 제7차 [항공장애표시등 민원서비스]는 책임을 부담하지 아니합니다.</li>
            <li>제7차 [항공장애표시등 민원서비스]의 사정으로 서비스를 영구적으로 중단하여야 할 경우 전항에 의거합니다.
              다만, 이 경우 사전 고지기간은 1개월로 합니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며,
              이에 대하여 귀하 또는 제3자에게 어떠한 책임도 부담하지 아니합니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 이용자가 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스
              사용을 제한 및 중지 할 수 있습니다. 이 경우 제7차 [항공장애표시등 민원서비스]는 위 이용자의 접속을
              금지할 수 있으며, 위 이용자가 설정한 domain 명칭, 게시한 내용의 전부 또는 일부를 임의로 삭제할 수 있습니다.</li>
            <li>장기간 휴면 회원인 경우 안내 메일 또는 공지사항 발표 후 1주일간의 통지 기간을 거쳐 서비스 사용을 중지할 수 있습니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 회원이 가입 시 설정하는 domain 명칭 기타 정보
              (이하 "domain등")에 대한 심사 후 그 변경의 권유 및 삭제 권한을 보유하게 됩니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 가입자가 설정, 관리하는
              "domain등"에 대하여 당해 "domain등"의 이용률, 가입자의 본 서비스 이용 목적과 가입자가 설정,
              관리하는 "domain등" 명칭의 적합성, 기타 제반 사유 등에 비추어 당해 "domain등"을 변경, 삭제하여야
              할 필요성이 있을 때에는 그 변경 또는 삭제를 가입자에게 1주일의 기간을 두어 권고하고, 그 기간
              동안 가입자의 아무런 조치가 없을 경우 임의로 변경 또는 삭제 할 수 있습니다.</li>
          </ul>
        </div>

        <h5>제 10조 (서비스의 변경 및 해지)</h5>
        <div className="list-box">
          <ul>
            <li>제7차 [항공장애표시등 민원서비스]는 귀하가 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은
              자료로 인한 손해에 관하여 책임을 지지 않으며, 회원이 본 서비스에 게재한 정보, 자료, 사실의 신뢰도,
              정확성 등 내용에 관하여는 책임을 지지 않습니다.</li>
            <li>제7차 [항공장애표시등 민원서비스]는 서비스 이용과 관련하여 가입자에게 발생한 손해 중 가입자의 고의,
              과실에 의한 손해에 대하여 책임을 부담하지 아니합니다.</li>
          </ul>
        </div>

        <h5>제 11조 (정보 제공 및 광고의 게재)</h5>
        <div className="list-box">
          <ul>
            <li> 제7차 [항공장애표시등 민원서비스]는 서비스를 운영함에 있어서 각종 정보를 서비스에 게재하는
              방법 등으로 회원에게 제공할 수 있습니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 서비스에 적절하다고 판단되거나 활용 가능성 있는 광고를
              게재할 수 있습니다.</li>
          </ul>
        </div>

        <h5>제 12조 (당사에 제출된 게시물의 저작권)</h5>
        <div className="list-box">
          <ul>
            <li> 귀하가 게시한 게시물의 내용에 대한 권리는 귀하에게 있습니다.</li>
            <li>제7차 [항공장애표시등 민원서비스]는 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며,
              다음의 경우 사전 통지 없이 삭제 할 수 있습니다.
              <br/>
                <ul >
                  <li>
                    1) 본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우</li>
                  <li>2) 다른 회원 또는 제 3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용인 경우</li>
                  <li>3) 공공질서 및 미풍양속에 위반되는 내용인 경우</li>
                  <li>4) 범죄적 행위에 결부된다고 인정되는 내용일 경우</li>
                  <li>5) 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
                  <li>6) 탈퇴자가 게시한 게시물</li>
                  <li>7) 기타 관계 법령에 위배되는 경우</li>
                </ul></li>

            <li> 귀하의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 귀하가 부담하여야 합니다.</li>
          </ul>
        </div>

        <h5>제 13조 (사용자의 행동규범 및 서비스 이용제한)</h5>
        <div className="list-box">
          <ul>
            <li>귀하가 제공하는 정보의 내용이 허위(차명, 비실명 등)인 것으로 판명되거나, 그러하다고 의심할 만한 합리적인
              사유가 발생할 경우 제7차 [항공장애표시등 민원서비스]는 귀하의 본 서비스 사용을 일부 또는 전부 중지할 수 있으며,
              이로 인해 발생하는 불이익에 대해 책임을 부담하지 아니합니다.</li>
            <li> 귀하가 제7차 [항공장애표시등 민원서비스] 서비스를 통하여 게시, 전송, 입수하였거나 전자메일 기타 다른 수단에 의하여 게시,
              전송 또는 입수한 모든 형태의 정보에 대하여는 귀하가 모든 책임을 부담하며 제7차
              [항공장애표시등 민원서비스]는 어떠한 책임도 부담하지 아니합니다.</li>
            <li>제7차 [항공장애표시등 민원서비스]는 제7차 [항공장애표시등 민원서비스]이 제공한 서비스가 아닌 가입자 또는
              기타 제휴사가 제공하는 서비스의 내용상의 정확성, 완전성 및 질에 대하여 보장하지 않습니다. 따라서 제7차
              [항공장애표시등 민원서비스]는 귀하가 위 내용을 이용함으로 인하여 입게 된 모든 종류의 손실이나 손해에
              대하여 책임을 부담하지 아니합니다.</li>
            <li> 귀하는 본 서비스를 통하여 다음과 같은 행동을 하지 않는데 동의합니다.

              <br/>
                <ul>
                  <li>1) 타인의 제7차 [항공장애표시등 민원서비스] ID와 비밀번호, 주민번호를 도용하는 행위</li>
                  <li>2) 저속, 음란, 모욕적, 위협적이거나 타인의 프라이버시를 침해할 수 있는 내용을 전송, 게시,
                    게재, 전자 메일 또는 기타의 방법으로 전송하는 행위</li>
                  <li>3) 서비스를 통하여 전송된 내용의 출처를 위장하는 행위</li>
                  <li>4) 법률, 계약에 의하여 이용할 수 없는 내용을 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위</li>
                  <li>5) 타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을 침해하는 내용을 게시, 게재, 전자메일
                    또는 기타의 방법으로 전송하는 행위</li>
                  <li>6) 제7차 [항공장애표시등 민원서비스]의 승인을 받지 아니한 광고, 판촉물, 정크메일, 스팸, 행운의 편지,
                    피라미드 조직 기타 다른 형태의 권유를 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위.</li>
                  <li>7) 다른 사용자의 개인정보를 수집 또는 저장하는 행위</li>
                  <li>8) 실질적으로 사용하지 아니하는 domain 명칭의 등록행위, 이용하는 커뮤니티 및 기타 서비스의 본래
                    목적 또는 내용과 부합하지 아니하는 domain 명칭의 등록행위, 서비스 이용을 주된 목적으로 하지 아니하는 일체의 행위.</li>
                </ul>
            </li>
            <li>제7차 [항공장애표시등 민원서비스]는 회원이 본 약관을 위배했다고 판단되면 서비스와 관련된 모든 정보를 이용자의 동의없이 삭제할 수 있습니다.</li>
          </ul>
        </div>

        <h4>제 3 장 의무 및 책임</h4>
        <h5>제 14조 (제7차 [항공장애표시등 민원서비스]의 의무)</h5>
        <div className="list-box">
          <ul>
            <li> 제7차 [항공장애표시등 민원서비스]는 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적, 안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 특별한 사정이 없는 한 이용자가 신청한 서비스 제공 개시일에 서비스를 이용할 수 있도록 합니다.</li>
            <li>제7차 [항공장애표시등 민원서비스]는 회원의 개인 신상 정보를 본인의 승낙 없이 타인에게 누설, 배포하여서는 아니 됩니다.
              다만, 전기통신관련법령 등 관계법령에 의하여 관계 국가기관 등의 요구가 있는 경우에는 그러하지 아니합니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 이용자가 안전하게 제7차 [항공장애표시등 민원서비스] 서비스를 이용할 수
              있도록 이용자의 개인정보 (신용정보포함) 보호를 위한 보안시스템을 갖추어야 합니다.</li>
            <li>제7차 [항공장애표시등 민원서비스]는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.</li>
          </ul>
        </div>

        <h5>제 15조 (회원의 의무)</h5>
        <div className="list-box">
          <ul>
            <li> 회원 가입 시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한 이미 제공된 귀하에 대한 정보가
              정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 제7차 [항공장애표시등 민원서비스] ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
            <li>회원은 제7차 [항공장애표시등 민원서비스]의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.</li>
            <li>회원은 제7차 [항공장애표시등 민원서비스] 서비스를 이용하여 얻은 정보를 제7차 [항공장애표시등 민원서비스]의
              사전승낙 없이 복사, 복제, 변경, 번역, 출판·방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.</li>
            <li> 회원은 제7차 [항공장애표시등 민원서비스] 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
              <br/>
                <ul>
                  <li>
                    1) 다른 회원의 제7차 [항공장애표시등 민원서비스] ID를 부정 사용하는 행위</li>
                  <li>2) 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</li>
                  <li>3) 선량한 풍속, 기타 사회질서를 해하는 행위</li>
                  <li>4) 타인의 명예를 훼손하거나 모욕하는 행위</li>
                  <li>5) 타인의 지적재산권 등의 권리를 침해하는 행위</li>
                  <li>6) 해킹행위 또는 컴퓨터바이러스의 유포행위</li>
                  <li>7) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위</li>
                  <li>8) 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</li>
                  <li>9) 제7차 [항공장애표시등 민원서비스] 사이트에 게시된 정보의 변경.</li>
                  <li>10) 기타 전기통신법 제53조와 전기통신사업법 시행령 16조(불온통신), 통신사업법 제53조3항에 위배되는 행위</li>
                </ul>
            </li>
          </ul>
        </div>

        <h5>제 16조 (공개게시물의 삭제)</h5>
        <div className="list-box">
          <ul>
            <li>이용자의 공개게시물의 내용이 다음 각 호에 해당하는 경우 제7차 [항공장애표시등 민원서비스]는 이용자에게 사전
              통지없이 해당 공개게시물을 삭제할 수 있고, 해당 이용자의 회원 자격을 제한, 정지 또는 상실 시킬 수 있습니다.
              <br/>
                <ul>
                  <li>
                    1) 다른 이용자 또는 제3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용</li>
                  <li>2) 공서양속에 위반되는 내용의 정보, 문장, 도형 등을 유포하는 내용</li>
                  <li>3) 범죄행위와 관련이 있다고 판단되는 내용</li>
                  <li>4) 다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용</li>
                  <li>5) 기타 관계 법령에 위배된다고 판단되는 내용</li>
                </ul>
            </li>
            <li>① 제7차 [항공장애표시등 민원서비스]이 작성한 저작물에 대한 저작권 기타 지적재산권은
              제7차 [항공장애표시등 민원서비스]에 귀속합니다.</li>
            <li>② 이용자는 제7차 [항공장애표시등 민원서비스] 사이트를 이용함으로써 얻은 정보를
              제7차 [항공장애표시등 민원서비스]의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여
              영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</li>
          </ul>
        </div>


        <h4>제 4장 기 타</h4>
        <h5>제 17조 (제7차 [항공장애표시등 민원서비스]의 소유권)</h5>
        <div className="list-box">
          <ul>
            <li>제7차 [항공장애표시등 민원서비스]이 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인,
              서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 제7차 [항공장애표시등 민원서비스]에게 소유권이 있습니다.</li>
            <li> 귀하는 제7차 [항공장애표시등 민원서비스]이 명시적으로 승인한 경우를 제외하고는 전항의 소정의 각 재산에
              대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없으며,
              제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.</li>
          </ul>
        </div>

        <h5>제 18조 (양도금지)</h5>
        <div className="list-box">
          <ul>
            <li>회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공 할 수 없습니다.</li>
          </ul>
        </div>

        <h5>제 19조 (손해배상)</h5>
        <div className="list-box">
          <ul>
            <li>제7차 [항공장애표시등 민원서비스]는 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 제7차
              [항공장애표시등 민원서비스]이 고의로 행한 범죄행위를 제외하고 이에 대하여 책임을 부담하지 아니합니다.</li>
          </ul>
        </div>

        <h5>제 20조 (면책조항)</h5>
        <div className="list-box">
          <ul>
            <li> 제7차 [항공장애표시등 민원서비스]는 서비스에 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없으며
              회원이나 제3자에 의해 표출된 의견을 승인하거나 반대하거나 수정하지 않습니다. 제7차 [항공장애표시등 민원서비스]는
              어떠한 경우라도 회원이 서비스에 담긴 정보에 의존해 잃은 이득이나 입은 해에 대해 책임이 없습니다.</li>
            <li> 제7차 [항공장애표시등 민원서비스]는 회원간 또는 회원과 제3자간에 서비스를 매개로 하여 물품거래 혹은 금전적
              거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.</li>
          </ul>
        </div>

        <h4>부 칙</h4>
        <h5>제 1조 (시행일)</h5>
        <div className="list-box">
          <ul>
            <li>이 약관은 2014년 10월 01일로 부터 시행한다.</li>
          </ul>
        </div> 
      </div>
    </div>
  )
}

