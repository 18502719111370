import {PageTitle} from "../../comp-shared/PageTitle";
import "./RebrokenComplaint.scss"
import {useNavigate, useParams} from "react-router-dom";
import BrokenCycle from "../../comp-shared/Broken/BrokenCycle";
import {ChangeEvent, useEffect, useState} from "react";
import {checkLoginAndRedirect} from "../../util/loginManager";
import axios from "axios";
import {CommonBrokenInfoRes} from "../../server/user/myComplaintDto";
import {parseErrorMessage} from "../../util/errorParser";
import {TabSelector} from "../../comp-shared/TabSelector";
import {PostRebrokenComplaintReq} from "../../server/complaint/brokenComplaintDto";
import moment from "moment";
import {
  documentAccept,
  documentAndVideoAccept,
  FileUploadEvent,
  FileUploadInput
} from "../../comp-shared/FileUploadInput";
import BrokenStatusInfo from "../../comp-shared/Broken/BrokenStatusInfo";
import ReactLoading from "react-loading";
import {useMyBrokenComplaint} from "../../comp-shared/Broken/useMyBrokenComplaint";

export default function RebrokenComplaint() {
  const navigate = useNavigate();
  const params = useParams();

  const [isSearchByMngCardNum, setIsSearchByMngCardNum]
    = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const {
    selectedPage,
    setSelectedPage,
    rebrokenComplaint,
    setRebrokenComplaint,
    selectedRebrokenComplaint,
    isRestored,
    isOnRestore,
    isCycleEnd} = useMyBrokenComplaint();

  const postDisabled = selectedRebrokenComplaint.myRebrokenComplaintList
    .some(rebroken => rebroken.stts === -1);
  const [isProgress, setIsProgress] = useState(false);
  const [postRebrokenReq, setPostRebrokenReq]
    = useState<PostRebrokenComplaintReq>(
      {mngCardId: 0, dclrId: 0, brokenYmd: '', rstrYmd: '', rstrDlyRsn: '', fileId: null});

  useEffect(() => {
    checkLoginAndRedirect(navigate)
    init()
  }, []);

  const init = async () => {
    try {
      setIsLoading(true);
      if (params.hasOwnProperty("id")) {
        let res = await axios.get<CommonBrokenInfoRes[]>("/complaint/my-complaint/rebroken/"+params.id)
        setRebrokenComplaint(res.data)
        setIsSearchByMngCardNum(false)
      }
      if (params.hasOwnProperty("mngCardNum")) {
        let res = await axios.post<CommonBrokenInfoRes>(`/complaint/mng-card/broken/get-info`,{mngNo:params.mngCardNum})
        setRebrokenComplaint([res.data])
        setIsSearchByMngCardNum(true)
      }
    } catch (e) {
      alert("데이터를 불러오지 못했습니다. "+parseErrorMessage(e))
      navigate("/broken/rebroken")
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setPostRebrokenReq(
      {...postRebrokenReq,
        mngCardId: selectedRebrokenComplaint.mngCardId,
        dclrId: selectedRebrokenComplaint.myRebrokenComplaintList[0].dclrId,
        brokenYmd: selectedRebrokenComplaint.myRebrokenComplaintList[0].brokenYmd,
        rstrYmd: '',
        rstrDlyRsn: '',
        fileId: null
      })
  }, [rebrokenComplaint, selectedPage]);

  const onChangeStr = (e: ChangeEvent<HTMLInputElement>) => 
    setPostRebrokenReq({...postRebrokenReq, [e.target.name]: e.target.value});
  const onChangeFile = (e: FileUploadEvent) => 
    setPostRebrokenReq({...postRebrokenReq, [e.name]: e.fileId});

  const onClickToPostRebroken = async () => {
    if (postRebrokenReq.rstrYmd === '') {
      alert("복구 예정일자를 입력해주세요.")
      return
    }
    if (postRebrokenReq.rstrDlyRsn === '') {
      alert("복구 지연사유를 입력해주세요.")
      return
    }
    if (!window.confirm("고장 재통지를 신청하시겠습니까?")) return
    try {
      setIsProgress(true)
      await axios.post("/complaint/rebroken", postRebrokenReq)
      alert("고장 재통지 신청이 완료되었습니다.")
      init();
    } catch (e) {
      alert(parseErrorMessage(e))
    } finally {
      setIsProgress(false)
    }
  }

  if (isLoading) return <div className='RebrokenComplaint flex-center' style={{height: '70vh'}}>
    <ReactLoading type="spin" color="#c3d0e4" />
  </div>

  return (
    <div className='RebrokenComplaint'>
      <PageTitle title="고장 재통지"/>
      { !isSearchByMngCardNum &&
          <TabSelector selectedPage={selectedPage} setSelectedPage={setSelectedPage} data={rebrokenComplaint ?? []}/>
      }
      <BrokenStatusInfo
        type="Rebroken"
        mngNo={selectedRebrokenComplaint.mngNo}
        isCycleEnd={isCycleEnd} isOnRestore={isOnRestore} isRestored={isRestored}
      />
      { !isOnRestore && !isCycleEnd && <div className='rebroken-grid mb'>
          <div className='header green' style={{gridColumn: 'auto / span 4'}}>
              관리번호 : ( {selectedRebrokenComplaint.mngNo} ) 고장 재통지
          </div>
          <div className='header green'>고장연월일</div>
          <div className='field'>
            {selectedRebrokenComplaint.myRebrokenComplaintList[0].brokenYmd}
          </div>
          <div className='header green'>복구 예정일자</div>
          <div className='field'>
              <input
                  className='input full-width'
                  type={"date"}
                  name={"rstrYmd"}
                  value={postRebrokenReq.rstrYmd}
                  onChange={onChangeStr}
                  disabled={postDisabled}
                  min={selectedRebrokenComplaint.myRebrokenComplaintList
                    .reduce((minRstrYmd, rebrokenItem) =>
                        minRstrYmd.isAfter(rebrokenItem.rstrYmd) ? minRstrYmd : moment(rebrokenItem.rstrYmd),
                      moment("1900-01-01"))
                    .format("YYYY-MM-DD")}
              />
          </div>
        {postDisabled ? <div className='field' style={{gridColumn: 'auto / span 4'}}>
            <span className='alert-red-font'>재통지 승인 완료 후 추가 등록을 하실 수 있습니다.</span></div>
          : <>
            <div className='header green'>복구 지연사유 /<br/>첨부파일</div>
            <div className='field' style={{gridColumn: 'auto / span 2', height: '70px'}}>
              <input
                className='input full-width'
                type={"text"}
                name={"rstrDlyRsn"}
                value={postRebrokenReq.rstrDlyRsn}
                onChange={onChangeStr}
              />
            </div>
            <div className='field'>
              <FileUploadInput
                downloadName="첨부파일"
                name="fileId"
                value={postRebrokenReq.fileId}
                onChange={onChangeFile}
                accept={documentAndVideoAccept}
              />
            </div>
          </>}
      </div>}
      {!isOnRestore && !isCycleEnd && !postDisabled && <div className='flex-btn'>
          <span>※ 각 관리번호 개별로 신청하시기 바랍니다. (묶음 신청 불가)</span>
          <button
              className='button'
              onClick={onClickToPostRebroken}
              disabled={isProgress}
          >고장 재통지
          </button>
      </div>}
      {selectedRebrokenComplaint.myRebrokenComplaintList
        .map((rebrokenItem) => {
          if (rebrokenItem)
            return <BrokenCycle cycleIndex={rebrokenItem.cycl + 1} 
                                mutate={init}
                                myRebrokenComplaintItem={rebrokenItem} 
                                key={rebrokenItem.cycl + 1}/>
        })
        .reverse()}
    </div>
  )
}
