import React from "react";
import {QuestionBtn} from "./QuestionBtn";

export const Question2 = (props: {
  qnaCheck: string
  setQnaCheck: (value: string) => void;
}) => {
  return (
    <div>
      <h3>설치하고자 하는 대상의 종류를 선택해 주세요.</h3>
      <div className="detail-block">
        <QuestionBtn value={'1'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'건축물 등'}/>
        <QuestionBtn value={'2'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'굴뚝, 철탑, 기둥 기타 높이에 비해 그 폭이 좁은 물체 및 이들에 부착된 지선'}/>
        <QuestionBtn value={'3'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'철탑, 건설크레인 등 뼈대로 이루어진 구조물'}/>
        <QuestionBtn value={'4'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'건축물이나 구조물 위에 추가 설치한 철탑·송전탑·공중선 등'}/>
        <QuestionBtn value={'5'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'가공선이나 케이블·현수선과 이들을 지지하는 탑'}/>
        <QuestionBtn value={'6'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'계류기구와 계류용 선(주간에 시정이 5,000m 미만인 경우와 야간에 계류하는 것에 한한다.'}/>
        <QuestionBtn value={'7'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'풍력터빈'}/>
        <QuestionBtn value={'8'} checkedValue={props.qnaCheck}
                     setCheckedValue={props.setQnaCheck}
                     label={'건설중인 구조물'}/>
      </div>
    </div>
  )
}