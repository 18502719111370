import {ComplaintBase} from "./complaintBaseDto";
import {AwSignCode, AwSignReplaceCode} from "../code";

export interface PostBrokenComplaintReq {
  signerNm: string;
  signFileId: number | null;
  declaration: BrokenDeclaration[];
}

export interface BrokenDeclaration extends ComplaintBase{
  brokenYmd:string;
  rstrYmd: string;
  knd:string;
  cn:string;
  rstrDlyRsn:string;
  tel:string;
  jbttl:string;
  fax:string;
  brokenFileId: number | null;
}

export const getDefaultPostBrokenComplaintReq = ():PostBrokenComplaintReq => ({
  signerNm: "",
  signFileId: null,
  declaration: [getDefaultBrokenDeclaration()]
});

export const getDefaultBrokenDeclaration = ():BrokenDeclaration => ({
  id:null,
  no:"",
  mngCardNo:"",
  mngCardId:null, 
  kepco: false,
  obstZip: "",
  obstAddr: "",
  obstNm: "",
  aviationAgencyCd: null,
  sareaCd: null,
  rstrcZoneCd: "001",
  dclNm: "",
  dclBrno: "",
  dclZip: "",
  dclAddr: "",
  instlYmd: "",
  crdNorth: "",
  crdEast: "",
  obstType: "001",
  lctnHgt: null,
  hgtFromGrnd: null,
  hgtFromSea: null,
  horizontalWdth: null,
  vrtcWdth: null,
  airport: "1",
  distFromAirport: null,
  bearingFromAirport: "",
  cluster: false,
  awlHighANum: null,
  awlHighBNum: null,
  awlHighConcrnt: false,
  awlHighInstlFileId: null,
  awlHighPrdctId: null,
  awlHighPrdctNm: "",
  awlHighPrdctCertFileId: null,
  awlMdANum: null,
  awlMdAConcrnt: false,
  awlMdAInstlFileId: null,
  awlMdAPrdctId: null,
  awlMdAPrdctNm: "",
  awlMdAPrdctCertFileId: null,
  awlMdBNum: null,
  awlMdCNum: null,
  awlMdBcConcrnt: false,
  awlMdBcInstlFileId: null,
  awlMdBcPrdctId: null,
  awlMdBcPrdctNm: "",
  awlMdBcPrdctCertFileId: null,
  awlLowANum: null,
  awlLowBNum: null,
  awlLowCNum: null,
  awlLowDNum: null,
  awlLowENum: null,
  awlLowConcrnt: false,
  awlLowInstlFileId: null,
  awlLowPrdctId: null,
  awlLowPrdctNm: "",
  awlLowPrdctCertFileId: null,
  awsignType: "006",
  awsignReplace: "고광도 A로 대체",
  awsignPaintingDevide: "",
  awsignSignRedNum: null,
  awsignSignWhiteNum: null,
  awsignSignGap: null,
  awsignFlagSingleColr: null,
  awsignFlagCheckPattern: null,
  awsignFlagWhiteOrangeColr: null,
  mngrNm: "",
  mngrTel: "",
  mngrJbttl: "",
  mngrFax: "",
  brokenYmd:"",
  rstrYmd: "",
  knd:"",
  cn:"",
  rstrDlyRsn:"",
  tel:"",
  jbttl:"",
  fax:"",
  brokenFileId: null
});

export interface PostRebrokenComplaintReq {
  mngCardId: number;
  dclrId: number;
  brokenYmd: string;
  rstrYmd: string;
  rstrDlyRsn: string;
  fileId: number | null;
}

export interface UpdateRebrokenComplaintReq {
  rstrYmd: string;
  rstrCmptnYmd: string;
  rstrDlyRsn: string;
  fileId: number | null;
  [index: string]: any;
}

export interface PostRestoreComplaintReq {
  mngCardId: number;
  dclrId: number;
  brokenYmd: string;
  rstrCmptnYmd: string;
  fileId: number;
}