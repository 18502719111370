import {QuestionBtn} from "./QuestionBtn";
import React from "react";

export const Question3 = (props: {
  qnaCheck: string
  setQnaCheck: (value: string) => void;
}) => {
  return (
    <div id="question-step3">
      <div>
        <h3>설치하고자 하는 항공장애표시 방법을 선택해 주세요</h3>
        <div className="detail-block">
          <QuestionBtn value={'1'} checkedValue={props.qnaCheck}
                       setCheckedValue={props.setQnaCheck}
                       label={'항공장애주간표지 설치'}/>
          <QuestionBtn value={'2'} checkedValue={props.qnaCheck}
                       setCheckedValue={props.setQnaCheck}
                       label={'고광도 A, B, 중광도 A 설치'}/>
        </div>
      </div>
    </div>
  )
}