import React, {useEffect, useState} from 'react';
import "./MyComplaint.scss"
import {parseJWT, checkLoginAndRedirect} from "../../util/loginManager";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../comp-shared/PageTitle";
import ListPager from "../../comp-shared/ListPager";
import {useSWRMyComplaint} from "../../server/server";
import {AviationAgency, DeclarationKind, DeclarationStts} from "../../server/code";
import {timeToKorean, timeToKoreanDate} from "../../util/timeutil";
import {GetMyComplaintRes} from "../../server/user/myComplaintDto";

export const MyComplaint = () => {
  const navigate = useNavigate();
  const [page,setPage] = useState<number>(0)
  const {data:myComplaint} = useSWRMyComplaint(page) 
  
  useEffect(() => {
    checkLoginAndRedirect(navigate)
  },[]);

  const onClickMyComplaint = (complaint: GetMyComplaintRes) => {
    let complaintEditPath = "";
    switch (complaint.dclrCd) {
      case "I":complaintEditPath = "install";break;
      case "C":complaintEditPath = "change";break;
      case "D":complaintEditPath = "demolish";break;
      case "B":complaintEditPath = "broken";break;
    }
    navigate(`/user/my-complaint/${complaintEditPath}/${complaint.id}`);
  }
  
  return (
    <div className="MyComplaint">
      <PageTitle title="나의 민원"/>
      <table className="table clickable">
        <thead>
          <tr>
            <th>접수번호</th>
            <th>종류</th>
            <th>항공청</th>
            <th>신청일</th>
            <th>처리현황</th>
          </tr>
        </thead>
        <tbody>
        {myComplaint?.content.map((complaint) => (
          <tr key={complaint.id} onClick={()=>onClickMyComplaint(complaint)}>
            <td>{complaint.cvlcptNo}</td>
            <td>{DeclarationKind[complaint.dclrCd]}</td>
            <td>{AviationAgency[complaint.aviationAgencyCd]}</td>
            <td>{timeToKoreanDate(complaint.chgDt)}</td>
            <td>{DeclarationStts[complaint.stts]}</td>
          </tr>
        ))}
        </tbody>
      </table>
      
      <ListPager page={page} pageItem={myComplaint} onChangePage={setPage}/>
    </div>
  )
}

