import React from 'react';
import "./Help.scss"
import {Route, Routes, useLocation} from "react-router-dom";
import {BreadCrumb} from "../comp-shared/BreadCrumb";
import {LeftNaviagtor} from "../comp-shared/LeftNaviagtor";
import {LeftNavigatorItem} from "../comp-shared/LeftNavigator/LeftNavigatorItem";
import {ComplaintSample} from "./Help/ComplaintSample";
import {Restrict} from "./Help/Restrict";
import {DocumentSample} from "./Help/DocumentSample";
import {SignSample} from "./Help/SignSample";
import {InquiryBox} from "../comp-shared/InquiryBox";

export const Help = () => {
  const location = useLocation();
  const getBreadcrumbPath = () => {
    return ["신고서 작성예시"]
  };

  return (
    <div className="Introduce">
      <BreadCrumb path={getBreadcrumbPath()}/>
      <div className="container">
        <div className="container-inbox">
          <div>
            <LeftNaviagtor title="안내">
              <LeftNavigatorItem path="/help/complaint-sample"> 신고서 작성예시 </LeftNavigatorItem>
              <LeftNavigatorItem path="/help/restrict"> 장애물제한구역 확인방법 </LeftNavigatorItem>
              <LeftNavigatorItem path="/help/document-sample"> 제출서류 예시 </LeftNavigatorItem>
              <LeftNavigatorItem path="/help/sign-sample"> 서명파일 예시 (등록방법) </LeftNavigatorItem>
            </LeftNaviagtor>
            <InquiryBox />
          </div>
          <Routes>
            <Route path="complaint-sample" element={<ComplaintSample/>}/>
            <Route path="restrict" element={<Restrict/>}/>
            <Route path="document-sample" element={<DocumentSample/>}/>
            <Route path="sign-sample" element={<SignSample/>}/>
            <Route path="*" element={<ComplaintSample/>}/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

