import React, {ChangeEvent, useEffect, useState} from 'react';
import "./BrokenComplaint.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {AwSignCode, AwSignReplaceCode, ComplaintEditMode} from "../../server/code";
import {useImmer} from "use-immer";
import {useLocalStorage} from "usehooks-ts";
import {TempItem, TempLoaderModal} from "../../comp-shared/Complaint/TempLoaderModal";
import {checkLoginAndRedirect} from "../../util/loginManager";
import axios from "axios";
import {GetBrokenComplaintRes, GetChangeComplaintRes} from "../../server/user/myComplaintDto";
import {parseErrorMessage} from "../../util/errorParser";
import {documentAccept, FileUploadEvent, FileUploadInput} from "../../comp-shared/FileUploadInput";
import {GetMngCardRes} from "../../server/complaint/mngCardDto";
import {ComplaintBase} from "../../server/complaint/complaintBaseDto";
import {TabSelector} from "../../comp-shared/TabSelector";
import {MngCardLoader} from "../../comp-shared/Complaint/MngCardLoader";
import {ObstacleAddress} from "../../comp-shared/Complaint/ObstacleAddress";
import {DeclarationBasicInfo} from "../../comp-shared/Complaint/DeclarationBasicInfo";
import {Sign} from "../../comp-shared/Complaint/Sign";
import {Space} from "../../comp-shared/Space";
import {
  getDefaultBrokenDeclaration,
  getDefaultPostBrokenComplaintReq,
  PostBrokenComplaintReq
} from "../../server/complaint/brokenComplaintDto";
import moment from "moment";

export const BrokenComplaint = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isTempLoaderOpen, setTempLoaderOpen] = useState<boolean>(false);
  const [editMode,setEditMode] = useState<ComplaintEditMode>(
    params.hasOwnProperty("id") ? "edit":"create");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [complaint, updateComplaint] = useImmer<PostBrokenComplaintReq>(
    getDefaultPostBrokenComplaintReq());
  const [signDate, setSignDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const selectedDeclaration = complaint.declaration[selectedPage-1];

  const [tempComplaint, setTempComplaint] = useLocalStorage<TempItem[]>(
    "tempChangeComplaint",[]);
  const [loadedFromTemp, setLoadedFromTemp] = useState<number|null>(null);
  const [isProgress, setIsProgress] = useState<boolean>(false);

  const disabled = editMode === "readonly";


  useEffect(() => {
    checkLoginAndRedirect(navigate)
    init()
  },[]);

  const init = async () => {
    try {
      if (params.hasOwnProperty("id")) {
        let res = await axios.get<GetBrokenComplaintRes>("/user/my-complaint/broken/" + params.id);
        updateComplaint(res.data)
        setSignDate(res.data.chgDt);
        if (res.data.stts !== "S020100000" && !(res.data.stts === "S020200000" && res.data.mdfcnPrm))
          setEditMode("readonly")
      }
    } catch (e) {
      alert("민원을 불러오지 못했습니다. "+parseErrorMessage(e));
      navigate("/user/my-complaint")
    }
  }

  const onChangeStr = (e:ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.target.name] = e.target.value})
  const onChangeTextAreaStr = (e:ChangeEvent<HTMLTextAreaElement>) =>
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.target.name] = e.target.value})
  const onChangeSelect = (e:ChangeEvent<HTMLSelectElement>) =>
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.target.name] = e.target.value})
  const onChangeFloat = (e:ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.target.name] = parseFloat(e.target.value)})
  const onChangeNumber = (e:ChangeEvent<HTMLInputElement>) => {
    if (e.target.maxLength > 0 && e.target.value.length > e.target.maxLength) return;
    updateComplaint(draft => {draft.declaration[selectedPage - 1][e.target.name] = parseInt(e.target.value)})
  }
  const onChangeChecked = (e:ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.target.name] = e.target.checked})

  const onChangeFile = async (e: FileUploadEvent) => {
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.name] = e.fileId})
  };

  const onClickAdd = () => {
    updateComplaint(draft => {
      draft.declaration.push(getDefaultBrokenDeclaration());
      setSelectedPage(draft.declaration.length);
    });
  };

  const onClickDelete = () => {
    if(selectedPage > 1){
      updateComplaint(draft => {
        draft.declaration = draft.declaration.filter((_,i) => i !== selectedPage-1);
      });
      setSelectedPage(selectedPage - 1);
    }else{
      alert("첫번째 신고서는 삭제할 수 없습니다.")
    }
  };

  const onClickSave = async () => {
    if (!window.confirm("민원을 신청하시겠습니까?")) return;
    try {
      setIsProgress(true)
      await axios.post("/complaint/broken", complaint)
      alert("민원 신청이 완료되었습니다.")
      // 신청 완료되면 해당 임시저장 삭제
      if (loadedFromTemp !== null)
        setTempComplaint(tempComplaint.filter(temp => temp.id !== loadedFromTemp));
      navigate("/")
    }catch (e){
      alert(parseErrorMessage(e));
    }finally {
      setIsProgress(false)
    }
  };
  const onClickSaveEdit = async () => {
    if (!window.confirm("민원을 수정하시겠습니까?")) return;
    try {
      setIsProgress(true)
      await axios.post(`/complaint/broken/edit/${params.id}`, complaint)
      alert("민원 수정이 완료되었습니다.")
      navigate("/user/my-complaint")
    } catch (e) {
      alert(parseErrorMessage(e));
    }finally {
      setIsProgress(false)
    }
  };
  const onClickCancel = async () => {
    if (!window.confirm("민원을 취하하시겠습니까?")) return;
    try {
      setIsProgress(true)
      await axios.post(`/complaint/remove/${params.id}`)
      alert("민원이 취하되었습니다.")
      navigate("/user/my-complaint")
    } catch (e) {
      alert(parseErrorMessage(e));
    }finally {
      setIsProgress(false)
    }
  };

  const onClickAwsignReplace = (value:AwSignReplaceCode) =>
    updateComplaint(draft => {draft.declaration[selectedPage - 1].chgAwsignReplace = value as AwSignReplaceCode})
  const onChangeSignerName = (name:string) => updateComplaint(draft => {
    draft.signerNm = name
  });
  const onChangeSignFile = (fileId:number|null) => updateComplaint(draft => {
    draft.signFileId = fileId
  });

  const onCloseTempLoader = () => setTempLoaderOpen(false);
  const onClickOpenTempLoader = () => setTempLoaderOpen(true);

  const onLoadTemp = (tempItem:TempItem) => {
    setLoadedFromTemp(tempItem.id);
    updateComplaint(tempItem.complaint);
  }

  const onDeleteTemp = (id:number) => {
    if (!window.confirm("임시저장을 삭제하시겠습니까?")) return;
    setTempComplaint(tempComplaint.filter(temp => temp.id !== id))
  };
  const onClickSaveTemp = () => {
    if (!window.confirm("임시저장 하시겠습니까?")) return;
    let id = 1;
    if (tempComplaint.length > 0) id = Math.max(...tempComplaint.map(value => value.id))+1

    setTempComplaint([ {
      id: id,
      createdAt: new Date().toLocaleString(),
      complaint: complaint
    },...tempComplaint,]);
    alert("저장된 내역은 신고서 상단의 임시상단 불러오기를 클릭해 불러오실 수 있습니다.");
  }

  const onLoadMngCard = (mngCard:GetMngCardRes) => {
    updateComplaint(draft => {
      draft.declaration[selectedPage-1] = {...draft.declaration[selectedPage-1], ...mngCard,
        id:null, mngCardId:mngCard.id,mngCardNo:mngCard.mngNo
      };
    })
  };
  const updateDeclaration = (callback:(complaint:ComplaintBase)=>void) => {
    updateComplaint(draft => {
      callback(draft.declaration[selectedPage - 1]);
    });
  }
  return (
    <>
    <div className="BrokenComplaint">
      <PageTitle title={editMode === "create" ? "고장통지" : "나의 민원 상세보기"}/>
      {editMode !== "readonly" ?
      <button className="button" style={{marginRight:"10px"}} onClick={onClickAdd}>신고서 추가</button>
        :null}
      
      {editMode === "create" ? <>
        <button className="button" style={{marginRight:"10px"}} onClick={onClickOpenTempLoader}>임시저장 불러오기</button>
      </>:null}
      <TabSelector selectedPage={selectedPage} setSelectedPage={setSelectedPage}
                   data={complaint.declaration}/>
      {editMode !== "readonly" ?
        <div style={{display:"flex",marginTop:"10px", marginBottom:"10px"}}>
          <MngCardLoader onLoad={onLoadMngCard}/>
          <button className="button" onClick={onClickDelete}>신고서 삭제</button>
        </div>:null}

      {selectedDeclaration.mngCardId != null ?<>
      <ObstacleAddress selectedDeclaration={selectedDeclaration}
                       updateDeclaration={updateDeclaration}
                       editMode="readonly"/>

      <DeclarationBasicInfo declarationName="고장신고서"
                            selectedPage={selectedPage}
                            selectedDeclaration={selectedDeclaration}
                            updateDeclaration={updateDeclaration}
                            editMode={editMode}/>
      <div className="edit-notice">↑관리담당자, 연락처, 팩스번호 변경 시 수정하여주시기 바랍니다.↑ </div>
        <div className="declaration-grid middle-info-box">
          <div className="header green" style={{gridRow:"auto / span 2", gridColumn:"auto / span 2"}}>고장연월일</div>
          <div className="field" style={{gridColumn:"auto / span 3"}} >
            <input className="input" type="date" name="brokenYmd"
                   onChange={onChangeStr} value={selectedDeclaration.brokenYmd} 
                   disabled={disabled}/>
          </div>
          <div className="field" style={{gridColumn:"auto / span 3"}} >
            고장일로부터 6일 이내에 신고하지 않은 경우 과태료가 부과됩니다. 고장연원일을 다시 확인하시기 바랍니다.
          </div>
          <div className="header green" style={{gridRow:"auto / span 4"}}>고장내용</div>
          <div className="header green">종류 및 수량</div>
          <div className="field" style={{gridColumn:"auto / span 3"}} >
            <input className="input" name="knd"
                   onChange={onChangeStr} value={selectedDeclaration.knd} 
                   disabled={disabled}/>
          </div>
          <div className="header green">고장내용</div>
          <div className="field" style={{gridColumn:"auto / span 3"}} >
            <textarea className="input" name="cn"
                   onChange={onChangeTextAreaStr} value={selectedDeclaration.cn}
                   disabled={disabled}/>
          </div>
          <div className="header green">복구 예정일자</div>
          <div className="field" style={{gridColumn:"auto / span 3"}} >
            <input className="input" type="date" name="rstrYmd"
                   onChange={onChangeStr} value={selectedDeclaration.rstrYmd} 
                   disabled={disabled}/>
          </div>
          <div className="header green">복구 지연사유</div>
          <div className="field">
            <textarea className="input" name="rstrDlyRsn"
                      onChange={onChangeTextAreaStr} value={selectedDeclaration.rstrDlyRsn}
                      disabled={disabled}/>
          </div>
          <div className="header green">파일첨부</div>
          <div className="field">
            <FileUploadInput downloadName="첨부파일"
                             name="brokenFileId"
                             value={selectedDeclaration.brokenFileId}
                             onChange={onChangeFile} disabled={disabled}
                             accept={documentAccept}/>
          </div>
          <div className="header green" style={{gridRow:"auto / span 2"}}>통지인</div>
          <div className="header green">연락처</div>
          <div className="field">
            <input className="input" name="tel"
                   onChange={onChangeStr} value={selectedDeclaration.tel}
                   disabled={disabled}/>
          </div>
          <div className="header green">직책</div>
          <div className="field">
            <input className="input" name="jbttl"
                   onChange={onChangeStr} value={selectedDeclaration.jbttl}
                   disabled={disabled}/>
          </div>
          <div className="header green">팩스</div>
          <div className="field">
            <input className="input" name="fax"
                   onChange={onChangeStr} value={selectedDeclaration.fax}
                   disabled={disabled}/>
          </div>
          <div className="header green" style={{gridColumn:"auto / span 2"}}></div>
        </div>
      </>:null}
      {complaint.declaration.some(value => value.mngCardId != null) ? <>
        <Sign name={complaint.signerNm} fileId={complaint.signFileId}
              onChangeSignFile={onChangeSignFile} onChangeName={onChangeSignerName}
              disabled={disabled} hideProcessImage={true} 
              text={"「항공장애 표시등과 항공장애 주간표지의 설치 및 관리기준」 제21조에 따라"+
                ` [V] 항공장애 표시등, [${complaint.declaration.some(value => value.awsignType != "006") ? "V":""}] 항공장애 주간표지의 고장을 통지합니다.`}
              signDate={signDate} />
        <div className="flex mt">
          <Space/>
          {editMode == "edit" ? <>
            <button className="button mr" onClick={onClickSaveEdit} disabled={isProgress}>민원수정</button>
            <button className="button" onClick={onClickCancel} disabled={isProgress}>민원취하</button>
          </>:null}
          {editMode == "create" ? <>
            <button className="button mr" onClick={onClickSaveTemp}>임시저장</button>
            <button className="button" onClick={onClickSave} disabled={isProgress}>민원신청</button>
          </>:null}
        </div>
      </>:null}
    </div>
    <TempLoaderModal isOpen={isTempLoaderOpen} onClose={onCloseTempLoader} tempItems={tempComplaint}
                     type="고장" onLoad={onLoadTemp} onDelete={onDeleteTemp}/>
    </>
    
  )
}

