import React from 'react';
import "./Restrict.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import Image1 from "../../resources/images/eum_1.png";
import Image2 from "../../resources/images/eum_2.png";
import Image3 from "../../resources/images/eum_3.png";
import Image4 from "../../resources/images/eum_4.png";
import ArrowBottom from "../../resources/images/arrow_bottom.png";

export const Restrict = () => {
  return (
    <div className="Restrict">
      <PageTitle title="장애물제한구역 확인방법"/>
      <li>1. “토지이음” 사이트에 (www.eum.go.kr)에 접속하시면 아래 이미지와 같은 화면이 나타납니다.</li>
      <img src={Image1} alt="이미지1"/>
      <div className="arrow-container">
        <img className="arrow" src={ArrowBottom} alt=""/>
      </div>
      <li>2. 화면 중간 주소검색을 이용하여 설치하려는 장소의 토지이용계획을 검색합니다.</li>
      <img src={Image2} alt="이미지2"/>
      <div className="arrow-container">
        <img className="arrow" src={ArrowBottom} alt=""/>
      </div>
      <li>3. 토지이용계획 검색 결과 아래 예시의 “다른 법령 등에 따른 지역ㆍ지구등”을 확인하시면 아래 이미지와 같이
        &gt;공항시설법(구 항공법)&lt;에 따른 장애물제한표면(진입표면, 수평표면, 원추표면, 전이표면, 착륙복행표면)이 지정되어 있다면 장애물제한구역(장애물제한표면이 지표 또는 수면에 수직으로 투영된 구역)입니다.</li>
      <img src={Image3} alt="이미지3"/>
      <div className="arrow-container">
        <img className="arrow" src={ArrowBottom} alt=""/>
      </div>
      <li>4. 아래 이미지와 같이 &gt;군사기지 및 군사시설보호법&lt;에 따른 비행안전구역(제2구역부터 제6구역)이 지정되어 있다면 비행안전구역입니다.</li>
      <img src={Image4} alt="이미지4"/>
      <div className="arrow-container">
        <img className="arrow" src={ArrowBottom} alt=""/>
      </div>
    </div>
  )
}

