import React from 'react';
import "./PlanSamplePopup.scss"

import sampleImage1 from "../../../resources/sampleimages/1-1.png";
import sampleImage2 from "../../../resources/sampleimages/1-2.png";
import sampleImage3 from "../../../resources/sampleimages/1-3.png";
import PopupPanel from "../../../comp-shared/Popup/PopupPanel";
import clsx from "clsx";

const images:{[index:string]:string} = {
  "철탑":sampleImage1,
  "가공선":sampleImage2,
  "항공장애등":sampleImage3
}
interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const PlanSamplePopup = (props:Props) => {
  const [selectedImage, setSelectedImage] = React.useState<string>("철탑");
  const onClickTab = (imageName:string) => {
    setSelectedImage(imageName);
  };
  return (
    <PopupPanel isOpen={props.isOpen} onClose={props.onClose} title="표시등 종류, 수량 및 설치위치가 포함된 도면">
      <div className="PlanSamplePopup">
        <div>
        {Object.keys(images).map((key)=> 
          <button key={key}
                  className={clsx("tab-button",{"selected":selectedImage == key})}
                  onClick={()=>onClickTab(key)}>{key}</button>
        )}
        </div>
        <img src={images[selectedImage]}/>
      </div>
    </PopupPanel>
  )
}

