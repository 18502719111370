import "./BrokenCycle.scss"
import {MyRebrokenComplaint} from "../../server/user/myComplaintDto";
import {documentAccept, FileUploadEvent, FileUploadInput} from "../FileUploadInput";
import {AvtsmtMthd} from "../../server/code";
import moment from "moment/moment";
import {ChangeEvent, useEffect, useState} from "react";
import {
  PostRebrokenComplaintReq,
  UpdateRebrokenComplaintReq
} from "../../server/complaint/brokenComplaintDto";
import {useImmer} from "use-immer";
import axios from "axios";
import {parseErrorMessage} from "../../util/errorParser";

interface Props {
  cycleIndex: number
  myRebrokenComplaintItem: MyRebrokenComplaint
  mutate:()=>void
}
export default function BrokenCycle(props: Props) {
  const isOnRestore = props.myRebrokenComplaintItem.rstrCmptnYmd !== null;
  const isWaiting = props.myRebrokenComplaintItem.stts === 2 || props.myRebrokenComplaintItem.stts === -1;
  const [req, updateReq] = useImmer<UpdateRebrokenComplaintReq>(
    {rstrYmd: '',rstrCmptnYmd:'', rstrDlyRsn: '', fileId: null});

  const onChangeStr = (e: ChangeEvent<HTMLInputElement>) =>
    updateReq(draft => {draft[e.target.name] = e.target.value})
  const onChangeTextAreaStr = (e: ChangeEvent<HTMLTextAreaElement>) =>
    updateReq(draft => {draft[e.target.name] = e.target.value})
  const onChangeFile = (e: FileUploadEvent) =>
    updateReq(draft => {draft[e.name] = e.fileId})

  useEffect(() => {
updateReq(draft => {
      draft.id = props.myRebrokenComplaintItem.id ?? 0
      draft.rstrYmd = props.myRebrokenComplaintItem.rstrYmd ?? ""
      draft.rstrCmptnYmd = props.myRebrokenComplaintItem.rstrCmptnYmd ?? ""
      draft.rstrDlyRsn = props.myRebrokenComplaintItem.rstrDlyRsn ?? ""
      draft.fileId = props.myRebrokenComplaintItem.fileId ?? null
    })
  }, [props.myRebrokenComplaintItem]);
  
  const onClickEdit = async () => {
    if (!window.confirm("수정하시겠습니까?")) return;
    try {
      if (isOnRestore) {
        await axios.post(`/complaint/restore/edit/${props.myRebrokenComplaintItem.id}`, req)
        alert("수정되었습니다.")
        props.mutate()
      } else {
        await axios.post(`/complaint/rebroken/edit/${props.myRebrokenComplaintItem.id}`, req)
        alert("수정되었습니다.")
        props.mutate()
      }
    }catch(e){
      alert(parseErrorMessage(e))
    }
  }

  return <>
  <table className='BrokenCycle full-width mt'>
    <thead>
    <tr>
      <td className='yellow'></td>
      <th className='yellow' colSpan={2}>
        {isOnRestore ? "복구완료 통지" : props.cycleIndex+"차 통지"}
        {isWaiting ? " (승인 대기 중)" : null}
      </th>
      <td className='yellow' style={{textAlign: 'right'}}>
        {props.myRebrokenComplaintItem.avtsmtMthd !== 0
          ? "통지방법: "+AvtsmtMthd[props.myRebrokenComplaintItem.avtsmtMthd ?? 0] : null}<br/>
        {props.myRebrokenComplaintItem.regYmd && "등록일: "+props.myRebrokenComplaintItem.regYmd}
      </td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th className='yellow'>고장연월일</th>
      <td>
        <input
          className='input full-width'
          type="date"
          value={props.myRebrokenComplaintItem.brokenYmd}
          disabled
        />
      </td>
      <th className='yellow'>
        {isOnRestore ? "복구 완료일" : "복구 예정일자"}
      </th>
      <td>
        {isOnRestore ?
          <input
            className='input full-width'
            type="date"
            name="rstrCmptnYmd"
            value={req.rstrCmptnYmd ?? ""}
            onChange={onChangeStr}
            disabled={!isWaiting}
          /> : <input
            className='input full-width'
            type="date"
            name="rstrYmd"
            value={req.rstrYmd}
            onChange={onChangeStr}
            disabled={!isWaiting}
          />
        }
      </td>
    </tr>
    <tr>
      <th className='yellow'>종류 및 수량</th>
      <td colSpan={3}><textarea value={props.myRebrokenComplaintItem.knd ?? ""} readOnly/></td>
    </tr>
    <tr>
      <th className='yellow'>고장내용</th>
      <td colSpan={3}><textarea value={props.myRebrokenComplaintItem.cn ?? ""} readOnly/></td>
    </tr>
    <tr>
      <th className='yellow'>복구 지연사유 /<br/>{isOnRestore && "복구완료 "}첨부파일</th>
      <td colSpan={2}>
        <textarea style={{height: '70px'}} 
                  value={req.rstrDlyRsn ?? ""} 
                  name="rstrDlyRsn"
                  onChange={onChangeTextAreaStr}
                  readOnly={!(isWaiting && !isOnRestore)}/>
      </td>
      <td>
        <FileUploadInput
          downloadName={isOnRestore ? "복구완료 첨부파일" : `${props.cycleIndex}차 첨부파일`}
          name="fileId"
          value={req.fileId}
          disabled={!isWaiting}
          onChange={onChangeFile}
        />
      </td>
    </tr>
    </tbody>
  </table>
    {isWaiting &&
      <div className="mt" style={{display:"flex", justifyContent:"flex-end"}}>
        <button className='button' onClick={onClickEdit}>수정</button>
      </div>
    }
  </>
}
