import React, {ChangeEvent, useEffect, useState} from 'react';
import "./ChangeComplaint.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import {useSWRProductTestReport} from "../../server/server";
import {
  Airport,
  AirportByAviationAgency, AirportCode, AwSignCode, AwSignReplaceCode,
  ComplaintEditMode,
} from "../../server/code";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {TabSelector} from "../../comp-shared/TabSelector";
import {Space} from "../../comp-shared/Space";
import {useImmer} from "use-immer";
import {useLocalStorage} from "usehooks-ts";
import {TempItem, TempLoaderModal} from "../../comp-shared/Complaint/TempLoaderModal";
import {
  documentAccept,
  FileUploadEvent,
  FileUploadInput,
} from "../../comp-shared/FileUploadInput";
import {
  getDefaultChangeDeclaration, getDefaultPostChangeComplaintReq,
  PostChangeComplaintReq
} from "../../server/complaint/changeComplaintDto";
import {MngCardLoader} from "../../comp-shared/Complaint/MngCardLoader";
import {GetMngCardRes} from "../../server/complaint/mngCardDto";
import {ComplaintBase} from "../../server/complaint/complaintBaseDto";
import {ObstacleAddress} from "../../comp-shared/Complaint/ObstacleAddress";
import {DeclarationBasicInfo} from "../../comp-shared/Complaint/DeclarationBasicInfo";
import {Sign} from "../../comp-shared/Complaint/Sign";
import sampleImage2 from "../../resources/sampleimages/2_sample.png";
import sampleImage3 from "../../resources/sampleimages/3_sample_1.png";
import sampleImage5 from "../../resources/sampleimages/5_sample.png";
import sampleImage6 from "../../resources/sampleimages/6_sample.png";
import {ImagePopup} from "../../comp-shared/Popup/ImagePopup";
import {PlanSamplePopup} from "../../comp-pages/Complaint/InstallComplaint/PlanSamplePopup";
import axios from "axios";
import {parseErrorMessage} from "../../util/errorParser";
import {checkLoginAndRedirect} from "../../util/loginManager";
import {GetChangeComplaintRes, GetInstallComplaintRes} from "../../server/user/myComplaintDto";
import ConfirmPopup, {useConfirmPopup} from "../../comp-shared/Popup/ConfirmPopup";
import moment from "moment/moment";

export const ChangeComplaint = () => {
  const {popupProps,openPopup} = useConfirmPopup();
  const navigate = useNavigate();
  const params = useParams();
  
  const [isTempLoaderOpen, setTempLoaderOpen] = useState<boolean>(false);
  const {data:productTestReportData} = useSWRProductTestReport();
  const [editMode,setEditMode] = useState<ComplaintEditMode>(
    params.hasOwnProperty("id") ? "edit":"create");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [complaint, updateComplaint] = useImmer<PostChangeComplaintReq>(
    getDefaultPostChangeComplaintReq());
  const [signDate, setSignDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const selectedDeclaration = complaint.declaration[selectedPage-1];

  const [tempComplaint, setTempComplaint] = useLocalStorage<TempItem[]>(
    "tempChangeComplaint",[]);
  const [loadedFromTemp, setLoadedFromTemp] = useState<number|null>(null);

  const [isOpenImagePopup, setIsOpenImagePopup] = useState<boolean>(false);
  const [imagePopupSrc, setImagePopupSrc] = useState<string>("");

  const [isOpenPlanSamplePopup, setIsOpenPlanSamplePopup] = useState<boolean>(false);
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const disabled = editMode === "readonly";


  useEffect(() => {
    checkLoginAndRedirect(navigate)
    init()
  },[]);

  const init = async () => {
    try {
      if (params.hasOwnProperty("id")) {
        let res = await axios.get<GetChangeComplaintRes>("/user/my-complaint/change/" + params.id);
        updateComplaint(res.data)
        setSignDate(res.data.chgDt)
        if (res.data.stts !== "S020100000" && !(res.data.stts === "S020200000" && res.data.mdfcnPrm))
          setEditMode("readonly")
      }
    } catch (e) {
      alert("민원을 불러오지 못했습니다. "+parseErrorMessage(e));
      navigate("/user/my-complaint")
    }
  }
  
  const onChangeStr = (e:ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.target.name] = e.target.value})
  const onChangeSelect = (e:ChangeEvent<HTMLSelectElement>) =>
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.target.name] = e.target.value})
  const onChangeFloat = (e:ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.target.name] = parseFloat(e.target.value)})
  const onChangeNumber = (e:ChangeEvent<HTMLInputElement>) => {
    if (e.target.maxLength > 0 && e.target.value.length > e.target.maxLength) return;
    updateComplaint(draft => {draft.declaration[selectedPage - 1][e.target.name] = parseInt(e.target.value)})
  }
  const onChangeChecked = (e:ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.target.name] = e.target.checked})

  const onChangeFile = async (e: FileUploadEvent) => {
    updateComplaint(draft => {draft.declaration[selectedPage-1][e.name] = e.fileId})
  };

  const onClickAdd = () => {
    updateComplaint(draft => {
      draft.declaration.push(getDefaultChangeDeclaration());
      setSelectedPage(draft.declaration.length);
    });
  };

  const onClickDelete = () => {
    if(selectedPage > 1){
      updateComplaint(draft => {
        draft.declaration = draft.declaration.filter((_,i) => i !== selectedPage-1);
      });
      setSelectedPage(selectedPage - 1);
    }else{
      alert("첫번째 신고서는 삭제할 수 없습니다.")
    }
  };

  const onClickSave = async () => {
    try {
      await openPopup("확인", "여러장의 신고서를 제출하시는 경우, " +
        "민원신고를 여러번 진행하지 마시고\n신고서 추가 기능을 통하여 한번에 제출하시기 바랍니다." +
        "\n\n위 내용을 확인하셨습니까?", "confirm")
    } catch (e) {
      return;
    }
    try {
      setIsProgress(true)
      await axios.post("/complaint/change", complaint)
      alert("민원 신청이 완료되었습니다.")
      // 신청 완료되면 해당 임시저장 삭제
      if (loadedFromTemp !== null)
        setTempComplaint(tempComplaint.filter(temp => temp.id !== loadedFromTemp));
      navigate("/")
    }catch (e){
      alert(parseErrorMessage(e));
    }finally {
      setIsProgress(false)
    }
  };
  const onClickSaveEdit = async () => {
    if (!window.confirm("민원을 수정하시겠습니까?")) return;
    try {
      setIsProgress(true)
      await axios.post(`/complaint/change/edit/${params.id}`, complaint)
      alert("민원 수정이 완료되었습니다.")
      navigate("/user/my-complaint")
    } catch (e) {
      alert(parseErrorMessage(e));
    } finally {
      setIsProgress(false);
    }
  };
  const onClickCancel = async () => {
    if (!window.confirm("민원을 취하하시겠습니까?")) return;
    try {
      setIsProgress(true)
      await axios.post(`/complaint/remove/${params.id}`)
      alert("민원이 취하되었습니다.")
      navigate("/user/my-complaint")
    } catch (e) {
      alert(parseErrorMessage(e));
    } finally {
      setIsProgress(false);
    }
  };

  const onChangeChgAwlHighProductId = async (e: ChangeEvent<HTMLSelectElement>) => {
    let value:number | null = null;
    if (e.target.value !== "") value = parseInt(e.target.value)
    updateComplaint(draft => {
      draft.declaration[selectedPage-1].chgAwlHighPrdctId = value;
      draft.declaration[selectedPage-1].chgAwlHighPrdctNm = value !== null?
        findProductTestReportNameById(value):"";
    })
  };

  const onChangeChgAwlMidAProductId = async (e: ChangeEvent<HTMLSelectElement>) => {
    let value:number|null = null;
    if (e.target.value !== "") value = parseInt(e.target.value)
    updateComplaint(draft => {
      draft.declaration[selectedPage-1].chgAwlMdAPrdctId = value;
      draft.declaration[selectedPage-1].chgAwlMdAPrdctNm = value !== null?
        findProductTestReportNameById(value):"";
    })
  };

  const onChangeChgAwlMidBcProductId = async (e: ChangeEvent<HTMLSelectElement>) => {
    let value:number|null = null;
    if (e.target.value !== "") value = parseInt(e.target.value)
    updateComplaint(draft => {
      draft.declaration[selectedPage-1].chgAwlMdBcPrdctId = value;
      draft.declaration[selectedPage-1].chgAwlMdBcPrdctNm = value !== null?
        findProductTestReportNameById(value):"";
    })
  };
  const onChangeChgAwlLowProductId = async (e: ChangeEvent<HTMLSelectElement>) => {
    let value:number|null = null;
    if (e.target.value !== "") value = parseInt(e.target.value)
    updateComplaint(draft => {
      draft.declaration[selectedPage-1].chgAwlLowPrdctId = value;
      draft.declaration[selectedPage-1].chgAwlLowPrdctNm = value !== null?
        findProductTestReportNameById(value):"";
    })
  };

  const findProductTestReportNameById = (id:number) => {
    let report = productTestReportData?.find(report => report.id === id);
    if (report === undefined) return "";
    return report.nm;
  }
  
  const onClickAwsignType = (value:AwSignCode) => {
    updateComplaint(draft => {
      let declaration = draft.declaration[selectedPage -1];
      declaration.chgAwsignType = value
      declaration.awsignReplace = value === "006" ? "고광도 A로 대체" : "";
      declaration.chgAwsignPaintingDevide = "";
      declaration.chgAwsignSignRedNum = null;
      declaration.chgAwsignSignWhiteNum = null;
      declaration.chgAwsignSignGap = null;
      declaration.chgAwsignFlagSingleColr = null;
      declaration.chgAwsignFlagCheckPattern = null;
      declaration.chgAwsignFlagWhiteOrangeColr = null;
    })
  }
  const onClickAwsignReplace = (value:AwSignReplaceCode) =>
    updateComplaint(draft => {draft.declaration[selectedPage - 1].chgAwsignReplace = value as AwSignReplaceCode})
  const onChangeSignerName = (name:string) => updateComplaint(draft => {
    draft.signerNm = name
  });
  const onChangeSignFile = (fileId:number|null) => updateComplaint(draft => {
    draft.signFileId = fileId
  });
  
  const onCloseTempLoader = () => setTempLoaderOpen(false);
  const onClickOpenTempLoader = () => setTempLoaderOpen(true);

  const onCloseImagePopup = () => setIsOpenImagePopup(false);
  const onClosePlanSamplePopup = () => setIsOpenPlanSamplePopup(false);

  const onLoadTemp = (tempItem:TempItem) => {
    setLoadedFromTemp(tempItem.id);
    updateComplaint(tempItem.complaint);
  }

  const onDeleteTemp = (id:number) => {
    if (!window.confirm("임시저장을 삭제하시겠습니까?")) return;
    setTempComplaint(tempComplaint.filter(temp => temp.id !== id))
  };
  const onClickSaveTemp = () => {
    if (!window.confirm("임시저장 하시겠습니까?")) return;
    let id = 1;
    if (tempComplaint.length > 0) id = Math.max(...tempComplaint.map(value => value.id))+1

    setTempComplaint([ {
      id: id,
      createdAt: new Date().toLocaleString(),
      complaint: complaint
    },...tempComplaint,]);
    alert("저장된 내역은 신고서 상단의 임시상단 불러오기를 클릭해 불러오실 수 있습니다.");
  }

  const onLoadMngCard = (mngCard:GetMngCardRes) => {
    updateComplaint(draft => {
      draft.declaration[selectedPage-1] = {...draft.declaration[selectedPage-1], ...mngCard,
        id:null, mngCardId:mngCard.id, mngCardNo:mngCard.mngNo,
        chgAwlHighANum:mngCard.awlHighANum,
        chgAwlHighBNum:mngCard.awlHighBNum,
        chgAwlHighConcrnt:mngCard.awlHighConcrnt,
        chgAwlHighInstlFileId:mngCard.awlHighInstlFileId,
        chgAwlHighPrdctId:mngCard.awlHighPrdctId,
        chgAwlHighPrdctNm:mngCard.awlHighPrdctNm,
        chgAwlHighPrdctCertFileId:mngCard.awlHighPrdctCertFileId,
        chgAwlMdANum:mngCard.awlMdANum,
        chgAwlMdAConcrnt:mngCard.awlMdAConcrnt,
        chgAwlMdAInstlFileId:mngCard.awlMdAInstlFileId,
        chgAwlMdAPrdctId:mngCard.awlMdAPrdctId,
        chgAwlMdAPrdctNm:mngCard.awlMdAPrdctNm,
        chgAwlMdAPrdctCertFileId:mngCard.awlMdAPrdctCertFileId,
        chgAwlMdBNum:mngCard.awlMdBNum,
        chgAwlMdCNum:mngCard.awlMdCNum,
        chgAwlMdBcConcrnt:mngCard.awlMdBcConcrnt,
        chgAwlMdBcInstlFileId:mngCard.awlMdBcInstlFileId,
        chgAwlMdBcPrdctId:mngCard.awlMdBcPrdctId,
        chgAwlMdBcPrdctNm:mngCard.awlMdBcPrdctNm,
        chgAwlMdBcPrdctCertFileId:mngCard.awlMdBcPrdctCertFileId,
        chgAwlLowANum:mngCard.awlLowANum,
        chgAwlLowBNum:mngCard.awlLowBNum,
        chgAwlLowCNum:mngCard.awlLowCNum,
        chgAwlLowDNum:mngCard.awlLowDNum,
        chgAwlLowENum:mngCard.awlLowENum,
        chgAwlLowConcrnt:mngCard.awlLowConcrnt,
        chgAwlLowInstlFileId:mngCard.awlLowInstlFileId,
        chgAwlLowPrdctId:mngCard.awlLowPrdctId,
        chgAwlLowPrdctNm:mngCard.awlLowPrdctNm,
        chgAwlLowPrdctCertFileId:mngCard.awlLowPrdctCertFileId,
        chgAwsignType:mngCard.awsignType,
        chgAwsignReplace:mngCard.awsignReplace,
        chgAwsignPaintingDevide:mngCard.awsignPaintingDevide,
        chgAwsignSignRedNum:mngCard.awsignSignRedNum,
        chgAwsignSignWhiteNum:mngCard.awsignSignWhiteNum,
        chgAwsignSignGap:mngCard.awsignSignGap,
        chgAwsignFlagSingleColr:mngCard.awsignFlagSingleColr,
        chgAwsignFlagCheckPattern:mngCard.awsignFlagCheckPattern,
        chgAwsignFlagWhiteOrangeColr:mngCard.awsignFlagWhiteOrangeColr,
      };
    })
  };

  const onClickOpenImagePopup = (imgSrc:string) => {
    setIsOpenImagePopup(true);
    setImagePopupSrc(imgSrc);
  };
  const onClickOpenPlanSamplePopup = () => setIsOpenPlanSamplePopup(true);
  const updateDeclaration = (callback:(complaint:ComplaintBase)=>void) => {
    updateComplaint(draft => {
      callback(draft.declaration[selectedPage - 1]);
    });
  }
  return (
    <>
      <div className="ChangeComplaint">
        <PageTitle title={editMode === "create" ? "변경신고" : "나의 민원 상세보기"}/>
        {editMode !== "readonly" ?
          <button className="button" style={{marginRight:"10px"}} onClick={onClickAdd}>신고서 추가</button>:null}
        {editMode === "create" ? <>
          <button className="button" style={{marginRight:"10px"}} onClick={onClickOpenTempLoader}>임시저장 불러오기</button>
        </>:null}
        <TabSelector selectedPage={selectedPage} setSelectedPage={setSelectedPage}
                     data={complaint.declaration}/>
        {editMode !== "readonly" ?
          <div style={{display:"flex",marginTop:"10px", marginBottom:"10px"}}>
            <MngCardLoader onLoad={onLoadMngCard}/>
            <button className="button" onClick={onClickDelete}>신고서 삭제</button>
          </div>:null}


        {selectedDeclaration.mngCardId != null ?<>
          <ObstacleAddress selectedDeclaration={selectedDeclaration}
                           updateDeclaration={updateDeclaration} 
                           editMode="readonly"/>
          
          <DeclarationBasicInfo declarationName="변경신고서"
                                selectedPage={selectedPage}
                                selectedDeclaration={selectedDeclaration}
                                updateDeclaration={updateDeclaration}
                                editMode={editMode}/>
          <div className="edit-notice">↑관리담당자, 연락처, 팩스번호 변경 시 수정하여주시기 바랍니다.↑ </div>
          
          <div className="declaration-grid middle-info-box">
            <div className="header green" style={{gridColumn:"auto / span 4"}}>변경내용</div>
            <div className="header green">변경연월일</div>
            <div className="field">
              <input className="input" type="date" name="chgYmd"
                     value={selectedDeclaration.chgYmd ?? ""}
                     onChange={onChangeStr} disabled={disabled}/>
            </div>
            <div className="field" style={{gridColumn:"auto /span 6"}}>
              변경한 날로부터 15일 이내에 신고하지 않은 경우 과태료가 부과됩니다. 변경연월일을 다시 확인하시기 바랍니다.
            </div>
            <div className="header green" style={{gridRow:"auto / span 8"}}>항공장애<br/>표시등</div>

            <div className="header green">고광도</div>
            <div className="field" style={{gridColumn:"auto / span 4"}}>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>A</span>
                <input className="input" type="number" value={selectedDeclaration.chgAwlHighANum ?? ""}
                       name="chgAwlHighANum" onChange={onChangeNumber} disabled={disabled}/>
              </div>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>B</span>
                <input className="input" type="number" value={selectedDeclaration.chgAwlHighBNum ?? ""}
                       name="chgAwlHighBNum" onChange={onChangeNumber} disabled={disabled}/>
              </div>
              <div>
                <input type="checkbox" checked={selectedDeclaration.chgAwlHighConcrnt}
                       name="chgAwlHighConcrnt" onChange={onChangeChecked} disabled={disabled}/>동시섬광
              </div>
              <Space/>
            </div>

            <div className="header green">고광도 <br/>설치사진</div>
            <div className="field vertical">
              (설치 후 제품명이 보이도록 찍은 사진)
              <FileUploadInput name="chgAwlHighInstlFileId"
                               value={selectedDeclaration.chgAwlHighInstlFileId}
                               downloadName="설치사진"
                               onChange={onChangeFile} disabled={disabled}
                               accept={documentAccept}/>
            </div>
            <div className="header green">제품명</div>

            <div className="field vertical">
              <select className="select" style={{margin:"5px"}} value={selectedDeclaration.chgAwlHighPrdctId ?? ""}
                      onChange={onChangeChgAwlHighProductId} disabled={disabled}>
                <option value="">직접등록(시험성적서 파일 필요)</option>
                {productTestReportData?.filter(report => report.knd === 3)
                  .map((report) => {
                    return <option value={report.id} key={report.id}>{report.nm}</option>
                  })}
              </select>
              { selectedDeclaration.chgAwlHighPrdctId === null ?
                <input className="input" value={selectedDeclaration.chgAwlHighPrdctNm}
                       name="chgAwlHighPrdctNm" onChange={onChangeStr} disabled={disabled}/>:null
              }
            </div>

            <div className="field vertical">
              {selectedDeclaration.chgAwlHighPrdctId === null ? <>
                <div> 시험성적서 </div>
                <FileUploadInput name="chgAwlHighPrdctCertFileId"
                                 value={selectedDeclaration.chgAwlHighPrdctCertFileId}
                                 downloadName="시험성적서"
                                 onChange={onChangeFile} disabled={disabled}
                                 accept={documentAccept}/>
              </>:null
              }
            </div>

            <div className="header green">중광도 A</div>
            <div className="field" style={{gridColumn:"auto / span 4"}}>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>A</span>
                <input className="input" placeholder=""  type="number"
                       value={selectedDeclaration.chgAwlMdANum ?? ""}
                       name="chgAwlMdANum" onChange={onChangeNumber} disabled={disabled}/>
              </div>
              <div>
                <input type="checkbox" checked={selectedDeclaration.chgAwlMdAConcrnt}
                       name="chgAwlMdAConcrnt"
                       onChange={onChangeChecked} disabled={disabled}/>동시섬광
              </div>
              <Space/>
            </div>

            <div className="header green">중광도 A <br/>설치사진</div>
            <div className="field vertical">
              (설치 후 제품명이 보이도록 찍은 사진)
              <FileUploadInput name="chgAwlMdAInstlFileId"
                               downloadName="설치사진"
                               value={selectedDeclaration.chgAwlMdAInstlFileId}
                               onChange={onChangeFile} disabled={disabled}
                               accept={documentAccept}/>
            </div>

            <div className="header green">제품명</div>
            <div className="field vertical">
              <select className="select" style={{margin:"5px"}}
                      value={selectedDeclaration.chgAwlMdAPrdctId ?? ""}
                      onChange={onChangeChgAwlMidAProductId} disabled={disabled}>
                <option value="">직접등록(시험성적서 파일 필요)</option>
                {productTestReportData?.filter(report => report.knd === 2)
                  .map((report) => {
                    return <option value={report.id} key={report.id}>{report.nm}</option>
                  })}
              </select>
              { selectedDeclaration.chgAwlMdAPrdctId === null ?
                <input className="input" value={selectedDeclaration.chgAwlMdAPrdctNm}
                       name="chgAwlMdAPrdctNm" onChange={onChangeStr} disabled={disabled}/>:null
              }
            </div>

            <div className="field vertical">
              {selectedDeclaration.chgAwlMdAPrdctId === null ? <>
                <div> 시험성적서 </div>
                <FileUploadInput name="chgAwlMdAPrdctCertFileId"
                                 value={selectedDeclaration.chgAwlMdAPrdctCertFileId}
                                 downloadName="시험성적서"
                                 onChange={onChangeFile} disabled={disabled}
                                 accept={documentAccept}/>
              </>:null}
            </div>

            <div className="header green">중광도 B, C</div>
            <div className="field" style={{gridColumn:"auto / span 4"}}>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>B</span>
                <input className="input" placeholder="" type="number"
                       value={selectedDeclaration.chgAwlMdBNum ?? ""}
                       name="chgAwlMdBNum" onChange={onChangeNumber} disabled={disabled}/>
              </div>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>C</span>
                <input className="input" placeholder="" type="number"
                       value={selectedDeclaration.chgAwlMdCNum ?? ""}
                       name="chgAwlMdCNum" onChange={onChangeNumber} disabled={disabled}/>
              </div>
              <div>
                <input type="checkbox" checked={selectedDeclaration.chgAwlMdBcConcrnt}
                       name="chgAwlMdBcConcrnt" onChange={onChangeChecked} disabled={disabled}/>동시섬광
              </div>
              <Space/>
            </div>

            <div className="header green">중광도 B, C <br/>설치사진</div>
            <div className="field vertical">
              (설치 후 제품명이 보이도록 찍은 사진)
              <FileUploadInput name="chgAwlMdBcInstlFileId"
                               value={selectedDeclaration.chgAwlMdBcInstlFileId}
                               downloadName="설치사진"
                               onChange={onChangeFile} disabled={disabled}
                               accept={documentAccept}/>
            </div>
            <div className="header green">제품명</div>
            <div className="field vertical">
              <select className="select" style={{margin:"5px"}}
                      value={selectedDeclaration.chgAwlMdBcPrdctId ?? ""}
                      onChange={onChangeChgAwlMidBcProductId}>
                <option value="">직접등록(시험성적서 파일 필요)</option>
                {productTestReportData?.filter(report => report.knd === 2)
                  .map((report) => {
                    return <option value={report.id} key={report.id}>{report.nm}</option>
                  })}
              </select>
              { selectedDeclaration.chgAwlMdBcPrdctId === null ?
                <input className="input" value={selectedDeclaration.chgAwlMdBcPrdctNm}
                       name="chgAwlMdBcPrdctNm" onChange={onChangeStr} disabled={disabled}/>:null
              }
            </div>

            <div className="field vertical">
              {selectedDeclaration.chgAwlMdBcPrdctId === null ? <>
                <div> 시험성적서 </div>
                <FileUploadInput name="chgAwlMdBcPrdctCertFileId"
                                 value={selectedDeclaration.chgAwlMdBcPrdctCertFileId}
                                 downloadName="시험성적서"
                                 onChange={onChangeFile} disabled={disabled}
                                 accept={documentAccept}/>
              </>:null}
            </div>

            <div className="header green">저광도</div>
            <div className="field" style={{gridColumn:"auto / span 4"}}>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>A</span>
                <input className="input" value={selectedDeclaration.chgAwlLowANum ?? ""}
                       type="number" name="chgAwlLowANum" onChange={onChangeNumber} disabled={disabled}
                />
              </div>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>B</span>
                <input className="input" value={selectedDeclaration.chgAwlLowBNum ?? ""}
                       type="number" name="chgAwlLowBNum" onChange={onChangeNumber} disabled={disabled}/>
              </div>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>C</span>
                <input className="input" value={selectedDeclaration.chgAwlLowCNum ?? ""}
                       type="number" name="chgAwlLowCNum" onChange={onChangeNumber} disabled={disabled}/>
              </div>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>D</span>
                <input className="input" value={selectedDeclaration.chgAwlLowDNum ?? ""}
                       type="number" name="chgAwlLowDNum" onChange={onChangeNumber} disabled={disabled}/>
              </div>
              <div className="headed-input-container" style={{width:"100px"}}>
                <span>E</span>
                <input className="input" value={selectedDeclaration.chgAwlLowENum ?? ""}
                       type="number" name="chgAwlLowENum" onChange={onChangeNumber} disabled={disabled}/>
              </div>
              <div>
                <input type="checkbox" checked={selectedDeclaration.chgAwlLowConcrnt}
                       name="chgAwlLowConcrnt" onChange={onChangeChecked} disabled={disabled}/>동시섬광
              </div>
              <Space/>
            </div>

            <div className="header green">저광도 <br/>설치사진</div>
            <div className="field vertical">
              (설치 후 제품명이 보이도록 찍은 사진)
              <FileUploadInput name="chgAwlLowInstlFileId"
                               value={selectedDeclaration.chgAwlLowInstlFileId}
                               downloadName="설치사진"
                               onChange={onChangeFile} disabled={disabled}
                               accept={documentAccept}/>
            </div>
            <div className="header green">제품명</div>
            <div className="field vertical">
              <select className="select" style={{margin:"5px"}}
                      value={selectedDeclaration.chgAwlLowPrdctId ?? ""}
                      onChange={onChangeChgAwlLowProductId} disabled={disabled}>
                <option value="">직접등록(시험성적서 파일 필요)</option>
                {productTestReportData?.filter(report => report.knd === 1)
                  .map((report) => {
                    return <option value={report.id} key={report.id}>{report.nm}</option>
                  })}
              </select>
              { selectedDeclaration.chgAwlLowPrdctId === null ?
                <input className="input" value={selectedDeclaration.chgAwlLowPrdctNm}
                       name="chgAwlLowPrdctNm" onChange={onChangeStr} disabled={disabled}/>:null
              }
            </div>

            <div className="field vertical">
              {selectedDeclaration.chgAwlLowPrdctId === null ? <>
                <div> 시험성적서 </div>
                <FileUploadInput name="chgAwlLowPrdctCertFileId"
                                 value={selectedDeclaration.chgAwlLowPrdctCertFileId}
                                 downloadName="시험성적서"
                                 onChange={onChangeFile} disabled={disabled}
                                 accept={documentAccept}/>
              </>:null}
            </div>


            <div className="header green" style={{gridRow:"auto / span 11"}}>항공장애<br/>주간표지</div>
            <div className="header green" style={{gridRow:"auto / span 11"}}>주간표지 <br/>종류 택 1</div>

            <div className="field" style={{gridRow:"auto / span 3", gridColumn:"auto / span 2"}}>
              <input type="radio" checked={selectedDeclaration.chgAwsignType === "006"}
                     onChange={()=>onClickAwsignType("006")} disabled={disabled}/>
              주간표지 없음(표시등으로 대체)
              <Space/>
            </div>
            <div className="header green" style={{gridRow:"auto / span 3"}}>대체</div>
            <div className="field">
              <input type="radio"
                     disabled={selectedDeclaration.chgAwsignType !== "006" || disabled}
                     checked={selectedDeclaration.chgAwsignReplace == "고광도 A로 대체"}
                     onChange={()=>onClickAwsignReplace("고광도 A로 대체")}/>
              고광도 A로 대체</div>
            <div className="field">
              <input type="radio"
                     disabled={selectedDeclaration.chgAwsignType !== "006" || disabled}
                     checked={selectedDeclaration.chgAwsignReplace == "고광도 B로 대체"}
                     onChange={()=>onClickAwsignReplace("고광도 B로 대체")}/>
              고광도 B로 대체</div>
            <div className="field">
              <input type="radio"
                     disabled={selectedDeclaration.chgAwsignType !== "006" || disabled}
                     checked={selectedDeclaration.chgAwsignReplace == "중광도 A로 대체"}
                     onChange={()=>onClickAwsignReplace("중광도 A로 대체")}/>
              중광도 A로 대체
            </div>

            <div className="field" style={{gridColumn:"auto / span 2"}}>
              <input type="radio" checked={selectedDeclaration.chgAwsignType === "001"}
                     onChange={()=>onClickAwsignType("001")} disabled={disabled}/>
              도색
              <Space/>
            </div>
            <div className="header green">도색등분</div>
            <div className="field">
              <input className="input" placeholder="예) 7등분, 5/9"
                     disabled={selectedDeclaration.chgAwsignType !== "001" || disabled}
                     value={selectedDeclaration.chgAwsignPaintingDevide}
                     name="chgAwsignPaintingDevide"
                     onChange={onChangeStr} />
            </div>

            <div className="field" style={{gridRow:"auto / span 3", gridColumn:"auto / span 2"}}>
              <input type="radio" checked={selectedDeclaration.chgAwsignType === "004"}
                     onChange={()=>onClickAwsignType("004")} disabled={disabled}/>
              표지구
              <Space/>
            </div>
            <div className="header green">적색 개수</div>
            <div className="field">
              <input className="input" name="chgAwsignSignRedNum" type="number"
                     disabled={selectedDeclaration.chgAwsignType !== "004" || disabled}
                     value={selectedDeclaration.chgAwsignSignRedNum ?? ""}
                     onChange={onChangeNumber}/>
            </div>
            <div className="header green">백색 개수</div>
            <div className="field">
              <input className="input" name="chgAwsignSignWhiteNum" type="number"
                     disabled={selectedDeclaration.chgAwsignType !== "004" || disabled}
                     value={selectedDeclaration.chgAwsignSignWhiteNum ?? ""}
                     onChange={onChangeNumber}/>

            </div>
            <div className="header green">경간</div>
            <div className="field">
              <input className="input" placeholder="미터(m)" name="chgAwsignSignGap" type="number"
                     disabled={selectedDeclaration.chgAwsignType !== "004" || disabled}
                     value={selectedDeclaration.chgAwsignSignGap ?? ""}
                     onChange={onChangeFloat}/>
            </div>

            <div className="field" style={{gridColumn:"auto / span 4"}}>
              <input type="radio" checked={selectedDeclaration.chgAwsignType === "002"}
                     onChange={()=>onClickAwsignType("002")} disabled={disabled}/>
              백색
              <Space/>
            </div>

            <div className="field" style={{gridRow:"auto / span 3", gridColumn:"auto / span 2"}}>
              <input type="radio" checked={selectedDeclaration.chgAwsignType === "003"}
                     onChange={()=>onClickAwsignType("003")} disabled={disabled}/>
              깃발
              <Space/>
            </div>
            <div className="header green">단일색</div>
            <div className="field">
              <input className="input" name="chgAwsignFlagSingleColr"
                     type="number"
                     disabled={selectedDeclaration.chgAwsignType !== "003" || disabled}
                     value={selectedDeclaration.chgAwsignFlagSingleColr ?? ""}
                     onChange={onChangeNumber}/>
            </div>
            <div className="header green">체크무늬</div>
            <div className="field">
              <input className="input" name="chgAwsignFlagCheckPattern"
                     type="number"
                     disabled={selectedDeclaration.chgAwsignType !== "003" || disabled}
                     value={selectedDeclaration.chgAwsignFlagCheckPattern ?? ""}
                     onChange={onChangeNumber}/>
            </div>
            <div className="header green">백/주황색</div>
            <div className="field">
              <input className="input" name="chgAwsignFlagWhiteOrangeColr"
                     type="number"
                     disabled={selectedDeclaration.chgAwsignType !== "003" || disabled}
                     value={selectedDeclaration.chgAwsignFlagWhiteOrangeColr ?? ""}
                     onChange={onChangeNumber}/>
            </div>
            <div className="header green" style={{gridRow:"auto / span 6"}}>제출서류</div>
            <div className="field left" style={{gridColumn:"auto / span 3"}}>
              1. 항공장애 표시등 변경 설치도면&nbsp;
              <a className="href" onClick={onClickOpenPlanSamplePopup}>[예시]</a>
            </div>
            <div className="field left" style={{gridColumn:"auto / span 2"}}>
              <FileUploadInput name="chgFlrplnFileId" downloadName="도면"
                               value={selectedDeclaration.chgFlrplnFileId}
                               onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
            </div>
            <div className="field left" style={{gridColumn:"auto / span 3"}}>
              <div>
              2. 주간표지 변경 설치도면 (주간표지 미설치 시 첨부 생략 가능) &nbsp;
                <br/>
              <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage2)}>[예시]</a>
              </div>
            </div>
            <div className="field left" style={{gridColumn:"auto / span 2"}}>
              <FileUploadInput name="chgAwsignFlrplnFileId" downloadName="도면"
                               value={selectedDeclaration.chgAwsignFlrplnFileId}
                               onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
            </div>
            <div className="field left" style={{gridColumn:"auto / span 3"}}>
              3. 변경 설치사진 (전체적인 위치를 나타내는 사진)&nbsp;
              <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage3)}>[예시]</a>
            </div>
            <div className="field left" style={{gridColumn:"auto / span 2"}}>
              <FileUploadInput name="chgInstlFileId" downloadName="설치사진"
                               value={selectedDeclaration.chgInstlFileId}
                               onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
            </div>
            <div className="field left" style={{gridColumn:"auto / span 5"}}>
              4. 최근 2년 이내에 시험한 성적서 (항공장애 표시등의 제품명을 선택하거나 시험성적서 파일첨부)
            </div>
            <div className="field left" style={{gridColumn:"auto / span 3"}}>
              5. 제어반 사진 및 입력 전압, 전류값&nbsp;
              <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage5)}>[예시]</a>
            </div>
            <div className="field left" style={{gridColumn:"auto / span 2"}}>
              <FileUploadInput name="chgCtlboxFileId" downloadName="사진"
                               value={selectedDeclaration.chgCtlboxFileId}
                               onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
            </div>
            <div className="field left" style={{gridColumn:"auto / span 3"}}>
              6. 표시등 및 제어반 부품 내역&nbsp;
              <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage6)}>[예시]</a>
            </div>
            <div className="field left" style={{gridColumn:"auto / span 2"}}>
              <FileUploadInput name="chgCtlboxCompListFileId" downloadName="부품내역"
                               value={selectedDeclaration.chgCtlboxCompListFileId}
                               onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
            </div>
          </div>
        </>:null}
        {complaint.declaration.some(value => value.mngCardId != null) ? <>
          <Sign name={complaint.signerNm} fileId={complaint.signFileId}
                onChangeSignFile={onChangeSignFile} onChangeName={onChangeSignerName} 
                disabled={disabled} 
                text="「공항시설법」 제36조제6항 및 같은 법 시행규칙 제29조제3항에 따라 위와 같이 신고합니다."
                signDate={signDate} />
          <div className="flex mt">
            <Space/>
            {editMode == "edit" ? <>
              <button className="button mr" onClick={onClickSaveEdit} disabled={isProgress}>민원수정</button>
              <button className="button" onClick={onClickCancel} disabled={isProgress}>민원취하</button>
            </>:null}
            {editMode == "create" ? <>
              <button className="button mr" onClick={onClickSaveTemp}>임시저장</button>
              <button className="button" onClick={onClickSave} disabled={isProgress}>민원신청</button>
            </>:null}
          </div>
        </>:null}
      </div>
      <TempLoaderModal isOpen={isTempLoaderOpen} onClose={onCloseTempLoader} tempItems={tempComplaint}
                       type="변경" onLoad={onLoadTemp} onDelete={onDeleteTemp}/>
      <ImagePopup isOpen={isOpenImagePopup} onClose={onCloseImagePopup}
                  imgSrc={imagePopupSrc}/>
      <PlanSamplePopup isOpen={isOpenPlanSamplePopup} onClose={onClosePlanSamplePopup}/>
      <ConfirmPopup confirmPopupProps={popupProps}/>
    </>
  )
}

