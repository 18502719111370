import React from 'react';
import "./PageTitle.scss"
interface Props{
  title:string;
}
export const PageTitle = (props:Props) => {
  return (
    <div className="PageTitle">
      {props.title}
    </div>
  )
}

