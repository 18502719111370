import {ComplaintBase} from "./complaintBaseDto";

export interface PostInstallComplaintReq{
  signerNm: string;
  signFileId: number | null;
  declaration: InstallDeclaration[];
}

export interface InstallDeclaration extends ComplaintBase{
  instlFlrplnFileId: number | null;
  awsignFlrplnFileId: number | null;
  instlFileId: number | null;
  ctlboxFileId: number | null;
  ctlboxCompListFileId: number | null;
  dmltnPrnmntYmd:string|null;
}

export const getDefaultPostInstallComplaintReq = ():PostInstallComplaintReq => ({
  signerNm: "",
  signFileId: null,
  declaration: [getDefaultInstallDeclaration()]
});

export const getDefaultInstallDeclaration = ():InstallDeclaration => ({
    id:null,
    no:"",
    mngCardNo:"",
    kepco: false,
    obstZip: "",
    obstAddr: "",
    obstNm: "",
    aviationAgencyCd: null,
    sareaCd: null,
    rstrcZoneCd: "001",
    dclNm: "",
    dclBrno: "",
    dclZip: "",
    dclAddr: "",
    instlYmd: "",
    crdNorth: "",
    crdEast: "",
    obstType: "001",
    lctnHgt: null,
    hgtFromGrnd: null,
    hgtFromSea: null,
    horizontalWdth: null,
    vrtcWdth: null,
    airport: "1",
    distFromAirport: null,
    bearingFromAirport: "",
    cluster: false,
    awlHighANum: null,
    awlHighBNum: null,
    awlHighConcrnt: false,
    awlHighInstlFileId: null,
    awlHighPrdctId: null,
    awlHighPrdctNm: "",
    awlHighPrdctCertFileId: null,
    awlMdANum: null,
    awlMdAConcrnt: false,
    awlMdAInstlFileId: null,
    awlMdAPrdctId: null,
    awlMdAPrdctNm: "",
    awlMdAPrdctCertFileId: null,
    awlMdBNum: null,
    awlMdCNum: null,
    awlMdBcConcrnt: false,
    awlMdBcInstlFileId: null,
    awlMdBcPrdctId: null,
    awlMdBcPrdctNm: "",
    awlMdBcPrdctCertFileId: null,
    awlLowANum: null,
    awlLowBNum: null,
    awlLowCNum: null,
    awlLowDNum: null,
    awlLowENum: null,
    awlLowConcrnt: false,
    awlLowInstlFileId: null,
    awlLowPrdctId: null,
    awlLowPrdctNm: "",
    awlLowPrdctCertFileId: null,
    awsignType: "006",
    awsignReplace: "고광도 A로 대체",
    awsignPaintingDevide: "",
    awsignSignRedNum: null,
    awsignSignWhiteNum: null,
    awsignSignGap: null,
    awsignFlagSingleColr: null,
    awsignFlagCheckPattern: null,
    awsignFlagWhiteOrangeColr: null,
    mngrNm: "",
    mngrTel: "",
    mngrJbttl: "",
    mngrFax: "",
    instlFlrplnFileId: null,
    awsignFlrplnFileId: null,
    instlFileId: null,
    ctlboxFileId: null,
    ctlboxCompListFileId: null,
    dmltnPrnmntYmd:null
});

export const getTestInstallDeclaration = ():InstallDeclaration => ({
  id:null,
  no:"",
  mngCardNo:"",
  kepco: true,
  obstZip: "20303",
  obstAddr: "대전 동구 반석로",
  obstNm: "테스트 장애물",
  aviationAgencyCd: "001",
  sareaCd: "008",
  rstrcZoneCd: "001",
  dclNm: "김수아",
  dclBrno: "2022284484",
  dclZip: "20303",
  dclAddr: "대전 동구 반석로",
  instlYmd: "2023-11-15",
  crdNorth: "20-34-30",
  crdEast: "30-23-32",
  obstType: "001",
  lctnHgt: 10,
  hgtFromGrnd: 20,
  hgtFromSea: 30,
  horizontalWdth: 40,
  vrtcWdth: 50,
  airport: "1",
  distFromAirport: 10,
  bearingFromAirport: "비행장으로부터 동쪽",
  cluster: true,
  awlHighANum: 1,
  awlHighBNum: 2,
  awlHighConcrnt: true,
  awlHighInstlFileId: null,
  awlHighPrdctId: null,
  awlHighPrdctNm: "",
  awlHighPrdctCertFileId: null,
  awlMdANum: null,
  awlMdAConcrnt: false,
  awlMdAInstlFileId: null,
  awlMdAPrdctId: null,
  awlMdAPrdctNm: "",
  awlMdAPrdctCertFileId: null,
  awlMdBNum: null,
  awlMdCNum: null,
  awlMdBcConcrnt: false,
  awlMdBcInstlFileId: null,
  awlMdBcPrdctId: null,
  awlMdBcPrdctNm: "",
  awlMdBcPrdctCertFileId: null,
  awlLowANum: null,
  awlLowBNum: null,
  awlLowCNum: null,
  awlLowDNum: null,
  awlLowENum: null,
  awlLowConcrnt: false,
  awlLowInstlFileId: null,
  awlLowPrdctId: null,
  awlLowPrdctNm: "",
  awlLowPrdctCertFileId: null,
  awsignType: "006",
  awsignReplace: "고광도 A로 대체",
  awsignPaintingDevide: "",
  awsignSignRedNum: null,
  awsignSignWhiteNum: null,
  awsignSignGap: null,
  awsignFlagSingleColr: null,
  awsignFlagCheckPattern: null,
  awsignFlagWhiteOrangeColr: null,
  mngrNm: "김수아",
  mngrTel: "01011112222",
  mngrJbttl: "대리",
  mngrFax: "",
  instlFlrplnFileId: null,
  awsignFlrplnFileId: null,
  instlFileId: null,
  ctlboxFileId: null,
  ctlboxCompListFileId: null,
  dmltnPrnmntYmd:null
});