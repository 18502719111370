import React from 'react';
import {Pager} from "../server/pager";
import "./ListPager.scss"
import ArrowLeftIcon from "../resources/icons/keyboard_arrow_left_black_24dp.svg";
import ArrowRightIcon from "../resources/icons/keyboard_arrow_right_black_24dp.svg";
import DoubleArrowLeftIcon from "../resources/icons/keyboard_double_arrow_left_black_24dp.svg";
import DoubleArrowRightIcon from "../resources/icons/keyboard_double_arrow_right_black_24dp.svg";

interface Props{
    page:number;
    pageItem:Pager<any>|undefined;
    onChangePage:(page:number)=>void
}

const ListPager = (props:Props) => {
    const goToFirstPage = () => props.onChangePage(0)
    const goToPrevPage = () => {
        if(props.pageItem?.first === false ?? false) props.onChangePage(props.page - 1)
    }
    const goToNextPage = () => {
        if(props.pageItem?.last === false ?? false) props.onChangePage(props.page + 1)
    }
    const goToLastPage = () => props.onChangePage((props.pageItem?.totalPages ?? 1) -1)

    if (props.pageItem == undefined) return null 
    
    return(<div className="ListPager">
        <button className="button-circle" onClick={goToFirstPage}><img src={DoubleArrowLeftIcon}/></button>
        <button className="button-circle" onClick={goToPrevPage}><img src={ArrowLeftIcon}/></button>
        <span>
                {(props.pageItem.pageable?.pageNumber ?? 0) + 1} / {props.pageItem.totalPages ?? 0} Page
        </span>
        <button className="button-circle" onClick={goToNextPage}><img src={ArrowRightIcon}/> </button>
        <button className="button-circle" onClick={goToLastPage}><img src={DoubleArrowRightIcon}/> </button>
        </div>
    )
};

export default ListPager;
