import PopupPanel from "./PopupPanel";
import React, {useRef, useState} from "react";
import "./ConfirmPopup.scss"

interface useConfirmPopupResults {
  popupProps: Props;
  openPopup: (title: string, content: string, type?:"confirm"|"message") => Promise<void>;
}

export function useConfirmPopup(): useConfirmPopupResults {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [isMessageOnly, setMessageOnly] = useState<boolean>(false)
  const confirm = useRef<() => void>(()=>{});
  const rejectRef = useRef<() => void>(()=>{});

  const onReject = () => setOpen(false)

  const openPopup = (title: string, content: string, type:"confirm"|"message" = "message"):Promise<void> => {
    setOpen(true)
    setTitle(title)
    setContent(content)
    setMessageOnly(type === "message")
    return new Promise((resolve, reject) => {
      confirm.current = () => {
        setOpen(false)
        resolve()
      }
      rejectRef.current = () => {
        setOpen(false)
        if(isMessageOnly) resolve()
        else reject()
      }
    })
  }

  return {
    popupProps: {
      isOpen: isOpen,
      title: title,
      content: content,
      onConfirm: confirm.current,
      isMessageOnly: isMessageOnly,
      onReject: rejectRef.current,
    },
    openPopup: openPopup
  }
}

interface Props {
  isOpen: boolean;
  title: string;
  content: string;
  isMessageOnly:boolean;
  onConfirm: () => void;
  onReject: () => void;
}

const ConfirmPopup = (props: { confirmPopupProps: Props }) => {
  return <PopupPanel isOpen={props.confirmPopupProps.isOpen}
                     title={props.confirmPopupProps.title}
                     onClose={props.confirmPopupProps.onReject}><>
    <div className="ConfirmPopup">
      <div style={{whiteSpace:"pre"}}>
        {props.confirmPopupProps.content}
      </div>
      <div className="space"/>
      <div style={{marginTop: "20px", display: "flex", justifyContent: "center"}}>
        <button className="button" style={{marginRight: "10px"}}
                onClick={props.confirmPopupProps.onConfirm}>확인
        </button>
        {!props.confirmPopupProps.isMessageOnly ?
        <button className="button" onClick={props.confirmPopupProps.onReject}>취소
        </button>:null
        }
      </div>
    </div>
  </>
  </PopupPanel>
}

export default ConfirmPopup;
