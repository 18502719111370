import moment from "moment";

export const timeToKorean = (time: string) =>
  moment(time).format("YYYY년 MM월 DD일 hh시 mm분 ss초")
export const timeToKorean24Hour = (time: string) =>
  moment(time).format("YYYY년 MM월 DD일 HH시 mm분 ss초")
export const timeToKoreanDate = (time: string) =>
  moment(time).format("YYYY년 MM월 DD일")

export const timeToMinuteAndSecond = (time: string) =>
  moment(time).format("mm:ss.SSS")
