import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

export interface JWT {
  sub: string,
  username: string,
  user?:true,
  "admin-viewer"?:true
}

export const parseJWT = (): JWT => {
  try {
    let token = new Cookies().get("accessToken");
    return jwtDecode<JWT>(token);
  }catch (e){
    return {sub: "", username: ""}
  }
}

export function checkLoginAndRedirect(navigate:any) {
  const accessToken = parseJWT();
  if (!accessToken.user) navigate("/user/login", { replace: true });
}
