import React from "react";
import "./Space.scss"

interface Props{
  disableMobile?:boolean;
  disablePC?:boolean;
}
export const Space = (props:Props) => {
  let disableMobile = props.disableMobile === undefined ? false : props.disableMobile;
  let disablePC = props.disablePC === undefined ? false : props.disablePC;
  
  return (
    <div className={`Space ${disableMobile ? "pc-only":""} ${disablePC ? "mobile-only":""}`}>
    </div>
  )
}

