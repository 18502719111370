import {AviationAgencyCode, AvtsmtMthdCode, DeclarationKindCode, DeclarationSttsCode} from "../code";
import {InstallDeclaration} from "../complaint/installComplaintDto";
import {ChangeDeclaration} from "../complaint/changeComplaintDto";
import {DemolishDeclaration} from "../complaint/demolishComplaintDto";
import {BrokenDeclaration} from "../complaint/brokenComplaintDto";

export interface GetMyComplaintRes {
  id:number;
  cvlcptNo:string;
  dclrCd:DeclarationKindCode;
  stts:DeclarationSttsCode;
  aviationAgencyCd:AviationAgencyCode;
  regDt:string;
  chgDt:string;
}

export interface GetInstallComplaintRes{
  signerNm: string;
  signFileId: number | null;
  stts:DeclarationSttsCode;
  mdfcnPrm:boolean;
  declaration: InstallDeclaration[];
  chgDt:string;
}

export interface GetChangeComplaintRes{
  signerNm: string;
  signFileId: number | null;
  stts:DeclarationSttsCode;
  mdfcnPrm:boolean;
  declaration: ChangeDeclaration[];
  chgDt:string;
}

export interface GetDemolishComplaintRes{
  signerNm: string;
  signFileId: number | null;
  stts:DeclarationSttsCode;
  mdfcnPrm:boolean;
  declaration: DemolishDeclaration[];
  chgDt:string;
}

export interface GetBrokenComplaintRes{
  signerNm: string;
  signFileId: number | null;
  stts:DeclarationSttsCode;
  mdfcnPrm:boolean;
  declaration: BrokenDeclaration[];
  chgDt:string;
}

export interface CommonBrokenInfoRes {
  mngCardId: number;
  mngNo: string;
  myRebrokenComplaintList: MyRebrokenComplaint[];
}

export const getDefaultCommonBrokenInfoRes = (): CommonBrokenInfoRes => ({
  mngCardId: 0,
  mngNo: '',
  myRebrokenComplaintList: [getDefaultMyRebrokenComplaint()]
});

//관리카드-고장통지 연계
export interface MyRebrokenComplaint {
  //관리카드-고장통지 id
  id: number;
  //관리카드 id
  mngCardId: number;
  //고장민원 id
  dclrId: number;
  //상태 (-1: 재통지, 0: 재통지 승인, 1: 복구완료 승인, 2: 복구완료)
  stts: number;
  //통지방법 (0: 시스템, 1: 유선, 2: 공문)
  avtsmtMthd: AvtsmtMthdCode | null;
  //재통지 회차
  cycl: number;
  //신고일
  regYmd: string | null;
  //고장연월일
  brokenYmd: string;
  //복구예정일
  rstrYmd: string;
  //복구완료일
  rstrCmptnYmd: string | null;
  //고장 종류 및 수량
  knd: string;
  //고장 내용
  cn: string;
  //복구지연사유
  rstrDlyRsn: string | null;
  //첨부파일 id
  fileId: number | null;
}

export const getDefaultMyRebrokenComplaint = (): MyRebrokenComplaint => ({
  id: 0,
  mngCardId: 0,
  dclrId: 0,
  stts: 0,
  avtsmtMthd: null,
  cycl: 0,
  regYmd: null,
  brokenYmd: '',
  rstrYmd: '',
  rstrCmptnYmd: null,
  knd: '',
  cn: '',
  rstrDlyRsn: '',
  fileId: null
});
