import React from 'react';
import "./FileUploadInput.scss"
import axios from "axios";
import {requestURL} from "../config";
import DownloadIcon from "../resources/icons/download_2_FILL1_wght400_GRAD0_opsz48.svg";

export const signAccept = ".jpg, .jpeg, .png"
export const pictureAccept = ".jpg, .jpeg, .png, .pdf"
export const documentAccept = ".jpg, .jpeg, .png, .pdf, .hwp, .hwpx, .xls, .xlsx, .ppt, .pptx"
export const documentAndVideoAccept = ".jpg, .jpeg, .png, .pdf, .hwp, .hwpx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi"
interface Props{
  name:string;
  downloadName:string;
  value:number|null;
  authority?:'private'|'public';
  onChange?:(e:FileUploadEvent)=>void;
  disabled?:boolean;
  accept?:string;
}

export interface FileUploadEvent{
  name:string;
  fileId:number|null;
}

export const FileUploadInput = (props:Props) => {
  const fileRef = React.useRef<HTMLInputElement>(null);
  const disabled = props.disabled ?? false;

  const onClickUploadFile = () => fileRef.current?.click();
  
  const onChangeSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0];
    let ext = file?.name.split('.').pop()?.toLowerCase();
    let allowedExt = props.accept?.split(',').map(e => e.replace(".","").trim().toLowerCase());
    if (allowedExt != undefined && !allowedExt.includes(ext ?? "")){
      alert(`확장자가 ${props.accept}인 파일만 업로드 가능합니다.`);
      return;
    }
    try {
      let fileId = await uploadFile(file);
      props.onChange?.({name:props.name,fileId});
    }catch(e:any){
      alert(e.message);
      return;
    }
  };
  
  const onDeleteFile = async () => {
    props.onChange?.({name:props.name,fileId:null});
  };
  
  const uploadFile = async (file: File|undefined) => {
    if (file == undefined) throw new Error("파일을 선택해 주세요.");
    let formData = new FormData();
    formData.append('file', file);
    
    try {
      return (await axios.post<number>(`/upload-file/${props.authority ?? 'private'}`, formData)).data
    }catch (e:any){
      throw new Error("파일 업로드에 실패했습니다. "+e.message);
    }
  }
  
  return (
    <div className="FileUploadInput">
      {props.value == null ?<>
        {!disabled ?<>
          <input type="file" style={{display:"none"}}
                 ref={fileRef} onChange={onChangeSelectFile} accept={props.accept}/>
          <button className="select-button" onClick={onClickUploadFile}>파일 선택</button>
        </>:<div>파일 없음</div>}
      </>:<div className="uploaded-container">
        <a href={`${requestURL}/download-file/${props.value}`}>
          <button className="download-button">{props.downloadName}</button>
        </a>
        {!disabled ?
        <button className="delete-button" onClick={onDeleteFile}>X</button>:
        <img src={DownloadIcon} className="download-icon" style={{width:"16px",height:"16px"}}/>}
      </div>}
    </div>
  )
}

