import {ComplaintBase} from "./complaintBaseDto";
import {AwSignCode, AwSignReplaceCode} from "../code";

export interface PostChangeComplaintReq{
  signerNm: string;
  signFileId: number | null;
  declaration: ChangeDeclaration[];
}

export interface ChangeDeclaration extends ComplaintBase{
  chgYmd: string;
  chgAwlHighANum: number | null;
  chgAwlHighBNum: number | null;
  chgAwlHighConcrnt: boolean;
  chgAwlHighInstlFileId: number | null;
  chgAwlHighPrdctId: number | null;
  chgAwlHighPrdctNm: string;
  chgAwlHighPrdctCertFileId: number | null;
  chgAwlMdANum: number | null;
  chgAwlMdAConcrnt: boolean;
  chgAwlMdAInstlFileId: number | null;
  chgAwlMdAPrdctId: number | null;
  chgAwlMdAPrdctNm: string;
  chgAwlMdAPrdctCertFileId: number | null;
  chgAwlMdBNum: number | null;
  chgAwlMdCNum: number | null;
  chgAwlMdBcConcrnt: boolean;
  chgAwlMdBcInstlFileId: number | null;
  chgAwlMdBcPrdctId: number | null;
  chgAwlMdBcPrdctNm: string;
  chgAwlMdBcPrdctCertFileId: number | null;
  chgAwlLowANum: number | null;
  chgAwlLowBNum: number | null;
  chgAwlLowCNum: number | null;
  chgAwlLowDNum: number | null;
  chgAwlLowENum: number | null;
  chgAwlLowConcrnt: boolean;
  chgAwlLowInstlFileId: number | null;
  chgAwlLowPrdctId: number | null;
  chgAwlLowPrdctNm: string;
  chgAwlLowPrdctCertFileId: number | null;
  chgAwsignType: AwSignCode;
  chgAwsignReplace: AwSignReplaceCode;
  chgAwsignPaintingDevide: string;
  chgAwsignSignRedNum: number | null;
  chgAwsignSignWhiteNum: number | null;
  chgAwsignSignGap: number | null;
  chgAwsignFlagSingleColr: number | null;
  chgAwsignFlagCheckPattern: number | null;
  chgAwsignFlagWhiteOrangeColr: number | null;
  chgFlrplnFileId: number | null;
  chgAwsignFlrplnFileId: number | null;
  chgInstlFileId: number | null;
  chgCtlboxFileId: number | null;
  chgCtlboxCompListFileId: number | null;
}

export const getDefaultPostChangeComplaintReq = ():PostChangeComplaintReq => ({
  signerNm: "",
  signFileId: null,
  declaration: [getDefaultChangeDeclaration()]
});

export const getDefaultChangeDeclaration = ():ChangeDeclaration => ({
  id:null,
  no:"",
  mngCardNo:"",
  mngCardId:null, 
  kepco: false,
  obstZip: "",
  obstAddr: "",
  obstNm: "",
  aviationAgencyCd: null,
  sareaCd: null,
  rstrcZoneCd: "001",
  dclNm: "",
  dclBrno: "",
  dclZip: "",
  dclAddr: "",
  instlYmd: "",
  crdNorth: "",
  crdEast: "",
  obstType: "001",
  lctnHgt: null,
  hgtFromGrnd: null,
  hgtFromSea: null,
  horizontalWdth: null,
  vrtcWdth: null,
  airport: "1",
  distFromAirport: null,
  bearingFromAirport: "",
  cluster: false,
  awlHighANum: null,
  awlHighBNum: null,
  awlHighConcrnt: false,
  awlHighInstlFileId: null,
  awlHighPrdctId: null,
  awlHighPrdctNm: "",
  awlHighPrdctCertFileId: null,
  awlMdANum: null,
  awlMdAConcrnt: false,
  awlMdAInstlFileId: null,
  awlMdAPrdctId: null,
  awlMdAPrdctNm: "",
  awlMdAPrdctCertFileId: null,
  awlMdBNum: null,
  awlMdCNum: null,
  awlMdBcConcrnt: false,
  awlMdBcInstlFileId: null,
  awlMdBcPrdctId: null,
  awlMdBcPrdctNm: "",
  awlMdBcPrdctCertFileId: null,
  awlLowANum: null,
  awlLowBNum: null,
  awlLowCNum: null,
  awlLowDNum: null,
  awlLowENum: null,
  awlLowConcrnt: false,
  awlLowInstlFileId: null,
  awlLowPrdctId: null,
  awlLowPrdctNm: "",
  awlLowPrdctCertFileId: null,
  awsignType: "006",
  awsignReplace: "고광도 A로 대체",
  awsignPaintingDevide: "",
  awsignSignRedNum: null,
  awsignSignWhiteNum: null,
  awsignSignGap: null,
  awsignFlagSingleColr: null,
  awsignFlagCheckPattern: null,
  awsignFlagWhiteOrangeColr: null,
  mngrNm: "",
  mngrTel: "",
  mngrJbttl: "",
  mngrFax: "",
  chgYmd: "",
  chgAwlHighANum: null,
  chgAwlHighBNum: null,
  chgAwlHighConcrnt: false,
  chgAwlHighInstlFileId: null,
  chgAwlHighPrdctId: null,
  chgAwlHighPrdctNm: "",
  chgAwlHighPrdctCertFileId: null,
  chgAwlMdANum: null,
  chgAwlMdAConcrnt: false,
  chgAwlMdAInstlFileId: null,
  chgAwlMdAPrdctId: null,
  chgAwlMdAPrdctNm: "",
  chgAwlMdAPrdctCertFileId: null,
  chgAwlMdBNum: null,
  chgAwlMdCNum: null,
  chgAwlMdBcConcrnt: false,
  chgAwlMdBcInstlFileId: null,
  chgAwlMdBcPrdctId: null,
  chgAwlMdBcPrdctNm: "",
  chgAwlMdBcPrdctCertFileId: null,
  chgAwlLowANum: null,
  chgAwlLowBNum: null,
  chgAwlLowCNum: null,
  chgAwlLowDNum: null,
  chgAwlLowENum: null,
  chgAwlLowConcrnt: false,
  chgAwlLowInstlFileId: null,
  chgAwlLowPrdctId: null,
  chgAwlLowPrdctNm: "",
  chgAwlLowPrdctCertFileId: null,
  chgAwsignType: "006",
  chgAwsignReplace: "고광도 A로 대체",
  chgAwsignPaintingDevide: "",
  chgAwsignSignRedNum: null,
  chgAwsignSignWhiteNum: null,
  chgAwsignSignGap: null,
  chgAwsignFlagSingleColr: null,
  chgAwsignFlagCheckPattern: null,
  chgAwsignFlagWhiteOrangeColr: null,
  chgFlrplnFileId: null,
  chgAwsignFlrplnFileId: null,
  chgInstlFileId: null,
  chgCtlboxFileId: null,
  chgCtlboxCompListFileId: null
});

