import React from 'react';
import "./User.scss"
import {Route, Routes, useLocation} from "react-router-dom";
import {Map} from "./Introduce/Map";
import {BreadCrumb} from "../comp-shared/BreadCrumb";
import {LeftNaviagtor} from "../comp-shared/LeftNaviagtor";
import {LeftNavigatorItem} from "../comp-shared/LeftNavigator/LeftNavigatorItem";
import {Policy} from "./Introduce/Policy";
import {EmailGathering} from "./Introduce/EmailGathering";
import {Privacy} from "./Introduce/Privacy";
import {Login} from "./User/Login";
import {Join} from "./User/Join";
import {FindPassword} from "./User/FindPassword";
import {MyComplaint} from "./User/MyComplaint";
import {MyInfo} from "./User/MyInfo";
import {parseJWT} from "../util/loginManager";
import {InstallComplaint} from "./Complaint/InstallComplaint";
import {ChangeComplaint} from "./Complaint/ChangeComplaint";
import {DemolishComplaint} from "./Complaint/DemolishComplaint";
import {BrokenComplaint} from "./Broken/BrokenComplaint";
import {InquiryBox} from "../comp-shared/InquiryBox";

export const User = () => {
  const location = useLocation();
  const accessToken = parseJWT();
  const getBreadcrumbPath = () => {
    if (location.pathname.includes("login")) return ["회원서비스","로그인"];
    if (location.pathname.includes("join")) return ["회원서비스","회원가입"];
    if (location.pathname.includes("find-password")) return ["회원서비스","비밀번호 찾기"];
    if (location.pathname.includes("my-complaint")) return ["회원서비스","나의 민원"];
    if (location.pathname.includes("my-info")) return ["회원서비스","회원정보"];
    else return []
  };
  
  return (
    <div className="User">
      <BreadCrumb path={getBreadcrumbPath()}/>
      <div className="container">
        <div className="container-inbox">
          <div>
            <LeftNaviagtor title="회원서비스">
              {!accessToken.user ? <>
                <LeftNavigatorItem path="/user/login"> 로그인 </LeftNavigatorItem>
                <LeftNavigatorItem path="/user/join"> 회원가입 </LeftNavigatorItem>
                <LeftNavigatorItem path="/user/find-password"> 비밀번호 찾기</LeftNavigatorItem>
              </>:<>
                <LeftNavigatorItem path="/user/my-complaint"> 나의 민원</LeftNavigatorItem>
                <LeftNavigatorItem path="/user/my-info">회원정보</LeftNavigatorItem>
              </>
              }
            </LeftNaviagtor>
            <InquiryBox />
          </div>
          <Routes>
            <Route path="login" element={<Login/>}/>
            <Route path="join/*" element={<Join/>}/>
            <Route path="find-password" element={<FindPassword/>}/>
            <Route path="my-complaint" element={<MyComplaint/>}/>
            <Route path="my-info" element={<MyInfo/>}/>
            <Route path="my-complaint/install/:id" element={<InstallComplaint/>}/>
            <Route path="my-complaint/change/:id" element={<ChangeComplaint/>}/>
            <Route path="my-complaint/demolish/:id" element={<DemolishComplaint/>}/>
            <Route path="my-complaint/broken/:id" element={<BrokenComplaint/>}/>
            <Route path="*" element={<Login/>}/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

