import {ComplaintBase} from "./complaintBaseDto";
import {AwSignCode, AwSignReplaceCode} from "../code";

export interface PostDemolishComplaintReq {
  signerNm: string;
  signFileId: number | null;
  declaration: DemolishDeclaration[];
}

export interface DemolishDeclaration extends ComplaintBase{
  dmltnYmd: string;
  dmltnFileId: number | null;
}

export const getDefaultPostDemolishComplaintReq = ():PostDemolishComplaintReq => ({
  signerNm: "",
  signFileId: null,
  declaration: [getDefaultDemolishDeclaration()]
});

export const getDefaultDemolishDeclaration = ():DemolishDeclaration => ({
  id:null,
  no:"",
  mngCardNo:"",
  mngCardId:null, 
  kepco: false,
  obstZip: "",
  obstAddr: "",
  obstNm: "",
  aviationAgencyCd: null,
  sareaCd: null,
  rstrcZoneCd: "001",
  dclNm: "",
  dclBrno: "",
  dclZip: "",
  dclAddr: "",
  instlYmd: "",
  crdNorth: "",
  crdEast: "",
  obstType: "001",
  lctnHgt: null,
  hgtFromGrnd: null,
  hgtFromSea: null,
  horizontalWdth: null,
  vrtcWdth: null,
  airport: "1",
  distFromAirport: null,
  bearingFromAirport: "",
  cluster: false,
  awlHighANum: null,
  awlHighBNum: null,
  awlHighConcrnt: false,
  awlHighInstlFileId: null,
  awlHighPrdctId: null,
  awlHighPrdctNm: "",
  awlHighPrdctCertFileId: null,
  awlMdANum: null,
  awlMdAConcrnt: false,
  awlMdAInstlFileId: null,
  awlMdAPrdctId: null,
  awlMdAPrdctNm: "",
  awlMdAPrdctCertFileId: null,
  awlMdBNum: null,
  awlMdCNum: null,
  awlMdBcConcrnt: false,
  awlMdBcInstlFileId: null,
  awlMdBcPrdctId: null,
  awlMdBcPrdctNm: "",
  awlMdBcPrdctCertFileId: null,
  awlLowANum: null,
  awlLowBNum: null,
  awlLowCNum: null,
  awlLowDNum: null,
  awlLowENum: null,
  awlLowConcrnt: false,
  awlLowInstlFileId: null,
  awlLowPrdctId: null,
  awlLowPrdctNm: "",
  awlLowPrdctCertFileId: null,
  awsignType: "006",
  awsignReplace: "고광도 A로 대체",
  awsignPaintingDevide: "",
  awsignSignRedNum: null,
  awsignSignWhiteNum: null,
  awsignSignGap: null,
  awsignFlagSingleColr: null,
  awsignFlagCheckPattern: null,
  awsignFlagWhiteOrangeColr: null,
  mngrNm: "",
  mngrTel: "",
  mngrJbttl: "",
  mngrFax: "",
  dmltnYmd: "",
  dmltnFileId: null,
});

