import React from 'react';
import "./EmailGathering.scss"
import {PageTitle} from "../../comp-shared/PageTitle";

export const EmailGathering = () => {
  return (
    <div className="EmailGathering">
      <PageTitle title="이메일주소 무단 수집 거부"/>
      <div className="policy-box">
        <p className="text-box">
          <br/>
            <strong>본웹사이트에 게시된 이메일주소가 전자우편 수집프로그램이나 그 밖의 기술적장치를
              이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시 정보통신망법에 의해 형사처벌됨을 유념하시기바랍니다.
            </strong><br/><br/>
            정보통신망이용촉진및정보보호등에관한법률」[타법개정2010.3.22법률제10166호]</p>

        <div className="list-box">
          <ul>
            <li>우리부에서 제공하는 콘텐츠로 수익을 얻거나 이에 상응하는 혜택을 누리고자 하는 경우에는
              우리부와 사전에 별도의 협의를 하거나 허락을 득하여야하며, 협의 또는 허락을 얻어 자료의 내용을
              게재하는 경우에도 출처가 교육과학기술부임을 반드시 명시하여야합니다.</li>
            <li>우리부의 콘텐츠를 적법한 절차에 따라 다른 인터넷사이트에 게재하는 경우에도 단순한 오류정정
              이외에 내용의 무단변경을 금지하며, 이를 위반할때에는 형사처분을 받을 수 있습니다.</li>
            <li>다른 인터넷사이트에서 우리부 홈페이지로 링크하는 경우에도 링크사실을 우리부에 통지하여야합니다.</li>
          </ul>
        </div>

        <h4>제50조의2(전자우편주소의무단수집행위등금지)</h4>
        <div className="list-box">
          <ul>
            <li>누구든지 인터넷홈페이지 운영자 또는 관리자의 사전동의 없이 인터넷 홈페이지에서
              자동으로 전자우편 주소를 수집하 는프로그램이나 그 밖의 기술적 장치를 이용하여
              전자우편주소를 수집하여서는 아니된다.</li>
            <li>누구든지 제1항을 위반하여 수집된 전자우편주소를 판매&middot;유통하여서는 아니된다.</li>
            <li>누구든지 제1항과 제2항에 따라 수집&middot;판매 및 유통이 금지된 전자우편주소임을 알면서
              이를 정보전송에 이용하여서는 아니된다.[전문개정2008.6.13]</li>
          </ul>
        </div>

        <h4>제74조(벌칙) ①다음 각호의 어느 하나에 해당하는 자는 1년이하의 징역 또는 1천만원 이하의 벌금에 처한다.</h4>
        <div className="list-box">
          <ul>
            <li>제8조 제4항을 위반하여 비슷한 표시를 한 제품을 표시&middot;판매 또는 판매할 목적으로 진열한 자</li>
            <li>제44조의 7제1항 제1호의 규정을 위반하여 음란한 부호&middot;문언&middot;음향&middot;화상 또는 영상을
              배포&middot;판매&middot;임대하거나 공공연하게 전시한 자</li>
            <li>제44조의 7제1항 제3호의 규정을 위반하여 공포심이나 불안감을 유발하는
              부호&middot;문언&middot;음향&middot;화상 또는 영상을 반복적으로 상대방에게 도달하게 한 자</li>
            <li>제50조 제6항의 규정을 위반하여 기술적 조치를 한 자</li>
            <li>제50조의 2를 위반하여 전자우편주소를 수집&middot;판매&middot;유통하거나 정보전송에 이용한 자</li>
            <li>제50조의8을위반하여광고성정보를전송한자</li>
            <li>제53조 제4항을 위반하여 등록사항의 변경 등록 또는 사업의 양도&middot;양수
              또는 합병&middot;상속의 신고를 하지 아니한 자</li>
          </ul><br/>
          제1항 제3호의 죄는 피해자가 구체적으로 밝힌 의사에 반하여 공소를 제기할 수 없다.
        </div>
      </div>
    </div>
  )
}

