import {PageTitle} from "../../comp-shared/PageTitle";
import "./RestoreComplaint.scss"
import {useNavigate, useParams} from "react-router-dom";
import BrokenCycle from "../../comp-shared/Broken/BrokenCycle";
import {ChangeEvent, useEffect, useState} from "react";
import {checkLoginAndRedirect} from "../../util/loginManager";
import axios from "axios";
import {CommonBrokenInfoRes} from "../../server/user/myComplaintDto";
import {parseErrorMessage} from "../../util/errorParser";
import {TabSelector} from "../../comp-shared/TabSelector";
import {PostRestoreComplaintReq} from "../../server/complaint/brokenComplaintDto";
import moment from "moment";
import {
  documentAccept,
  documentAndVideoAccept,
  FileUploadEvent,
  FileUploadInput
} from "../../comp-shared/FileUploadInput";
import BrokenStatusInfo from "../../comp-shared/Broken/BrokenStatusInfo";
import ReactLoading from "react-loading";
import {useMyBrokenComplaint} from "../../comp-shared/Broken/useMyBrokenComplaint";

export default function RestoreComplaint() {
  const navigate = useNavigate();
  const params = useParams();

  const [isSearchByMngCardNum, setIsSearchByMngCardNum]
    = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const {
    selectedPage,
    setSelectedPage,
    rebrokenComplaint,
    setRebrokenComplaint,
    selectedRebrokenComplaint,
    isRestored,
    isOnRestore,
    isCycleEnd} = useMyBrokenComplaint();

  const postDisabledByRebrokenStts = selectedRebrokenComplaint.myRebrokenComplaintList
    .some(rebroken => rebroken.stts === -1);

  const [isProgress, setIsProgress] = useState(false);
  const [postRestoreReq, setPostRestoreReq]
    = useState<PostRestoreComplaintReq>(
      {mngCardId: 0, dclrId: 0, brokenYmd: '', rstrCmptnYmd: '', fileId: 0});

  useEffect(() => {
    checkLoginAndRedirect(navigate)
    init()
  }, []);

  const init = async () => {
    try {
      setIsLoading(true);
      if (params.hasOwnProperty("id")) {
        let res = await axios.get<CommonBrokenInfoRes[]>("/complaint/my-complaint/rebroken/"+params.id)
        setRebrokenComplaint(res.data)
        setIsSearchByMngCardNum(false)
      }
      if (params.hasOwnProperty("mngCardNum")) {
        let res = await axios.post<CommonBrokenInfoRes>(`/complaint/mng-card/broken/get-info`,{mngNo:params.mngCardNum})
        setRebrokenComplaint([res.data])
        setIsSearchByMngCardNum(true)
      }
    } catch (e) {
      alert("데이터를 불러오지 못했습니다. "+parseErrorMessage(e))
      navigate("/broken/restore")
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setPostRestoreReq(
      {...postRestoreReq,
        mngCardId: selectedRebrokenComplaint.mngCardId,
        dclrId: selectedRebrokenComplaint.myRebrokenComplaintList[0].dclrId,
        brokenYmd: selectedRebrokenComplaint.myRebrokenComplaintList[0].brokenYmd,
        rstrCmptnYmd: '',
        fileId: 0
      })
  }, [rebrokenComplaint, selectedPage]);

  const onChangeStr = (e: ChangeEvent<HTMLInputElement>) => setPostRestoreReq({...postRestoreReq, [e.target.name]: e.target.value});
  const onChangeFile = (e: FileUploadEvent) => setPostRestoreReq({...postRestoreReq, [e.name]: e.fileId});

  const onClickToPostRestore = async () => {
    if (postRestoreReq.rstrCmptnYmd === '') {
      alert("복구완료 통지일을 입력해주세요.")
      return
    }
    if (postRestoreReq.fileId === 0) {
      alert("복구완료된 현장사진이 필요합니다.")
      return
    }
    if (!window.confirm("복구완료 통지를 신청하시겠습니까?")) return
    try {
      setIsProgress(true)
      await axios.post("/complaint/restore", postRestoreReq)
      alert("복구완료 통지 신청이 완료되었습니다.")
      init();
    } catch (e) {
      alert(parseErrorMessage(e))
    } finally {
      setIsProgress(false)
    }
  }

  if (isLoading) return <div className='RestoreComplaint flex-center' style={{height: '70vh'}}>
    <ReactLoading type="spin" color="#c3d0e4" />
  </div>

  return (
    <div className='RestoreComplaint'>
      <PageTitle title="복구완료 통지"/>
      { !isSearchByMngCardNum &&
          <TabSelector selectedPage={selectedPage} setSelectedPage={setSelectedPage} data={rebrokenComplaint ?? []}/>
      }
      <BrokenStatusInfo
        type="Restore"
        mngNo={selectedRebrokenComplaint.mngNo}
        isCycleEnd={isCycleEnd} isOnRestore={isOnRestore} isRestored={isRestored}
      />
      { !isOnRestore && <div className='restore-grid mb'>
          <div className='header green' style={{gridColumn: 'auto / span 4'}}>
              관리번호 : ( {selectedRebrokenComplaint.mngNo} ) 복구완료 통지
          </div>
        {postDisabledByRebrokenStts ? <div className='field' style={{gridColumn: 'auto / span 4'}}>
            <span className='alert-red-font'>고장 재통지 승인 완료 후 복구완료 통지 신청이 가능합니다.</span></div>
          : <>
            <div className='header green'>복구완료 통지일</div>
            <div className='field'>
              <input
                className='input full-width'
                type={"date"}
                name={"rstrCmptnYmd"}
                value={postRestoreReq.rstrCmptnYmd}
                onChange={onChangeStr}
                disabled={postDisabledByRebrokenStts}
                min={moment().format("YYYY-MM-DD")}
              />
            </div>
            <div className='header green'>복구완료 사진</div>
            <div className='field'>
              <FileUploadInput
                downloadName="첨부파일"
                name="fileId"
                value={postRestoreReq.fileId !== 0 ? postRestoreReq.fileId : null}
                onChange={onChangeFile}
                accept={documentAndVideoAccept}
              />
            </div>
          </>}
      </div>}
      {!isOnRestore && !postDisabledByRebrokenStts && <div className='flex-btn'>
          <span>※ 각 관리번호 개별로 신청하시기 바랍니다. (묶음 신청 불가)</span>
          <button
              className='button'
              onClick={onClickToPostRestore}
              disabled={isProgress}
          >복구완료 통지
          </button>
      </div>}
      {selectedRebrokenComplaint.myRebrokenComplaintList
        .map((rebrokenItem) => {
          if (rebrokenItem)
            return <BrokenCycle cycleIndex={rebrokenItem.cycl + 1} 
                                mutate={init}
                                myRebrokenComplaintItem={rebrokenItem} key={rebrokenItem.cycl + 1}/>
        })
        .reverse()}
    </div>
  )
}
