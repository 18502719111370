import React, {ChangeEvent, useState,KeyboardEvent} from 'react';
import "./MngCardLoader.scss"
import SearchIcon from "../../resources/icons/search_FILL0_wght400_GRAD0_opsz48.svg";
import {GetMngCardRes} from "../../server/complaint/mngCardDto";
import {Simulate} from "react-dom/test-utils";
import axios from "axios";
import {parseErrorMessage} from "../../util/errorParser";

interface Props{
  onLoad: (mngCard:GetMngCardRes) => void;
}

export const MngCardLoader = (props:Props) => {
  const [mngCardNum, setMngCardNum] = useState<string>("");
  const onClickSearch = async () => {
    if (mngCardNum === "") return alert("관리번호를 입력해주세요.");
    try {
      let res = await axios.post<GetMngCardRes>(`/complaint/mng-card/get-info`,{mngNo:mngCardNum});
      props.onLoad(res.data);
      setMngCardNum("");
    }catch (e) {
      alert(parseErrorMessage(e));
    }
  };
  
  const onChangeMngCardNum = (e:ChangeEvent<HTMLInputElement>) => setMngCardNum(e.target.value)

  const onKeyDownSearch = (e:KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") onClickSearch();
  };
  return (
    <div className="MngCardLoader">
      <input className="input"
             placeholder="관리번호 입력 (관리번호를 모를 경우 각 지방청 담당자에게 문의해주세요)"
             value={mngCardNum}
             onChange={onChangeMngCardNum}
             onKeyDown={onKeyDownSearch}
             name="mngCardNum"
      />
      <button className="button-circle" style={{marginRight:"10px"}} 
              onClick={onClickSearch}>
        <img src={SearchIcon} width={25}/>
      </button>
    </div>
  )
}

