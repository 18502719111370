import React, {useEffect} from 'react';
import {BrowserRouter, Route, Router, Routes, ScrollRestoration, useLocation} from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import "./index.scss"
import {TopBar} from "./comp-pages/index/TopBar";
import {TopNavigator} from "./comp-pages/index/TopNavigator";
import {Main} from "./pages/Main";
import {BottomBar} from "./comp-pages/index/BottomBar";
import {FooterSiteInfo} from "./comp-pages/index/FooterSiteInfo";
import {Introduce} from "./pages/Introduce";
import ScrollToTop from "./util/scrollToTop";
import {User} from "./pages/User";
import axios from "axios";
import {basePath, requestURL} from "./config";
import {Complaint} from "./pages/Complaint";
import Cookies from "universal-cookie";
import {Broken} from "./pages/Broken";
import {Estimate} from "./pages/Estimate";
import {Notice} from "./pages/Notice";
import {Help} from "./pages/Help";

const root = createRoot(document.getElementById("root") as HTMLElement);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = requestURL;
axios.interceptors.response.use(
  res => res,
  async error => {
    if (error.response === undefined) return Promise.reject(error);
    const status = error.response.status;
    if (status === 401){
      //401을 받은 경우 갱신 실패임. 쿠키 삭제 후 새로고침
        let cookie = new Cookies();
        cookie.remove("accessToken", {path: "/"});
        cookie.remove("refreshToken", {path: "/"});
        window.location.reload();
    }
    return Promise.reject(error);
  }
)

function App() {
    const location = useLocation();
    useEffect(() => {
        const page = location.pathname;
        axios.post('/view-count/user', {page})
            .catch(err => console.error(err));
    }, [location.pathname]);

  return (
    <div className="App">
      <TopBar/>
      <TopNavigator/>
      <div className="app-inner">
      <Routes>
        <Route path="/main/*" element={<Main/>}/>
        <Route path="/introduce/*" element={<Introduce/>}/>
        <Route path="/complaint/*" element={<Complaint/>}/>
        <Route path="/broken/*" element={<Broken/>}/>
        <Route path="/estimate/*" element={<Estimate/>}/>
        <Route path="/user/*" element={<User/>}/>
        <Route path="/notice/*" element={<Notice/>}/>
        <Route path="/help/*" element={<Help/>}/>
        <Route path="*" element={<Main/>}/>
      </Routes>
      </div>
      <BottomBar/>
      <FooterSiteInfo/>
    </div>
  )
}


root.render(
  <BrowserRouter basename={basePath}>
    <ScrollToTop/>
    <App/>
  </BrowserRouter>
);
