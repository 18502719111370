import React from 'react';
import "./Privacy.scss"
import {PageTitle} from "../../comp-shared/PageTitle";

export const Privacy = () => {
  return (
    <div className="Privacy">
      <PageTitle title="개인정보처리방침"/>
      <div className="policy-box">
        <p className="text-box">
          <br/><strong>[항공장애표시등 민원서비스]는 개인정보보호법에 따라 이용자의 개인정보 보호
            및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 아래와 같은 처리 방침을 두고 있습니다.</strong></p>

        <h4>1조. 개인정보의 처리목적, 처리 및 보유기간, 처리하는 개인정보의 항목</h4>
        <p className="text-box">
          [항공장애표시등 민원서비스] 대표홈페이지는 직접 개인정보를 수집하지 않으며,
          개인정보 보호법 제32조에 따라 등록, 공개하는 개인정보파일은 붙임과 같습니다.</p>

        <p className="text-box">
          ※ 국토교통부 개인정보파일 보유현황<br/>
          ※ 좀 더 상세한 &lt;국토교통부&gt;의 개인정보파일 등록사항 공개는 개인정보보호 종합지원포털(www.privacy.go.kr)<br/>
          개인정보민원▷ 개인정보의 열람 등 요구 ▷ 개인정보파일 목록 검색 ▷ 기관명에 “국토교통부” 입력 후 조회하실 수 있습니다.</p>

        <h4>2조. 개인정보의 제3자 제공</h4>
        <p className="text-box">
          [항공장애표시등 민원서비스]는 원칙적으로 정보주체의 개인정보를
          수집·이용 목적으로 명시한 범위 내에서 처리하며, 아래의 경우를 제외하고는 정보주체의 사전 동의 없이는 본래의
          목적 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.</p>

        <h4>3조. 개인정보 처리 위탁</h4>
        <p className="text-box">
          [항공장애표시등 민원서비스]는 개인정보의 처리업무를 위탁하는 경우 아래의 내용이
          포함된 문서에 의하여 처리하고 있습니다.</p>

        <div className="list-box">
          <ul><li>위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항</li>
            <li>개인정보의 관리적·기술적 보호조치에 관한 사항</li>
            <li>개인정보의 안전관리에 관한 사항</li>
          </ul>
        </div>
        <p className="text-box">
          위탁업무의 목적 및 범위, 재위탁 제한에 관한 사항, 개인정보 안전성 확보 조치에 관한 사항,
          위탁업무와 관련하여 보유하고 있는 개인정보의 관리현황점검 등 감독에 관한 사항, 수탁자가 준수하여야할
          의무를 위반한 경우의 손해배상책임에 관한 사항 또한, 위탁하는 업무의 내용과 개인정보 처리업무를 위탁받아
          처리하는 자(“수탁자”)에 대하여 해당 홈페이지에 공개하고 있습니다.</p>

        <h4>4조. 정보주체의 권리·의무 및 그 행사 방법</h4>
        <p className="text-box">
          정보주체는 아래와 같은 권리를 행사 할 수 있으며, 만14세 미만 아동의 법정대리인은 그 아동의
          개인정보에 대한 열람, 정정·삭제, 처리정지를 요구할 수 있습니다.</p>
        <div className="list-box">
          <ul><li>개인정보 열람 요구<br/>
            [항공장애표시등 민원서비스]에서 보유하고 있는 개인정보파일은「개인정보보호법」
            제35조(개인정보의 열람)에 따라 자신의 개인정보에 대한 열람을 요구할 수 있습니다.
            다만, 개인정보 열람 요구는 법 제35조 5항에 의하여 아래와 같이 제한될 수 있습니다.
            <ul>
              <li>1. 법률에 따라 열람이 금지되거나 제한되는 경우</li>
              <li>2. 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</li>
              <li>3. 공공기관이 아래 각 목의 어느 하나에 해당하는 업무를 수행할 때 중대한 지장을 초래하는 경우
                <ul>
                  <li>가. 조세의 부과·징수 또는 환급에 관한 업무</li>
                  <li>나. 학력·기능 및 채용에 관한 시험, 자격 심사에 관한 업무</li>
                  <li>다. 보상금·급부금 산정 등에 대하여 진행 중인 평가 또는 판단에 관한 업무</li>
                  <li>라. 다른 법률에 따라 진행 중인 감사 및 조사에 관한 업무</li>
                </ul>
              </li>
            </ul>
          </li>
          </ul>
          <ul>
            <li>개인정보 정정·삭제 요구<br/>
              [항공장애표시등 민원서비스]에서 보유하고 있는 개인정보파일은「개인정보보호법」
              제36조(개인정보의 정정·삭제)에 따라 정정·삭제를 요구할 수 있습니다.
              다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
          </ul>
          <ul>
            <li> 개인정보 처리정지 요구<br/>
              [항공장애표시등 민원서비스]에서 보유하고 있는 개인정보파일은 「개인정보보호법」 제37조(개인정보의 처리정지 등)에 따라 처리정지를 요구할 수 있습니다.
              다만, 개인정보 처리정지 요구 시 법 제37조 2항에 의하여 처리정지 요구가 거절될 수 있습니다.</li>
          </ul>
          <ul>
            <li>법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</li>
            <li>다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</li>
            <li> 공공기관이 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우</li>
            <li> 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우</li>
          </ul>
        </div>

        <h4>5조. 개인정보의 파기</h4>
        <p className="text-box">
          [항공장애표시등 민원서비스]는 원칙적으로 개인정보 보존기간이 경과되거나,
          개인정보 처리목적이 달성된 경우에는 지체 없이 파기합니다. 파기의 절차, 기한 및 방법은 아래와 같습니다.</p>
        <div className="list-box">
          <ul>
            <li> 파기 절차<br/>
              개인정보는 목적 달성 후 즉시 또는 별도의 공간에 옮겨져 내부 방침 및 기타 관련법령에 따라 일정기간
              저장된 후 파기됩니다. 별도의 공간으로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</li>
          </ul>
          <ul>
            <li> 파기 기한 및 파기 방법<br/>
              보유기간이 만료되었거나 개인정보의 처리목적달성, 해당 업무의 폐지 등 그 개인정보가
              불필요하게 되었을 때에는 지체 없이 파기합니다. 전자적 파일형태의 정보는 기록을 재생할 수 없는
              기술적 방법을 사용합니다. 종이에 출력된 대인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
          </ul>
        </div>

        <h4>6조. 개인정보의 안정성 확보 조치</h4>
        <p className="text-box">
          [항공장애표시등 민원서비스]는 개인정보보호법 제29조에 따라
          다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</p>
        <div className="list-box">
          <ul>
            <li> 개인정보 취급직원의 최소화 및 교육<br/>
              개인정보를 취급하는 직원은 반드시 필요한 인원에 한하여 지정 · 관리하고 있으며 취급직원을
              대상으로 안전한 관리를 위한 교육을 실시하고 있습니다.</li>
            <li> 개인정보에 대한 접근 제한<br/>
              개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여·변경·말소를 통하여 개인정보에 대한
              접근통제를 위한 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</li>
            <li> 접속기록의 보관<br/>
              개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 6개월 이상 보관·관리하고 있습니다.</li>
            <li> 개인정보의 암호화<br/>
              개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.</li>
            <li> 보안프로그램 설치 및 주기적 점검·갱신<br/>
              해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적으로 갱신·점검하고 있습니다.</li>
            <li> 비인가자에 대한 출입 통제<br/>
              개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</li>
            <li> 정기적인 자체 점검 실시<br/>
              개인정보 취급 관련 안정성 확보를 위해 정기적으로 소속･산하기관을 포함하여 개인정보 보호관리 점검을 실시하고 있습니다.</li>
            <li> 내부관리계획의 수립 및 시행<br/>
              개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</li>
          </ul>
        </div>

        <h4>7조. 권익침해 구제 방법</h4>
        <p className="text-box">개인정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보 분쟁조정위원회,
          한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.</p>
        <p className="text-box">
          ☞ 개인정보 분쟁조정위원회 : (국번없이) 118 (privacy.kisa.or.kr)<br/>
          ☞ 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br/>
          ☞ 대검찰청 사이버범죄수사단 : 02-3480-3571 (cybercid@spo.go.kr)<br/>
          ☞ 경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr)<br/>
          또한, 개인정보의 열람, 정정·삭제, 처리정지 등에 대한 정보주체자의 요구에 대하여 공공기관의 장이
          행한 처분 또는 부작위로 인하여 권리 또는 이익을 침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br/><br/>

          ☞ 행정심판에 대한 자세한 사항은 국민권익위원회(http://www.acrc.go.kr) 홈페이지를 참고하시기 바랍니다.</p>

        <h4>8조. 개인정보보호 책임관 및 담당자 연락처</h4>
        <p className="text-box">
          개인정보보호법 제31조 제1항에 따른 개인정보보호책임자는 다음과 같습니다.<br/>
          • 개인정보보호 책임자 : 윤칠성<br/>
          • 개인정보보호 담당자 : 윤칠성<br/>
          - 연락처 : 032)740-2114 <br/>
          - Fax : 032)740-2119<br/>
          - 메일: master@korea.kr<br/></p>

        <h4>9조. 개인정보 처리방침의 변경</h4>
        <p className="text-box">이 개인정보처리방침은 2014.10.01일부터 적용됩니다.</p>
      </div>
    </div>
  )
}

