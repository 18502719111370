import "./Install.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import React, {useEffect, useState} from "react";
import {Question1} from "../../comp-pages/Estimate/Install/Question/Question1";
import {Question2} from "../../comp-pages/Estimate/Install/Question/Question2";
import {Question3} from "../../comp-pages/Estimate/Install/Question/Question3";
import {Question4} from "../../comp-pages/Estimate/Install/Question/Question4";
import {Answer1} from "../../comp-pages/Estimate/Install/Answer/Answer1";
import {Answer2} from "../../comp-pages/Estimate/Install/Answer/Answer2";
export const Install = () => {
  const [qnaCheck1, setQnaCheck1] =
    useState<string>('');
  const [qnaCheck2, setQnaCheck2] =
    useState<string>('');
  const [qnaCheck3, setQnaCheck3] =
    useState<string>('');
  const [qnaCheck4, setQnaCheck4] =
    useState<string>('1');
  const [onAnswer1, setOnAnswer1] =
    useState<boolean>(false)
  const [onAnswer2, setOnAnswer2] =
    useState<boolean>(false)

  const askQuestion1 = () => {
    if (qnaCheck1 === '') {
      alert('설치 대상의 구역을 선택해 주세요.');
      return;
    }
    if (qnaCheck2 === '') {
      alert('설치 대상의 종류를 선택해 주세요.');
      return;
    }
    setOnAnswer1(true)
  }

  const askQuestion2 = () => {
    if (qnaCheck1 === '' || qnaCheck2 === '') {
      alert('설치 대상 판단 정보를 먼저 선택해 주셔야 합니다.');
      return;
    }
    if (qnaCheck3 === '') {
      alert('설치하고자 하는 항공표시방법을 선택해 주세요.');
      return;
    }
    setOnAnswer2(true)
  }

  const handleBack = () => {
    setOnAnswer1(false)
    setOnAnswer2(false)
  }

  return (
    <div className="Install">
      <PageTitle title="설치대상 판단서비스"/>
      {/*안내문구 박스*/}
      <div className="box">
        항공장애표시등 설치대상 판단서비스는 법적 효력이 없으며, 참고자료로만 활용이 가능합니다.
        판단결과는 장애물의 형태, 주변환경 등을 종합적으로 고려하지 못하여 실제판단과 차이가 있을 수 있음을 알려드립니다.
        항공장애표시등 설치에 관한 규정 적용시 애매한 부분이 있는 경우에는 관할 지방항공청에 공식적으로 문의하시기 바랍니다.
      </div>

      {/*설치대상판단 질문*/}
      {!onAnswer1 && !onAnswer2 &&
          <div>
              <div><h2>※ 설치대상판단</h2></div>
              <Question1 qnaCheck={qnaCheck1} setQnaCheck={setQnaCheck1}/>
              <Question2 qnaCheck={qnaCheck2} setQnaCheck={setQnaCheck2}/>
              <div className="button-group">
                  <button type="button" className="button"
                          onClick={askQuestion1}>설치대상 기준 보기</button>
              </div>
          </div>
      }

      {/*설치대상판단 답변*/}
      {onAnswer1 &&
          <div className="step">
              <h2>답변</h2>
              <div className="detail-block">
                  <ul><Answer1 qnaCheck1={qnaCheck1} qnaCheck2={qnaCheck2}/></ul>
              </div>
              <div className="button-group">
                  <input className="button" type="button" value=" 돌아가기 " onClick={handleBack}/>
              </div>
          </div>
      }

      {/*종류, 수량, 배치판단 질문*/}
      {!onAnswer1 && !onAnswer2 &&
          <div>
              <div><h2>※ 종류ㆍ수량ㆍ배치판단</h2></div>
              <Question3 qnaCheck={qnaCheck3} setQnaCheck={setQnaCheck3}/>
              <Question4 qnaCheck={qnaCheck4} setQnaCheck={setQnaCheck4}/>
              <div className="button-group">
                  <button type="button" className="button"
                  onClick={askQuestion2}>종류ㆍ수량ㆍ배치판단 기준 보기</button>
              </div>
          </div>
      }

      {/*종류, 수량 배치판단 답변*/}
      {onAnswer2 &&
          <div className="step">
              <h3>답변</h3>
              <div className="detail-block">
                  <ul><Answer2 qnaCheck1={qnaCheck1} qnaCheck2={qnaCheck2}
                               qnaCheck3={qnaCheck3} qnaCheck4={qnaCheck4}/></ul>
              </div>
              <div className="button-group">
                  <input className="button" type="button" value=" 돌아가기 " onClick={handleBack}/>
              </div>
          </div>
      }
    </div>
  )
}