import React from 'react';
import "./Notice.scss"
import {Route, Routes, useLocation} from "react-router-dom";
import {BreadCrumb} from "../comp-shared/BreadCrumb";
import {LeftNaviagtor} from "../comp-shared/LeftNaviagtor";
import {LeftNavigatorItem} from "../comp-shared/LeftNavigator/LeftNavigatorItem";
import {NoticeList} from "./Notice/NoticeList";
import {InquiryBox} from "../comp-shared/InquiryBox";
import {NoticeDetail} from "./Notice/NoticeDetail";

export const Notice = () => {
  const location = useLocation();
  const getBreadcrumbPath = () => {
    return ["알림마당","공지사항"];
  };

  return (
    <div className="Notice">
      <BreadCrumb path={getBreadcrumbPath()}/>
      <div className="container">
        <div className="container-inbox">
          <div>
          <LeftNaviagtor title="공지사항">
            <LeftNavigatorItem path="/notice/list"> 공지사항 </LeftNavigatorItem>
          </LeftNaviagtor>
          <InquiryBox />
          </div>
          <Routes>
            <Route path="list" element={<NoticeList/>}/>
            <Route path="detail/*" element={<NoticeDetail/>}/>
            <Route path="*" element={<NoticeList/>}/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

