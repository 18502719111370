import axios from "axios";
import useSWR from "swr";
import {GetProductTestReportRes} from "./complaint/productTestReportDto";
import {requestURL} from "../config";
import {Pager} from "./pager";
import {GetMyComplaintRes} from "./user/myComplaintDto";
import {GetMngCardBrokenPopupRes} from "./popup/popupDto";
import {GetNtcRes} from "./ntc/ntcDto";
const defaultFetchAxios =
  async <T>(url: string) => axios.get<T>(requestURL+url).then(value => value.data)

export const useSWRProductTestReport = () => {
  return useSWR<GetProductTestReportRes[]>(`/complaint/product-test-report`, defaultFetchAxios)
}

export const useSWRMyComplaint = (page:number) => {
  return useSWR<Pager<GetMyComplaintRes>>(`/user/my-complaint?page=${page}`, defaultFetchAxios)
}

export const useSWRMyCompletedBrokenComplaint = (page:number) => {
  return useSWR<Pager<GetMyComplaintRes>>(`/complaint/my-completed-broken?page=${page}`, defaultFetchAxios)
}


export const useSWRBrokenMngCardList = () => {
  return useSWR<GetMngCardBrokenPopupRes[]>(`/popup/broken`, defaultFetchAxios)
}

export const useSWRNtcList = (page: number, size: number) => {
  return useSWR<Pager<GetNtcRes>>(`/ntc/list?page=${page}&size=${size}`, defaultFetchAxios)
}

export const useSWRNtc = (id:number) => {
  return useSWR<GetNtcRes>(`/ntc/one/${id}`, defaultFetchAxios)
}
