import React from 'react';
import "./BottomBar.scss"
import {useNavigate} from "react-router-dom";

export const BottomBar = () => {
  const navigate = useNavigate();
  return (
    <div className="BottomBar container">
      <div className="container-inbox">
        <div className="link" onClick={()=>navigate("/introduce/map")}>찾아오시는 길</div>
        <div className="link" onClick={()=>navigate("/introduce/policy")}>이용약관</div>
        <div className="link" onClick={()=>navigate("/introduce/emailGathering")}>이메일주소 무단 수집 거부</div>
        <div className="link" onClick={()=>navigate("/introduce/privacy")}>개인정보처리방침</div>
        <div className="link" onClick={()=>window.open("https://data.molit.go.kr/systemQuality/list?system_id=S0053","_blanks")}>품질오류신고 및 확인</div>
        <div className="link" onClick={()=>window.open("https://kotsa.or.kr/alims/","_blanks")}>항공장애표시등 자체점검 관리시스템 바로가기</div>
      </div>
    </div>
  )
}

