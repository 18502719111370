import React from 'react';
import "./TabSelector.scss"

interface Props{
  selectedPage:number;
  setSelectedPage:(index:number) => void;
  pageNameList?:string[];
  data:any[];
}

export const TabSelector = (props:Props) => {
  let pageNameList = props.pageNameList ?? [];
  return (
    <div className="TabSelector">
      {props.data.map((item:any, index:number) => {
        let pageNumber = index + 1;
        return (
          <button key={index} className={`tab ${props.selectedPage === pageNumber ? 'active' : ''}`}
                  onClick={() => props.setSelectedPage(pageNumber)}>
            {pageNameList[index] != null ? pageNameList[index] : pageNumber}
          </button>
        )
      })}
    </div>
  )
}

