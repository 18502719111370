import React, {ChangeEvent, useEffect, useState} from 'react';
import "./InstallComplaint.scss"
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {checkLoginAndRedirect} from "../../util/loginManager";
import {PageTitle} from "../../comp-shared/PageTitle";
import {TabSelector} from "../../comp-shared/TabSelector";
import {Space} from "../../comp-shared/Space";
import {
  getDefaultInstallDeclaration,
  getDefaultPostInstallComplaintReq,
  getTestInstallDeclaration, InstallDeclaration,
  PostInstallComplaintReq
} from "../../server/complaint/installComplaintDto";
import {Address, useDaumPostcodePopup} from "react-daum-postcode";
import {Sign} from "../../comp-shared/Complaint/Sign";
import {
  Airport,
  AirportByAviationAgency,
  AirportCode,
  AviationAgency,
  AviationAgencyBySubarea,
  AwSignCode,
  AwSignReplaceCode,
  ComplaintEditMode,
  getSubareaByAddress,
  Obstacle,
  ObstacleCode,
  Subarea,
  RestrictZoneCode
} from "../../server/code";
import {useSWRProductTestReport} from "../../server/server";
import {useImmer} from "use-immer";
import {
  documentAccept,
  FileUploadEvent,
  FileUploadInput,
} from "../../comp-shared/FileUploadInput";
import axios from "axios";
import {parseErrorMessage} from "../../util/errorParser";
import {RunMode, currentRunMode} from "../../config";
import {GetInstallComplaintRes} from "../../server/user/myComplaintDto";
import PopupPanel from "../../comp-shared/Popup/PopupPanel";
import {TempItem, TempLoaderModal} from "../../comp-shared/Complaint/TempLoaderModal";
import {useLocalStorage} from "usehooks-ts";
import {ImagePopup} from "../../comp-shared/Popup/ImagePopup";
import sampleImage2 from "../../resources/sampleimages/2_sample.png";
import sampleImage3 from "../../resources/sampleimages/3_sample_1.png";
import sampleImage5 from "../../resources/sampleimages/5_sample.png";
import sampleImage6 from "../../resources/sampleimages/6_sample.png";
import {PlanSamplePopup} from "../../comp-pages/Complaint/InstallComplaint/PlanSamplePopup";
import {ObstacleAddress} from "../../comp-shared/Complaint/ObstacleAddress";
import {Draft} from "immer";
import {ComplaintBase} from "../../server/complaint/complaintBaseDto";
import ConfirmPopup, { useConfirmPopup } from "../../comp-shared/Popup/ConfirmPopup";
import moment from "moment";


export const InstallComplaint = () => {
  const isOpenAddressPopup = useDaumPostcodePopup();
  const {popupProps,openPopup} = useConfirmPopup();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [isTempLoaderOpen, setTempLoaderOpen] = useState<boolean>(false);
  const {data: productTestReportData} = useSWRProductTestReport();
  const [editMode, setEditMode] = useState<ComplaintEditMode>(
    params.hasOwnProperty("id") ? "edit" : "create");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [complaint, updateComplaint] = useImmer<PostInstallComplaintReq>(
    getDefaultPostInstallComplaintReq());
  const [signDate, setSignDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const selectedDeclaration = complaint.declaration[selectedPage - 1];

  const [tempComplaint, setTempComplaint] = useLocalStorage<TempItem[]>(
    "tempInstallComplaint", []);
  const [loadedFromTemp, setLoadedFromTemp] = useState<number | null>(null);

  const [isOpenImagePopup, setIsOpenImagePopup] = useState<boolean>(false);
  const [imagePopupSrc, setImagePopupSrc] = useState<string>("");

  const [isOpenPlanSamplePopup, setIsOpenPlanSamplePopup] = useState<boolean>(false);
  const [isProgress, setIsProgress] = useState<boolean>(false);

  const disabled = editMode === "readonly";

  useEffect(() => {
    checkLoginAndRedirect(navigate)
    init()
  }, []);

  const init = async () => {
    try {
      if (params.hasOwnProperty("id")) {
        let res = await axios.get<GetInstallComplaintRes>("/user/my-complaint/install/" + params.id);
        updateComplaint(res.data)
        setSignDate(res.data.chgDt)
        if (res.data.stts !== "S020100000" && !(res.data.stts === "S020200000" && res.data.mdfcnPrm))
          setEditMode("readonly")
      }
    } catch (e) {
      alert("민원을 불러오지 못했습니다. " + parseErrorMessage(e));
      navigate("/user/my-complaint")
    }
  }

  const onClickAdd = () => {
    updateComplaint(draft => {
      draft.declaration.push(getDefaultInstallDeclaration());
      setSelectedPage(draft.declaration.length);
    });
  };

  const onClickDelete = () => {
    if (selectedPage > 1) {
      updateComplaint(draft => {
        draft.declaration = draft.declaration.filter((_, i) => i !== selectedPage - 1);
      });
      setSelectedPage(selectedPage - 1);
    } else {
      alert("첫번째 신고서는 삭제할 수 없습니다.")
    }
  };

  const onChangeStr = (e: ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1][e.target.name] = e.target.value
    })
  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) =>
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1][e.target.name] = e.target.value
    })
  const onChangeFloat = (e: ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1][e.target.name] = parseFloat(e.target.value)
    })
  const onChangeNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.maxLength > 0 && e.target.value.length > e.target.maxLength) return;
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1][e.target.name] = parseInt(e.target.value)
    })
  }
  const onChangeChecked = (e: ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1][e.target.name] = e.target.checked
    })

  const onChangeFile = async (e: FileUploadEvent) => {
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1][e.name] = e.fileId
    })
  };

  const onChangeNullableDate = (e: ChangeEvent<HTMLInputElement>) => {
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1][e.target.name] = e.target.value === "" ? null : e.target.value
    })
  }
  const onClickDeclarerZip = () => isOpenAddressPopup({onComplete: onSelectDeclarerZip});

  const onSelectDeclarerZip = (address: Address) =>
    updateComplaint(draft => {
      let declaration = draft.declaration[selectedPage - 1];
      declaration.dclZip = address.zonecode;
      declaration.dclAddr = address.address;
    })

  const onClickRestrictZone = (value: RestrictZoneCode) =>
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1].rstrcZoneCd = value
    })

  const onChangeAwlHighProductId = async (e: ChangeEvent<HTMLSelectElement>) => {
    let value: number | null = null;
    if (e.target.value !== "") value = parseInt(e.target.value)
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1].awlHighPrdctId = value;
      draft.declaration[selectedPage - 1].awlHighPrdctNm = value !== null ?
        findProductTestReportNameById(value) : "";
    })
  };

  const onChangeAwlMidAProductId = async (e: ChangeEvent<HTMLSelectElement>) => {
    let value: number | null = null;
    if (e.target.value !== "") value = parseInt(e.target.value)
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1].awlMdAPrdctId = value;
      draft.declaration[selectedPage - 1].awlMdAPrdctNm = value !== null ?
        findProductTestReportNameById(value) : "";
    })
  };

  const onChangeAwlMidBcProductId = async (e: ChangeEvent<HTMLSelectElement>) => {
    let value: number | null = null;
    if (e.target.value !== "") value = parseInt(e.target.value)
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1].awlMdBcPrdctId = value;
      draft.declaration[selectedPage - 1].awlMdBcPrdctNm = value !== null ?
        findProductTestReportNameById(value) : "";
    })
  };
  const onChangeAwlLowProductId = async (e: ChangeEvent<HTMLSelectElement>) => {
    let value: number | null = null;
    if (e.target.value !== "") value = parseInt(e.target.value)
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1].awlLowPrdctId = value;
      draft.declaration[selectedPage - 1].awlLowPrdctNm = value !== null ?
        findProductTestReportNameById(value) : "";
    })
  };

  const findProductTestReportNameById = (id: number) => {
    let report = productTestReportData?.find(report => report.id === id);
    if (report === undefined) return "";
    return report.nm;
  }

  const onClickAwsignType = (value: AwSignCode) => {
    updateComplaint(draft => {
      let declaration = draft.declaration[selectedPage - 1];
      declaration.awsignType = value
      declaration.awsignReplace = value === "006" ? "고광도 A로 대체" : "";
      declaration.awsignPaintingDevide = "";
      declaration.awsignSignRedNum = null;
      declaration.awsignSignWhiteNum = null;
      declaration.awsignSignGap = null;
      declaration.awsignFlagSingleColr = null;
      declaration.awsignFlagCheckPattern = null;
      declaration.awsignFlagWhiteOrangeColr = null;
    })
  }
  const onClickAwsignReplace = (value: AwSignReplaceCode) =>
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1].awsignReplace = value as AwSignReplaceCode
    })
  const onChangeSignerName = (name: string) => updateComplaint(draft => {
    draft.signerNm = name
  });
  const onChangeSignFile = (fileId: number | null) => updateComplaint(draft => {
    draft.signFileId = fileId
  });
  const onClickSave = async () => {
    try {
      await openPopup("확인", "여러장의 신고서를 제출하시는 경우, " +
        "민원신고를 여러번 진행하지 마시고\n신고서 추가 기능을 통하여 한번에 제출하시기 바랍니다." +
        "\n\n위 내용을 확인하셨습니까?", "confirm")
    } catch (e) {
      return;
    }
    try {
      setIsProgress(true)
      await axios.post("/complaint/install", complaint)
      alert("민원 신청이 완료되었습니다.")
      // 신청 완료되면 해당 임시저장 삭제
      if (loadedFromTemp !== null)
        setTempComplaint(tempComplaint.filter(temp => temp.id !== loadedFromTemp));
      navigate("/")
    } catch (e) {
      alert(parseErrorMessage(e));
    }finally {
      setIsProgress(false)
    }
  };
  const onClickAddTestDeclaration = () => {
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1] = getTestInstallDeclaration()
    })
  }

  const onClickSaveEdit = async () => {
    if (!window.confirm("민원을 수정하시겠습니까?")) return;
    try {
      setIsProgress(true)
      await axios.post(`/complaint/install/edit/${params.id}`, complaint)
      alert("민원 수정이 완료되었습니다.")
      navigate("/user/my-complaint")
    } catch (e) {
      alert(parseErrorMessage(e));
    } finally{
      setIsProgress(false)
    }
  };
  const onClickCancel = async () => {
    if (!window.confirm("민원을 취하하시겠습니까?")) return;
    try {
      setIsProgress(true)
      await axios.post(`/complaint/remove/${params.id}`)
      alert("민원이 취하되었습니다.")
      navigate("/user/my-complaint")
    } catch (e) {
      alert(parseErrorMessage(e));
    }finally {
      setIsProgress(false)
    }
  };

  const onCloseTempLoader = () => setTempLoaderOpen(false);
  const onClickOpenTempLoader = () => setTempLoaderOpen(true);
  const onCloseImagePopup = () => setIsOpenImagePopup(false);
  const onClosePlanSamplePopup = () => setIsOpenPlanSamplePopup(false);

  const onLoadTemp = (tempItem: TempItem) => {
    setLoadedFromTemp(tempItem.id);
    updateComplaint(tempItem.complaint);
  }

  const onDeleteTemp = (id: number) => {
    if (!window.confirm("임시저장을 삭제하시겠습니까?")) return;
    setTempComplaint(tempComplaint.filter(temp => temp.id !== id))
  };
  const onClickSaveTemp = () => {
    if (!window.confirm("임시저장 하시겠습니까?")) return;
    let id = 1;
    if (tempComplaint.length > 0) id = Math.max(...tempComplaint.map(value => value.id)) + 1

    setTempComplaint([{
      id: id,
      createdAt: new Date().toLocaleString(),
      complaint: complaint
    }, ...tempComplaint,]);
    alert("저장된 내역은 신고서 상단의 임시상단 불러오기를 클릭해 불러오실 수 있습니다.");
  }

  const onClickOpenImagePopup = (imgSrc: string) => {
    setIsOpenImagePopup(true);
    setImagePopupSrc(imgSrc);
  };
  const onClickOpenPlanSamplePopup = () => setIsOpenPlanSamplePopup(true);

  const updateDeclaration = (callback: (complaint: ComplaintBase) => void) => {
    updateComplaint(draft => {
      callback(draft.declaration[selectedPage - 1]);
    });
  }

  const calcObstHeaderLength = () => {
    if (selectedDeclaration.obstType === "011") return "auto / span 10";
    else return "auto / span 9"
  };

  return (
    <>
      <div className="InstallComplaint">
        <PageTitle title={editMode === "create" ? "설치신고" : "나의 민원 상세보기"}/>
        {editMode !== "readonly" ?
          <button className="button" style={{marginRight: "10px"}} onClick={onClickAdd}>신고서
            추가</button> : null}

        {editMode === "create" ? <>
          <button className="button" style={{marginRight: "10px"}}
                  onClick={onClickOpenTempLoader}>임시저장 불러오기
          </button>
        </> : null}
        {currentRunMode == RunMode.DEV ?
          <button className="button" onClick={onClickAddTestDeclaration}>(dev)시험신고서
            내용채우기</button> : null}
        <TabSelector selectedPage={selectedPage} setSelectedPage={setSelectedPage}
                     data={complaint.declaration}/>
        {editMode !== "readonly" ?
          <div style={{display: "flex", marginTop: "10px"}}>
            <Space/>
            <button className="button" onClick={onClickDelete}>신고서 삭제</button>
          </div> : null}

        <ObstacleAddress selectedDeclaration={selectedDeclaration}
                         updateDeclaration={updateDeclaration}
                         editMode={editMode}/>

        <div className="middle-info-box declaration-grid">
          <div className="header yellow"
               style={{gridColumn: "auto / span 5"}}>설치신고서({selectedPage})
          </div>
          <div className="header yellow">처리기간 15일</div>

          <div className="header yellow" style={{gridRow: "auto / span 2"}}>신고인</div>

          <div className="header yellow">성명 (법인명)</div>
          <div className="field">
            <input className="input" placeholder="oo주식회사 대표이사"
                   value={selectedDeclaration.dclNm}
                   name="dclNm" onChange={onChangeStr}
                   disabled={disabled}/>
          </div>
          <div className="header yellow">
            <input type="checkbox" checked={selectedDeclaration.kepco}
                   name="kepco" onChange={onChangeChecked}
                   disabled={disabled}/>
            한전
          </div>
          <div className="header yellow">사업자등록번호</div>
          <div className="field">
            <input className="input" placeholder="숫자만 입력"
                   type="number" maxLength={10}
                   value={selectedDeclaration.dclBrno}
                   name="dclBrno" onChange={onChangeNumber}
                   disabled={disabled}/>
          </div>

          <div className="header yellow">주소</div>
          <div className="field" style={{gridColumn: "auto / span 4"}}>
            <input className="input" style={{width: "100px"}} readOnly={true} placeholder="우편번호"
                   value={selectedDeclaration.dclZip ?? ""} onClick={onClickDeclarerZip}
                   disabled={disabled}/>
            <input className="input" value={selectedDeclaration.dclAddr}
                   name="dclAddr" onChange={onChangeStr} disabled={disabled}/>
          </div>

          <div className="header yellow"
               style={{gridRow: calcObstHeaderLength()}}>장애물
          </div>
          <div className="header yellow">명칭</div>

          <div className="field" style={{gridColumn: "auto / span 2"}}>
            <input className="input" placeholder="예시) ○○○○ 현장 타워크레인"
                   value={selectedDeclaration.obstNm}
                   name="obstNm" onChange={onChangeStr} disabled={disabled}/>
          </div>
          <div className="header yellow" style={{gridRow: "auto / span 2"}}>제한구역</div>
          <div className="field" style={{gridRow: "auto / span 2"}}>
            <div>
              <div>
                <input type="radio" checked={selectedDeclaration.rstrcZoneCd === "001"}
                       onChange={() => onClickRestrictZone("001")} disabled={disabled}/>장애물제한구역 안
              </div>
              <div>
                <input type="radio" checked={selectedDeclaration.rstrcZoneCd === "002"}
                       onChange={() => onClickRestrictZone("002")} disabled={disabled}/>장애물제한구역 밖
              </div>
              <div>
                <input type="radio" checked={selectedDeclaration.rstrcZoneCd === "003"}
                       onChange={() => onClickRestrictZone("003")} disabled={disabled}/>비행안전구역
              </div>
            </div>
          </div>

          <div className="header yellow">설치연월일</div>
          <div className="field" style={{gridColumn: "auto / span 2"}}>
            <input className="input" type="date" value={selectedDeclaration.instlYmd}
                   name="instlYmd" onChange={onChangeStr} disabled={disabled}/>
          </div>
          <div className="field" style={{gridColumn: "auto / span 5"}}>
            설치한 날로부터 15일 이내에 신고하지 않은 경우 과태료가 부과됩니다.
          </div>

          <div className="header yellow">좌표(WGS-84)</div>
          <div className="field" style={{gridColumn: "auto / span 4"}}>
            <div className="headed-input-container grow">
              <span>북위</span>
              <input className="input" placeholder="작성예시) 35-16-25"
                     value={selectedDeclaration.crdNorth}
                     name="crdNorth" onChange={onChangeStr} disabled={disabled}/>
            </div>
            <div className="headed-input-container grow">
              <span>동경</span>
              <input className="input" placeholder="작성예시) 35-16-25"
                     value={selectedDeclaration.crdEast}
                     name="crdEast" onChange={onChangeStr} disabled={disabled}/>
            </div>
          </div>
          <div className="header yellow">소재지 표고</div>
          <div className="field" style={{gridColumn: "auto / span 2"}}>
            <div className="headed-input-container grow">
              <span>해면상(A)</span>
              <input className="input" placeholder="미터(m)" type="number"
                     value={selectedDeclaration.lctnHgt ?? ""}
                     name="lctnHgt"
                     onChange={onChangeFloat} disabled={disabled}/>
            </div>
          </div>
          <div className="header yellow">장애물 종류</div>
          <div className="field">
            <select className="select" value={selectedDeclaration.obstType}
                    name="obstType"
                    onChange={onChangeSelect} disabled={disabled}>
              {Object.keys(Obstacle).map((key) => {
                return <option value={key} key={key}>{Obstacle[key as ObstacleCode]}</option>
              })}
            </select>
          </div>

          {selectedDeclaration.obstType === "011" ?
            <>
              <div className="header yellow">철거예정일</div>
              <div className="field vertical" style={{gridColumn: "auto / span 4"}}>
                <input className="input" type="date"
                       value={selectedDeclaration.dmltnPrnmntYmd ?? ""}
                       name="dmltnPrnmntYmd" onChange={onChangeNullableDate} disabled={disabled}/>
                <div>건설용 크레인의 경우 철거예정일을 반드시 입력해야 합니다.</div>
              </div>
            </> : null
          }

          <div className="header yellow">높이</div>
          <div className="field"
               style={{gridColumn: "auto / span 4", paddingTop: "5px", paddingBottom: "5px"}}>
            <div style={{width: "100%"}}>
              <div className="flex ml" style={{fontSize: "12px"}}>
                (피뢰침 등 부속구조물 포함)
                <Space/>
              </div>
              <div className="flex">
                <div className="headed-input-container grow">
                  <span>지면상(B)</span>
                  <input className="input" placeholder="미터(m)" type="number"
                         value={selectedDeclaration.hgtFromGrnd ?? ""}
                         name="hgtFromGrnd"
                         onChange={onChangeFloat} disabled={disabled}/>
                </div>
                <div className="headed-input-container grow">
                  <span>해면상(A+B)</span>
                  <input className="input" placeholder="미터(m)" type="number"
                         value={selectedDeclaration.hgtFromSea ?? ""}
                         name="hgtFromSea"
                         onChange={onChangeFloat} disabled={disabled}/>
                </div>
              </div>
            </div>
          </div>
          <div className="header yellow">크기</div>
          <div className="field" style={{gridColumn: "auto / span 4"}}>
            <div className="headed-input-container grow">
              <span>가로</span>
              <input className="input" placeholder="미터(m)" type="number"
                     value={selectedDeclaration.horizontalWdth ?? ""}
                     name="horizontalWdth"
                     onChange={onChangeFloat} disabled={disabled}/>
            </div>
            <div className="headed-input-container grow">
              <span>세로</span>
              <input className="input" placeholder="미터(m)" type="number"
                     value={selectedDeclaration.vrtcWdth ?? ""}
                     name="vrtcWdth"
                     onChange={onChangeFloat} disabled={disabled}/>
            </div>
          </div>
          <div className="header yellow">비행장</div>
          <div className="field" style={{gridColumn: "auto / span 2"}}>
            <select className="select" value={selectedDeclaration.airport}
                    name="airport"
                    onChange={onChangeSelect} disabled={disabled}>
              {AirportByAviationAgency[selectedDeclaration.aviationAgencyCd ?? "001"]
                .map((code) => {
                  return <option value={code} key={code}>{Airport[code as AirportCode]}</option>
                })}
            </select>
            <div className="headed-input-container">
              <span>거리</span>
              <input className="input" placeholder="Km" type="number"
                     value={selectedDeclaration.distFromAirport ?? ""}
                     name="distFromAirport"
                     onChange={onChangeFloat} disabled={disabled}/>
            </div>
          </div>

          <div className="header yellow">방위</div>
          <div className="field">
            <input className="input" value={selectedDeclaration.bearingFromAirport}
                   name="bearingFromAirport"
                   onChange={onChangeStr} disabled={disabled}/>
          </div>

          <div className="header yellow">군집물체</div>
          <div className="field" style={{gridColumn: "auto / span 4"}}>
            <input type="checkbox" checked={selectedDeclaration.cluster}
                   name="cluster"
                   onChange={onChangeChecked} disabled={disabled}/>
            단일물체나 서로 떨어져있는 여러개의 물체들이 밀접하게 모여 형성된 하나의 집단
            <Space/>
          </div>

          <div className="header yellow" style={{gridRow: "auto / span 8"}}>항공장애<br/>표시등</div>

          <div className="header yellow">고광도</div>
          <div className="field" style={{gridColumn: "auto / span 4"}}>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>A</span>
              <input className="input" type="number" value={selectedDeclaration.awlHighANum ?? ""}
                     name="awlHighANum" onChange={onChangeNumber} disabled={disabled}/>
            </div>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>B</span>
              <input className="input" type="number" value={selectedDeclaration.awlHighBNum ?? ""}
                     name="awlHighBNum" onChange={onChangeNumber} disabled={disabled}/>
            </div>
            <div>
              <input type="checkbox" checked={selectedDeclaration.awlHighConcrnt}
                     name="awlHighConcrnt" onChange={onChangeChecked} disabled={disabled}/>동시섬광
            </div>
            <Space/>
          </div>

          <div className="header yellow">고광도 <br/>설치사진</div>
          <div className="field vertical">
            (설치 후 제품명이 보이도록 찍은 사진)
            <FileUploadInput name="awlHighInstlFileId"
                             value={selectedDeclaration.awlHighInstlFileId}
                             downloadName="설치사진"
                             onChange={onChangeFile} disabled={disabled}
                             accept={documentAccept}/>
          </div>
          <div className="header yellow">제품명</div>

          <div className="field vertical">
            <select className="select" style={{margin: "5px"}}
                    value={selectedDeclaration.awlHighPrdctId ?? ""}
                    onChange={onChangeAwlHighProductId} disabled={disabled}>
              <option value="">직접등록(시험성적서 파일 필요)</option>
              {productTestReportData?.filter(report => report.knd === 3)
                .map((report) => {
                  return <option value={report.id} key={report.id}>{report.nm}</option>
                })}
            </select>
            {selectedDeclaration.awlHighPrdctId === null ?
              <input className="input" value={selectedDeclaration.awlHighPrdctNm}
                     name="awlHighPrdctNm" onChange={onChangeStr} disabled={disabled}/> : null
            }
          </div>

          <div className="field vertical">
            {selectedDeclaration.awlHighPrdctId === null ? <>
              <div> 시험성적서</div>
              <FileUploadInput name="awlHighPrdctCertFileId"
                               value={selectedDeclaration.awlHighPrdctCertFileId}
                               downloadName="시험성적서"
                               onChange={onChangeFile} disabled={disabled}
                               accept={documentAccept}/>
            </> : null
            }
          </div>

          <div className="header yellow">중광도 A</div>
          <div className="field" style={{gridColumn: "auto / span 4"}}>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>A</span>
              <input className="input" placeholder="" type="number"
                     value={selectedDeclaration.awlMdANum ?? ""}
                     name="awlMdANum" onChange={onChangeNumber} disabled={disabled}/>
            </div>
            <div>
              <input type="checkbox" checked={selectedDeclaration.awlMdAConcrnt}
                     name="awlMdAConcrnt"
                     onChange={onChangeChecked} disabled={disabled}/>동시섬광
            </div>
            <Space/>
          </div>

          <div className="header yellow">중광도 A <br/>설치사진</div>
          <div className="field vertical">
            (설치 후 제품명이 보이도록 찍은 사진)
            <FileUploadInput name="awlMdAInstlFileId"
                             downloadName="설치사진"
                             value={selectedDeclaration.awlMdAInstlFileId}
                             onChange={onChangeFile} disabled={disabled}
                             accept={documentAccept}/>
          </div>

          <div className="header yellow">제품명</div>
          <div className="field vertical">
            <select className="select" style={{margin: "5px"}}
                    value={selectedDeclaration.awlMdAPrdctId ?? ""}
                    onChange={onChangeAwlMidAProductId} disabled={disabled}>
              <option value="">직접등록(시험성적서 파일 필요)</option>
              {productTestReportData?.filter(report => report.knd === 2)
                .map((report) => {
                  return <option value={report.id} key={report.id}>{report.nm}</option>
                })}
            </select>
            {selectedDeclaration.awlMdAPrdctId === null ?
              <input className="input" value={selectedDeclaration.awlMdAPrdctNm}
                     name="awlMdAPrdctNm" onChange={onChangeStr} disabled={disabled}/> : null
            }
          </div>

          <div className="field vertical">
            {selectedDeclaration.awlMdAPrdctId === null ? <>
              <div> 시험성적서</div>
              <FileUploadInput name="awlMdAPrdctCertFileId"
                               value={selectedDeclaration.awlMdAPrdctCertFileId}
                               downloadName="시험성적서"
                               onChange={onChangeFile} disabled={disabled}
                               accept={documentAccept}/>
            </> : null}
          </div>

          <div className="header yellow">중광도 B, C</div>
          <div className="field" style={{gridColumn: "auto / span 4"}}>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>B</span>
              <input className="input" placeholder="" type="number"
                     value={selectedDeclaration.awlMdBNum ?? ""}
                     name="awlMdBNum" onChange={onChangeNumber} disabled={disabled}/>
            </div>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>C</span>
              <input className="input" placeholder="" type="number"
                     value={selectedDeclaration.awlMdCNum ?? ""}
                     name="awlMdCNum" onChange={onChangeNumber} disabled={disabled}/>
            </div>
            <div>
              <input type="checkbox" checked={selectedDeclaration.awlMdBcConcrnt}
                     name="awlMdBcConcrnt" onChange={onChangeChecked} disabled={disabled}/>동시섬광
            </div>
            <Space/>
          </div>

          <div className="header yellow">중광도 B, C <br/>설치사진</div>
          <div className="field vertical">
            (설치 후 제품명이 보이도록 찍은 사진)
            <FileUploadInput name="awlMdBcInstlFileId"
                             value={selectedDeclaration.awlMdBcInstlFileId}
                             downloadName="설치사진"
                             onChange={onChangeFile} disabled={disabled}
                             accept={documentAccept}/>
          </div>
          <div className="header yellow">제품명</div>
          <div className="field vertical">
            <select className="select" style={{margin: "5px"}}
                    value={selectedDeclaration.awlMdBcPrdctId ?? ""}
                    onChange={onChangeAwlMidBcProductId} disabled={disabled}>
              <option value="">직접등록(시험성적서 파일 필요)</option>
              {productTestReportData?.filter(report => report.knd === 2)
                .map((report) => {
                  return <option value={report.id} key={report.id}>{report.nm}</option>
                })}
            </select>
            {selectedDeclaration.awlMdBcPrdctId === null ?
              <input className="input" value={selectedDeclaration.awlMdBcPrdctNm}
                     name="awlMdBcPrdctNm" onChange={onChangeStr} disabled={disabled}/> : null
            }
          </div>

          <div className="field vertical">
            {selectedDeclaration.awlMdBcPrdctId === null ? <>
              <div> 시험성적서</div>
              <FileUploadInput name="awlMdBcPrdctCertFileId"
                               value={selectedDeclaration.awlMdBcPrdctCertFileId}
                               downloadName="시험성적서"
                               onChange={onChangeFile} disabled={disabled}
                               accept={documentAccept}/>
            </> : null}
          </div>

          <div className="header yellow">저광도</div>
          <div className="field" style={{gridColumn: "auto / span 4"}}>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>A</span>
              <input className="input" value={selectedDeclaration.awlLowANum ?? ""}
                     type="number" name="awlLowANum" onChange={onChangeNumber} disabled={disabled}
              />
            </div>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>B</span>
              <input className="input" value={selectedDeclaration.awlLowBNum ?? ""}
                     type="number" name="awlLowBNum" onChange={onChangeNumber} disabled={disabled}/>
            </div>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>C</span>
              <input className="input" value={selectedDeclaration.awlLowCNum ?? ""}
                     type="number" name="awlLowCNum" onChange={onChangeNumber} disabled={disabled}/>
            </div>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>D</span>
              <input className="input" value={selectedDeclaration.awlLowDNum ?? ""}
                     type="number" name="awlLowDNum" onChange={onChangeNumber} disabled={disabled}/>
            </div>
            <div className="headed-input-container" style={{width: "100px"}}>
              <span>E</span>
              <input className="input" value={selectedDeclaration.awlLowENum ?? ""}
                     type="number" name="awlLowENum" onChange={onChangeNumber} disabled={disabled}/>
            </div>
            <div>
              <input type="checkbox" checked={selectedDeclaration.awlLowConcrnt}
                     name="awlLowConcrnt" onChange={onChangeChecked} disabled={disabled}/>동시섬광
            </div>
            <Space/>
          </div>

          <div className="header yellow">저광도 <br/>설치사진</div>
          <div className="field vertical">
            (설치 후 제품명이 보이도록 찍은 사진)
            <FileUploadInput name="awlLowInstlFileId"
                             value={selectedDeclaration.awlLowInstlFileId}
                             downloadName="설치사진"
                             onChange={onChangeFile} disabled={disabled}
                             accept={documentAccept}/>
          </div>
          <div className="header yellow">제품명</div>
          <div className="field vertical">
            <select className="select" style={{margin: "5px"}}
                    value={selectedDeclaration.awlLowPrdctId ?? ""}
                    onChange={onChangeAwlLowProductId} disabled={disabled}>
              <option value="">직접등록(시험성적서 파일 필요)</option>
              {productTestReportData?.filter(report => report.knd === 1)
                .map((report) => {
                  return <option value={report.id} key={report.id}>{report.nm}</option>
                })}
            </select>
            {selectedDeclaration.awlLowPrdctId === null ?
              <input className="input" value={selectedDeclaration.awlLowPrdctNm}
                     name="awlLowPrdctNm" onChange={onChangeStr} disabled={disabled}/> : null
            }
          </div>

          <div className="field vertical">
            {selectedDeclaration.awlLowPrdctId === null ? <>
              <div> 시험성적서</div>
              <FileUploadInput name="awlLowPrdctCertFileId"
                               value={selectedDeclaration.awlLowPrdctCertFileId}
                               downloadName="시험성적서"
                               onChange={onChangeFile} disabled={disabled}
                               accept={documentAccept}/>
            </> : null}
          </div>


          <div className="header yellow" style={{gridRow: "auto / span 11"}}>항공장애<br/>주간표지</div>
          <div className="header yellow" style={{gridRow: "auto / span 11"}}>주간표지 <br/>종류 택 1</div>

          <div className="field" style={{gridRow: "auto / span 3", gridColumn: "auto / span 2"}}>
            <input type="radio" checked={selectedDeclaration.awsignType === "006"}
                   onChange={() => onClickAwsignType("006")} disabled={disabled}/>
            주간표지 없음(표시등으로 대체)
            <Space/>
          </div>
          <div className="header yellow" style={{gridRow: "auto / span 3"}}>대체</div>
          <div className="field">
            <input type="radio"
                   disabled={selectedDeclaration.awsignType !== "006" || disabled}
                   checked={selectedDeclaration.awsignReplace == "고광도 A로 대체"}
                   onChange={() => onClickAwsignReplace("고광도 A로 대체")}/>
            고광도 A로 대체
          </div>
          <div className="field">
            <input type="radio"
                   disabled={selectedDeclaration.awsignType !== "006" || disabled}
                   checked={selectedDeclaration.awsignReplace == "고광도 B로 대체"}
                   onChange={() => onClickAwsignReplace("고광도 B로 대체")}/>
            고광도 B로 대체
          </div>
          <div className="field">
            <input type="radio"
                   disabled={selectedDeclaration.awsignType !== "006" || disabled}
                   checked={selectedDeclaration.awsignReplace == "중광도 A로 대체"}
                   onChange={() => onClickAwsignReplace("중광도 A로 대체")}/>
            중광도 A로 대체
          </div>

          <div className="field" style={{gridColumn: "auto / span 2"}}>
            <input type="radio" checked={selectedDeclaration.awsignType === "001"}
                   onChange={() => onClickAwsignType("001")} disabled={disabled}/>
            도색
            <Space/>
          </div>
          <div className="header yellow">도색등분</div>
          <div className="field">
            <input className="input" placeholder="예) 7등분, 5/9"
                   disabled={selectedDeclaration.awsignType !== "001" || disabled}
                   value={selectedDeclaration.awsignPaintingDevide}
                   name="awsignPaintingDevide"
                   onChange={onChangeStr}/>
          </div>

          <div className="field" style={{gridRow: "auto / span 3", gridColumn: "auto / span 2"}}>
            <input type="radio" checked={selectedDeclaration.awsignType === "004"}
                   onChange={() => onClickAwsignType("004")} disabled={disabled}/>
            표지구
            <Space/>
          </div>
          <div className="header yellow">적색 개수</div>
          <div className="field">
            <input className="input" name="awsignSignRedNum" type="number"
                   disabled={selectedDeclaration.awsignType !== "004" || disabled}
                   value={selectedDeclaration.awsignSignRedNum ?? ""}
                   onChange={onChangeNumber}/>
          </div>
          <div className="header yellow">백색 개수</div>
          <div className="field">
            <input className="input" name="awsignSignWhiteNum" type="number"
                   disabled={selectedDeclaration.awsignType !== "004" || disabled}
                   value={selectedDeclaration.awsignSignWhiteNum ?? ""}
                   onChange={onChangeNumber}/>

          </div>
          <div className="header yellow">경간</div>
          <div className="field">
            <input className="input" placeholder="미터(m)" name="awsignSignGap" type="number"
                   disabled={selectedDeclaration.awsignType !== "004" || disabled}
                   value={selectedDeclaration.awsignSignGap ?? ""}
                   onChange={onChangeFloat}/>
          </div>

          <div className="field" style={{gridColumn: "auto / span 4"}}>
            <input type="radio" checked={selectedDeclaration.awsignType === "002"}
                   onChange={() => onClickAwsignType("002")} disabled={disabled}/>
            백색
            <Space/>
          </div>

          <div className="field" style={{gridRow: "auto / span 3", gridColumn: "auto / span 2"}}>
            <input type="radio" checked={selectedDeclaration.awsignType === "003"}
                   onChange={() => onClickAwsignType("003")} disabled={disabled}/>
            깃발
            <Space/>
          </div>
          <div className="header yellow">단일색</div>
          <div className="field">
            <input className="input" name="awsignFlagSingleColr"
                   type="number"
                   disabled={selectedDeclaration.awsignType !== "003" || disabled}
                   value={selectedDeclaration.awsignFlagSingleColr ?? ""}
                   onChange={onChangeNumber}/>
          </div>
          <div className="header yellow">체크무늬</div>
          <div className="field">
            <input className="input" name="awsignFlagCheckPattern"
                   type="number"
                   disabled={selectedDeclaration.awsignType !== "003" || disabled}
                   value={selectedDeclaration.awsignFlagCheckPattern ?? ""}
                   onChange={onChangeNumber}/>
          </div>
          <div className="header yellow">백/주황색</div>
          <div className="field">
            <input className="input" name="awsignFlagWhiteOrangeColr"
                   type="number"
                   disabled={selectedDeclaration.awsignType !== "003" || disabled}
                   value={selectedDeclaration.awsignFlagWhiteOrangeColr ?? ""}
                   onChange={onChangeNumber}/>
          </div>

          <div className="header yellow" style={{gridRow: "auto / span 2"}}>관리 <br/>담당자</div>
          <div className="header yellow">성명</div>
          <div className="field" style={{gridColumn: "auto / span 2"}}>
            <input className="input" name="mngrNm"
                   value={selectedDeclaration.mngrNm}
                   onChange={onChangeStr} disabled={disabled}/>
          </div>
          <div className="header yellow">직책</div>
          <div className="field">
            <input className="input" name="mngrJbttl"
                   value={selectedDeclaration.mngrJbttl}
                   onChange={onChangeStr} disabled={disabled}/>
          </div>
          <div className="header yellow">연락처</div>
          <div className="field" style={{gridColumn: "auto / span 2"}}>
            <input className="input" name="mngrTel"
                   value={selectedDeclaration.mngrTel}
                   onChange={onChangeStr} disabled={disabled}/>
          </div>
          <div className="header yellow">팩스</div>
          <div className="field">
            <input className="input" name="mngrFax"
                   value={selectedDeclaration.mngrFax}
                   onChange={onChangeStr} disabled={disabled}/>
          </div>

          <div className="header yellow" style={{gridRow: "auto / span 6"}}>제출서류</div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            1. 표시등 종류, 수량 및 설치위치가 포함된 도면&nbsp;
            <a className="href" onClick={onClickOpenPlanSamplePopup}>[예시]</a>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <FileUploadInput name="instlFlrplnFileId" downloadName="도면"
                             value={selectedDeclaration.instlFlrplnFileId}
                             onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            2. 주간표지 설치도면 (주간표지 미설치 시 첨부 생략 가능)&nbsp;
            <a className="href" onClick={() => onClickOpenImagePopup(sampleImage2)}>[예시]</a>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <FileUploadInput name="awsignFlrplnFileId" downloadName="도면"
                             value={selectedDeclaration.awsignFlrplnFileId}
                             onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            3. 설치사진 (전체적인 위치를 나타내는 사진)&nbsp;
            <a className="href" onClick={() => onClickOpenImagePopup(sampleImage3)}>[예시]</a>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <FileUploadInput name="instlFileId" downloadName="설치사진"
                             value={selectedDeclaration.instlFileId}
                             onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 5"}}>
            4. 최근 2년 이내에 시험한 성적서 (항공장애 표시등의 제품명을 선택하거나 시험성적서 파일첨부)
          </div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            5. 제어반 사진 및 입력 전압, 전류값&nbsp;
            <a className="href" onClick={() => onClickOpenImagePopup(sampleImage5)}>[예시]</a>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <FileUploadInput name="ctlboxFileId" downloadName="사진"
                             value={selectedDeclaration.ctlboxFileId}
                             onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            6. 표시등 및 제어반 부품 내역&nbsp;
            <a className="href" onClick={() => onClickOpenImagePopup(sampleImage6)}>[예시]</a>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <FileUploadInput name="ctlboxCompListFileId" downloadName="부품내역"
                             value={selectedDeclaration.ctlboxCompListFileId}
                             onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
          </div>
        </div>

        <Sign name={complaint.signerNm} fileId={complaint.signFileId}
              onChangeSignFile={onChangeSignFile} onChangeName={onChangeSignerName}
              disabled={disabled}
              text="「공항시설법」 제36조제5항 및 같은 법 시행규칙 제29조제1항에 따라 위와 같이 신고합니다."
              signDate={signDate}
        />

        <div className="flex mt">
          <Space/>
          {editMode == "edit" ? <>
            <button className="button mr" onClick={onClickSaveEdit} disabled={isProgress}>민원수정</button>
            <button className="button" onClick={onClickCancel} disabled={isProgress}
            >민원취하</button>
          </> : null}
          {editMode == "create" ? <>
            <button className="button mr" onClick={onClickSaveTemp}>임시저장</button>
            <button className="button" onClick={onClickSave} disabled={isProgress}>민원신청</button>
          </> : null}
        </div>
      </div>
      <TempLoaderModal isOpen={isTempLoaderOpen} onClose={onCloseTempLoader}
                       tempItems={tempComplaint}
                       type="설치"
                       onLoad={onLoadTemp} onDelete={onDeleteTemp}/>
      <ImagePopup isOpen={isOpenImagePopup} onClose={onCloseImagePopup}
                  imgSrc={imagePopupSrc}/>
      <PlanSamplePopup isOpen={isOpenPlanSamplePopup} onClose={onClosePlanSamplePopup}/>
      <ConfirmPopup confirmPopupProps={popupProps}/>
    </>
  )
}

