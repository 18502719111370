import React, {useEffect, useState} from 'react';
import "./Login.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {parseErrorMessage} from "../../util/errorParser";
import {parseJWT} from "../../util/loginManager";

export const Login = () => {
  const navigate = useNavigate();
  const [loginReq, setLoginReq] = useState<LoginReq>({account: "", password: ""});
  const [showAdminPageButton, setShowAdminPageButton] = useState(false);
  
  useEffect(() => {
    const accessToken = parseJWT();
    if(accessToken.user !== undefined) navigate("/")
  },[]);
  
  const onChangeAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginReq({...loginReq, account: e.target.value});
  }
  
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginReq({...loginReq, password: e.target.value});
  }

  const onClickLogin = async () => {
    try {
      await axios.post("/auth", loginReq)
      navigate("/");
    }catch (e: any) {
      if(e.response.data.code === "OTP_NOT_REGISTERED" || e.response.data.code === "OTP_VERIFY_FAILED") {
        setShowAdminPageButton(true)
      }else {
        alert(parseErrorMessage(e));
      }
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === "Enter") {
      onClickLogin();
    }
  }
  return (
    <div className="Login" onKeyDown={onKeyDown}>
      <PageTitle title="로그인"/>
      <div className="login-container">
        <div className="login-title">항공장애표시등 민원서비스 로그인</div>
        {!showAdminPageButton ?<>
        <input className="input mb full-width" placeholder="아이디"
               onChange={onChangeAccount} value={loginReq.account}/>
        <br/>
        <input className="input mb full-width" placeholder="비밀번호"  type="password"
               onChange={onChangePassword} value={loginReq.password}/>
        <br/>
        <button className="button full-width" onClick={onClickLogin}>로그인</button>
        <div className="bottom-line">
          <button className="button-outline full-width" style={{marginRight:"20px"}} 
                  onClick={()=>navigate("/user/find-password")}>비밀번호 찾기</button>
          <button className="button-outline full-width" 
                  onClick={()=>navigate("/user/join")}>회원가입</button>
        </div>
        </>:
        <>
          <div className="otp-description">
              OTP 인증이 필요한 계정입니다.<br/> 관리자페이지에서 로그인하고 OTP 인증을 진행해주세요.
          </div>
          <button className="button full-width"
                  onClick={() => window.location.href = "/admin"}>
              관리자페이지로 가기
          </button>
        </>
        }
      </div>
    </div>
  )
}

