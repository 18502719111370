import React from 'react';
import "./Introduce.scss"
import {Route, Routes, useLocation} from "react-router-dom";
import {Map} from "./Introduce/Map";
import {BreadCrumb} from "../comp-shared/BreadCrumb";
import {LeftNaviagtor} from "../comp-shared/LeftNaviagtor";
import {LeftNavigatorItem} from "../comp-shared/LeftNavigator/LeftNavigatorItem";
import {Policy} from "./Introduce/Policy";
import {EmailGathering} from "./Introduce/EmailGathering";
import {Privacy} from "./Introduce/Privacy";
import {InquiryBox} from "../comp-shared/InquiryBox";

export const Introduce = () => {
  const location = useLocation();
  const getBreadcrumbPath = () => {
    if (location.pathname.includes("map")) return ["안내","찾아오시는 길"];
    if (location.pathname.includes("policy")) return ["안내","이용약관"];
    if (location.pathname.includes("emailGathering")) return ["안내","이메일주소 무단 수집 거부"];
    if (location.pathname.includes("privacy")) return ["안내","개인정보처리방침"];
    else return []
  };
  
  return (
    <div className="Introduce">
      <BreadCrumb path={getBreadcrumbPath()}/>
      <div className="container">
        <div className="container-inbox">
          <div>
            <LeftNaviagtor title="안내">
              <LeftNavigatorItem path="/introduce/map"> 찾아오시는 길 </LeftNavigatorItem>
              <LeftNavigatorItem path="/introduce/policy"> 이용약관 </LeftNavigatorItem>
              <LeftNavigatorItem path="/introduce/emailGathering"> 이메일주소 무단 수집 거부</LeftNavigatorItem>
              <LeftNavigatorItem path="/introduce/privacy"> 개인정보처리방침 </LeftNavigatorItem>
            </LeftNaviagtor>
            <InquiryBox />
          </div>
          <Routes>
            <Route path="map" element={<Map/>}/>
            <Route path="policy" element={<Policy/>}/>
            <Route path="emailGathering" element={<EmailGathering/>}/>
            <Route path="privacy" element={<Privacy/>}/>
            <Route path="*" element={<Map/>}/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

