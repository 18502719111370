import {Route, Routes, useLocation} from "react-router-dom";
import {BreadCrumb} from "../comp-shared/BreadCrumb";
import {LeftNaviagtor} from "../comp-shared/LeftNaviagtor";
import {LeftNavigatorItem} from "../comp-shared/LeftNavigator/LeftNavigatorItem";
import React from "react";
import {Install} from "./Estimate/Install";
import {Exemption} from "./Estimate/Exemption";
import "./Estimate.scss"
import {InquiryBox} from "../comp-shared/InquiryBox";

export const Estimate = () => {
  const location = useLocation();
  const getBreadcrumbPath = () => {
    if (location.pathname.includes("install")) return ["설치/면제 판단서비스","설치대상 판단서비스"];
    if (location.pathname.includes("exemption")) return ["설치/면제 판단서비스","면제대상 판단서비스"];
    else return []
  };

  return (
    <div className="Estimate">
      <BreadCrumb path={getBreadcrumbPath()}/>
      <div className="container">
        <div className="container-inbox">
          <div>
            <LeftNaviagtor title="안내">
              <LeftNavigatorItem path="/estimate/install"> 설치대상 판단서비스 </LeftNavigatorItem>
              <LeftNavigatorItem path="/estimate/exemption"> 면제대상 판단서비스 </LeftNavigatorItem>
            </LeftNaviagtor>
            <InquiryBox />
          </div>
          <Routes>
            <Route path="install" element={<Install/>}/>
            <Route path="exemption" element={<Exemption/>}/>
            <Route path="*" element={<Install/>}/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

