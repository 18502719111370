import React, {useEffect, useState} from 'react';
import "./FindPassword.scss"
import {parseJWT} from "../../util/loginManager";
import {useNavigate} from "react-router-dom";
import {PageTitle} from "../../comp-shared/PageTitle";
import {FindPasswordReq} from "../../server/user/findPasswordDto";
import axios from "axios";
import {parseErrorMessage} from "../../util/errorParser";

export const FindPassword = () => {
  const navigate = useNavigate();
  const [req, setReq] = useState<FindPasswordReq>({acnt:"",eml: ""});

  useEffect(() => {
    const accessToken = parseJWT();
    if (accessToken.user != undefined) navigate("/")
  });
  
  const onChangeAcnt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReq({...req, acnt: e.target.value});
  }
  
  const onChangeEml = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReq({...req, eml: e.target.value});
  }
  
  const onClickFindPassword = async () => {
    try {
      await axios.post("/user/find-password", req)
      alert("임시 비밀번호가 입력하신 이메일로 전송되었습니다.")
    }catch (e) {
      alert(parseErrorMessage(e));
    }
  }
  
  return (
    <div className="FindPassword">
      <PageTitle title="비밀번호 찾기"/>
      <div className="large-form-container">
        <div className="large-form-title">
          입력한 회원 정보의 이메일로 임시 비밀번호가 발송됩니다.
        </div>
        <div className="large-form-content">
          <div className="field-title">아이디</div>
          <div className="field">
            <input className="input mb full-width" value={req.acnt} onChange={onChangeAcnt}
                   placeholder="아이디"/>
          </div>
          <div className="field-title">이메일</div>
          <div className="field">
            <input className="input mb full-width" value={req.eml} onChange={onChangeEml} 
                   placeholder="이메일"/>
          </div>
        </div>
      </div>
      <div className="button-container">
        <button className="button" onClick={onClickFindPassword}>비밀번호 찾기</button>
      </div>
    </div>
  )
}

