import React, {ChangeEvent, useEffect, useState} from 'react';
import "./DemolishComplaint.scss"
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSWRProductTestReport} from "../../server/server";
import {AwSignCode, AwSignReplaceCode, ComplaintEditMode} from "../../server/code";
import {useImmer} from "use-immer";
import {useLocalStorage} from "usehooks-ts";
import {TempItem, TempLoaderModal} from "../../comp-shared/Complaint/TempLoaderModal";
import {checkLoginAndRedirect} from "../../util/loginManager";
import axios from "axios";
import {GetChangeComplaintRes, GetDemolishComplaintRes} from "../../server/user/myComplaintDto";
import {parseErrorMessage} from "../../util/errorParser";
import {documentAccept, FileUploadEvent, FileUploadInput} from "../../comp-shared/FileUploadInput";
import {GetMngCardRes} from "../../server/complaint/mngCardDto";
import {ComplaintBase} from "../../server/complaint/complaintBaseDto";
import {PageTitle} from "../../comp-shared/PageTitle";
import {TabSelector} from "../../comp-shared/TabSelector";
import {MngCardLoader} from "../../comp-shared/Complaint/MngCardLoader";
import {ObstacleAddress} from "../../comp-shared/Complaint/ObstacleAddress";
import {DeclarationBasicInfo} from "../../comp-shared/Complaint/DeclarationBasicInfo";
import {Sign} from "../../comp-shared/Complaint/Sign";
import {Space} from "../../comp-shared/Space";
import {ImagePopup} from "../../comp-shared/Popup/ImagePopup";
import {
  getDefaultDemolishDeclaration, getDefaultPostDemolishComplaintReq,
  PostDemolishComplaintReq
} from "../../server/complaint/demolishComplaintDto";
import sampleImage7 from "../../resources/sampleimages/7_sample.png";
import ConfirmPopup, {useConfirmPopup} from "../../comp-shared/Popup/ConfirmPopup";
import moment from "moment/moment";

export const DemolishComplaint = () => {
  const {popupProps,openPopup} = useConfirmPopup();
  const navigate = useNavigate();
  const params = useParams();

  const [isTempLoaderOpen, setTempLoaderOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<ComplaintEditMode>(
    params.hasOwnProperty("id") ? "edit" : "create");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [complaint, updateComplaint] = useImmer<PostDemolishComplaintReq>(
    getDefaultPostDemolishComplaintReq());
  const [signDate, setSignDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const selectedDeclaration = complaint.declaration[selectedPage - 1];

  const [tempComplaint, setTempComplaint] = useLocalStorage<TempItem[]>(
    "tempDemolishComplaint", []);
  const [loadedFromTemp, setLoadedFromTemp] = useState<number | null>(null);

  const [isOpenImagePopup, setIsOpenImagePopup] = useState<boolean>(false);
  const [imagePopupSrc, setImagePopupSrc] = useState<string>("");
  const [isProgress, setIsProgress] = useState<boolean>(false);

  const disabled = editMode === "readonly";

  useEffect(() => {
    checkLoginAndRedirect(navigate)
    init()
  }, []);

  const init = async () => {
    try {
      if (params.hasOwnProperty("id")) {
        let res = await axios.get<GetDemolishComplaintRes>("/user/my-complaint/demolish/" + params.id);
        updateComplaint(res.data)
        setSignDate(res.data.chgDt)
        if (res.data.stts !== "S020100000" && !(res.data.stts === "S020200000" && res.data.mdfcnPrm))
          setEditMode("readonly")

      }
    } catch (e) {
      alert("민원을 불러오지 못했습니다. " + parseErrorMessage(e));
      navigate("/user/my-complaint")
    }
  }

  const onChangeStr = (e: ChangeEvent<HTMLInputElement>) =>
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1][e.target.name] = e.target.value
    })
  const onChangeFile = async (e: FileUploadEvent) => {
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1][e.name] = e.fileId
    })
  };

  const onClickAdd = () => {
    updateComplaint(draft => {
      draft.declaration.push(getDefaultDemolishDeclaration());
      setSelectedPage(draft.declaration.length);
    });
  };

  const onClickDelete = () => {
    if (selectedPage > 1) {
      updateComplaint(draft => {
        draft.declaration = draft.declaration.filter((_, i) => i !== selectedPage - 1);
      });
      setSelectedPage(selectedPage - 1);
    } else {
      alert("첫번째 신고서는 삭제할 수 없습니다.")
    }
  };

  const onClickSave = async () => {
    try {
      await openPopup("확인", "여러장의 신고서를 제출하시는 경우, " +
        "민원신고를 여러번 진행하지 마시고\n신고서 추가 기능을 통하여 한번에 제출하시기 바랍니다." +
        "\n\n위 내용을 확인하셨습니까?", "confirm")
    } catch (e) {
      return;
    }
    try {
      setIsProgress(true)
      await axios.post("/complaint/demolish", complaint)
      alert("민원 신청이 완료되었습니다.")
      // 신청 완료되면 해당 임시저장 삭제
      if (loadedFromTemp !== null)
        setTempComplaint(tempComplaint.filter(temp => temp.id !== loadedFromTemp));
      navigate("/")
    } catch (e) {
      alert(parseErrorMessage(e));
    } finally {
      setIsProgress(false)
    }
  };
  const onClickSaveEdit = async () => {
    if (!window.confirm("민원을 수정하시겠습니까?")) return;
    try {
      setIsProgress(true)
      await axios.post(`/complaint/demolish/edit/${params.id}`, complaint)
      alert("민원 수정이 완료되었습니다.")
      navigate("/user/my-complaint")
    } catch (e) {
      alert(parseErrorMessage(e));
    } finally {
      setIsProgress(false)
    }
  };
  const onClickCancel = async () => {
    if (!window.confirm("민원을 취하하시겠습니까?")) return;
    try {
      setIsProgress(true)
      await axios.post(`/complaint/remove/${params.id}`)
      alert("민원이 취하되었습니다.")
      navigate("/user/my-complaint")
    } catch (e) {
      alert(parseErrorMessage(e));
    } finally {
      setIsProgress(false)
    }
  };

  const onChangeSignerName = (name: string) => updateComplaint(draft => {
    draft.signerNm = name
  });
  const onChangeSignFile = (fileId: number | null) => updateComplaint(draft => {
    draft.signFileId = fileId
  });

  const onCloseTempLoader = () => setTempLoaderOpen(false);
  const onClickOpenTempLoader = () => setTempLoaderOpen(true);

  const onCloseImagePopup = () => setIsOpenImagePopup(false);

  const onLoadTemp = (tempItem: TempItem) => {
    setLoadedFromTemp(tempItem.id);
    updateComplaint(tempItem.complaint);
  }

  const onDeleteTemp = (id: number) => {
    if (!window.confirm("임시저장을 삭제하시겠습니까?")) return;
    setTempComplaint(tempComplaint.filter(temp => temp.id !== id))
  };
  const onClickSaveTemp = () => {
    if (!window.confirm("임시저장 하시겠습니까?")) return;
    let id = 1;
    if (tempComplaint.length > 0) id = Math.max(...tempComplaint.map(value => value.id)) + 1

    setTempComplaint([{
      id: id,
      createdAt: new Date().toLocaleString(),
      complaint: complaint
    }, ...tempComplaint,]);
    alert("저장된 내역은 신고서 상단의 임시상단 불러오기를 클릭해 불러오실 수 있습니다.");
  }

  const onLoadMngCard = (mngCard: GetMngCardRes) => {
    updateComplaint(draft => {
      draft.declaration[selectedPage - 1] = {
        ...draft.declaration[selectedPage - 1], ...mngCard,
        id: null, mngCardId: mngCard.id,mngCardNo:mngCard.mngNo
      };
    })
  };

  const onClickOpenImagePopup = (imgSrc: string) => {
    setIsOpenImagePopup(true);
    setImagePopupSrc(imgSrc);
  };
  const updateDeclaration = (callback: (complaint: ComplaintBase) => void) => {
    updateComplaint(draft => {
      callback(draft.declaration[selectedPage - 1]);
    });
  }

  return (<>
    <div className="DemolishComplaint">
      <PageTitle title={editMode === "create" ? "철거신고" : "나의 민원 상세보기"}/>
      {editMode !== "readonly" ?
        <button className="button" style={{marginRight:"10px"}} onClick={onClickAdd}>신고서 추가</button>:null}

      {editMode === "create" ? <>
        <button className="button" style={{marginRight:"10px"}} onClick={onClickOpenTempLoader}>임시저장 불러오기</button>
      </>:null}
      <div>2024년 4월 18일부터 철거신고 또한 설치, 수정신고와 마찬가지로 여러장의 신고서를 제출하는 경우 신고서 추가기능을 이용하여 한번에 제출해주셔야 합니다.</div>
      <TabSelector selectedPage={selectedPage} setSelectedPage={setSelectedPage}
                   data={complaint.declaration}/>
      {editMode !== "readonly" ?
        <div style={{display:"flex",marginTop:"10px", marginBottom:"10px"}}>
          <MngCardLoader onLoad={onLoadMngCard}/>
          <button className="button" onClick={onClickDelete}>신고서 삭제</button>
        </div>:null}


      {selectedDeclaration.mngCardId != null ?<>
        <ObstacleAddress selectedDeclaration={selectedDeclaration}
                         updateDeclaration={updateDeclaration}
                         editMode="readonly"/>

        <DeclarationBasicInfo declarationName="철거신고서"
                              selectedPage={selectedPage}
                              selectedDeclaration={selectedDeclaration}
                              updateDeclaration={updateDeclaration}
                              editMode={editMode}/>
        <div className="declaration-grid middle-info-box">
          <div className="header green" style={{gridColumn:"auto / span 4"}}>철거내용</div>
          <div className="header green">철거연월일</div>
          <div className="field">
            <input className="input" type="date" name="dmltnYmd"
                   value={selectedDeclaration.dmltnYmd ?? ""}
                   onChange={onChangeStr} disabled={disabled}/>
          </div>
          <div className="field" style={{gridColumn:"auto /span 6"}}>
            철거한 날로부터 15일 이내에 신고하지 않은 경우 과태료가 부과됩니다. 철거연월일을 다시 확인하시기 바랍니다.
          </div>
          <div className="header green">제출서류</div>
          <div className="field left" style={{gridColumn:"auto / span 3"}}>
            <div>
            1. 항공장애 표시등 또는 항공장애 주간표지 철거사진<br/>(전체적 위치를 나타내는 것)&nbsp;
            <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage7)}>[예시]</a>
            </div>
          </div>
          <div className="field left" style={{gridColumn:"auto / span 2"}}>
            <FileUploadInput name="dmltnFileId" downloadName="사진"
                             value={selectedDeclaration.dmltnFileId}
                             onChange={onChangeFile} disabled={disabled} accept={documentAccept}/>
          </div>
        </div>
      </>:null}
      {complaint.declaration.some(value => value.mngCardId != null) ? <>
        <Sign name={complaint.signerNm} fileId={complaint.signFileId}
              onChangeSignFile={onChangeSignFile} onChangeName={onChangeSignerName}
              disabled={disabled}
              text="「공항시설법」 제36조제6항 및 같은 법 시행규칙 제29조제2항에 따라 위와 같이 신고합니다."
              signDate={signDate} />
        <div className="flex mt">
          <Space/>
          {editMode == "edit" ? <>
            <button className="button mr" onClick={onClickSaveEdit} disabled={isProgress}>민원수정</button>
            <button className="button" onClick={onClickCancel} disabled={isProgress}>민원취하</button>
          </>:null}
          {editMode == "create" ? <>
            <button className="button mr" onClick={onClickSaveTemp}>임시저장</button>
            <button className="button" onClick={onClickSave} disabled={isProgress}>민원신청</button>
          </>:null}
        </div>
      </>:null}
    </div>
    <TempLoaderModal isOpen={isTempLoaderOpen} onClose={onCloseTempLoader} tempItems={tempComplaint}
                     type="철거" onLoad={onLoadTemp} onDelete={onDeleteTemp}/>
    <ImagePopup isOpen={isOpenImagePopup} onClose={onCloseImagePopup}
                imgSrc={imagePopupSrc}/>
      <ConfirmPopup confirmPopupProps={popupProps}/>
    </>
  )
}

