import './BrokenStatusInfo.scss'

interface Props {
  mngNo: string;
  isCycleEnd: boolean;
  isRestored: boolean;
  isOnRestore: boolean;
  type: "Rebroken" | "Restore";
}
export default function BrokenStatusInfo(props: Props) {
  if (props.type === "Restore" && !props.isOnRestore && props.isCycleEnd) return null;

  return props.isOnRestore || props.isCycleEnd ? <div className='BrokenStatusInfo mb'>
    <div className='yellow'>관리번호</div>
    <div>{props.mngNo}</div>
    <div className='yellow' style={{gridColumn: 'auto / span 2'}}>
      {props.isRestored && "복구완료"}
      {!props.isRestored && props.isOnRestore && "복구완료 승인 대기"}
      {!props.isOnRestore && props.isCycleEnd && "고장 재통지는 5차 통지까지 가능합니다."}
    </div>
  </div> : null
}