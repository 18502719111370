import React from 'react';
import "./ImagePopup.scss"
import PopupPanel from "./PopupPanel";

interface Props{
  isOpen:boolean;
  onClose:()=>void;
  imgSrc?:string;
}

export const ImagePopup = (props:Props) => {
  return (
    <PopupPanel isOpen={props.isOpen} title="이미지" onClose={props.onClose}>
      <div className="ImagePopup">
        <img src={props.imgSrc}/>
      </div>
    </PopupPanel>
  )
}

