import React from 'react';
import "./BreadCrumb.scss"
import homeIcon from "../resources/images/home.svg"
import rightArrowIcon from "../resources/images/right_arrow_breadcrumb.svg"

interface Props{
  path:string[];
}

export const BreadCrumb = (props:Props) => {
  return (
    <div className="BreadCrumb container">
      <div className="container-inbox">
        <img src={homeIcon}/>
        <img src={rightArrowIcon} className="arrow-icon"/> 
        {props.path.map((item,index)=> {
          return <React.Fragment key={item}>
            <div className="title"> {item} </div>
            {index !== props.path.length - 1 ? 
              <img src={rightArrowIcon} className="arrow-icon" />:null }
            </React.Fragment>
        })}
      </div>
    </div>
  )
}

