import React from 'react';
import "./LeftNavigatorItem.scss"
import {useLocation, useNavigate} from "react-router-dom";
import clsx from "clsx";
import * as path from "path";
interface Props{

  path:string;
  children:string;
}

export const LeftNavigatorItem = (props:Props) => {
  const url = useLocation();
  const navigate = useNavigate();
  
  return (
    <button className={clsx("LeftNavigatorItem",{"selected":url.pathname == props.path || url.pathname.includes(props.path)})}
            onClick={()=>navigate(props.path)}>
      <div className="title"> {props.children} </div>
    </button>
  )
}

