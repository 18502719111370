import React, {useState} from 'react';
import "./Main.scss"
import {Space} from "../comp-shared/Space";
import airplainIcon from "../resources/images/nav_airplane.svg";
import changeIcon from "../resources/images/nav_complain_change2.svg";
import demolishIcon from "../resources/images/nav_complain_demolish.svg";
import brokenIcon from "../resources/images/nav_complain_broken.svg";
import refreshIcon from "../resources/images/nav_refresh.svg";
import checkIcon from "../resources/images/nav_check_circle.svg";
import filghtTakeoffIcon from "../resources/images/flight_takeoff.svg";
import gavelIcon from "../resources/images/nav_gavel.svg";
import Govlogo from "../resources/images/logo.png";
import TsLogo from "../resources/images/TS_logo.png";
import RightArrowCircleIcon from "../resources/images/right_arrow_circle.svg";
import {BrokenListPopup} from "../comp-shared/Popup/BrokenListPopup";
import {useSWRNtcList} from "../server/server";
import moment from "moment";
import {Link, useNavigate} from "react-router-dom";
import {GetNtcRes} from "../server/ntc/ntcDto";
export const Main = () => {
  const navigate = useNavigate();
  const [isOpenBrokenPopup,setOpenBrokenPopup] = useState<boolean>(true);
  const {data:ntcList} = useSWRNtcList(0,5);

  const onClickNtc = (ntc: GetNtcRes) => {
    navigate(`/notice/detail?id=${ntc.id}`);
  };
  return (
    <>
    <div className="Main">
      <div className="top-panel container">
        <div className="container-inbox">
          <div className="notice">
            <div className="notice-top">
              <div className="notice-title">공지사항</div>
              <Space/>
              <button className="notice-add-button"
                      onClick={()=>navigate('/notice/list')}>+</button>
            </div>
            <div className="notice-box">
              {ntcList?.content.map((ntc) => <div className="notice-box-item" key={ntc.id}>
                <div onClick={() => onClickNtc(ntc)} className="notice-box-item-title">{ntc.ttl}</div>
                <div>{moment(ntc.regDt).format("yyyy-MM-DD")}</div>
              </div>)}
            </div>
          </div>
        </div>
      </div>
      <div className="info-panel container">
        <div className="container-inbox">
          <div className="top-info-box" style={{backgroundColor:"rgb(18,142,108)",gridRow:"span 2"}}
               onClick={()=>navigate('complaint/install')}>
            <div className="triangle"/>
            <img className="bottom-icon" src={airplainIcon}/>
            <div className="info-box-title">설치신고</div>
            <div className="info-text">설치한 날부터 15일 이내 하여야 하며,</div>
            <div className="info-text">설치신고를 하지 아니한 자에게는</div>
            <div className="info-text">200만원의 과태료 부과</div>
          </div>
          <div className="top-info-box" style={{backgroundColor:"rgb(217,106,24)",gridRow:"span 2"}}
            onClick={()=>navigate('complaint/change')}>
            <div className="triangle"/>
            <img className="bottom-icon" src={changeIcon}/>
            <div className="info-box-title">변경신고</div>
            <div className="info-text">변경한 날부터 15일 이내 하여야 하며,</div>
            <div className="info-text">변경신고를 하지 아니한 자에게는</div>
            <div className="info-text">200만원의 과태료 부과</div>
          </div>
          <div className="top-info-box" style={{backgroundColor:"rgb(58,118,176)",gridRow:"span 2"}}
            onClick={()=>navigate('complaint/demolish')}>
            <div className="triangle"/>
            <img className="bottom-icon" src={demolishIcon}/>
            <div className="info-box-title">철거신고</div>
            <div className="info-text">철거한 날부터 15일 이내 하여야 하며,</div>
            <div className="info-text">철거신고를 하지 아니한 자에게는</div>
            <div className="info-text">200만원의 과태료 부과</div>
          </div>
          <div className="top-info-box" style={{backgroundColor:"rgb(70,88,188)"}}
            onClick={()=>navigate('broken/broken')}>
            <div className="triangle"/>
            <img className="bottom-icon" src={brokenIcon}/>
            <div className="info-box-title">고장통지</div>
          </div>
          <div className="top-info-box" style={{backgroundColor:"rgb(70,88,188)"}}
            onClick={()=>navigate('broken/rebroken')}>
            <div className="triangle"/>
            <img className="bottom-icon" src={refreshIcon}/>
            <div className="info-box-title">재통지</div>
          </div>
          <div className="top-info-box" style={{backgroundColor:"rgb(70,88,188)"}}
            onClick={()=>navigate('broken/restore')}>
            <div className="triangle"/>
            <img className="bottom-icon" src={checkIcon}/>
            <div className="info-box-title">복구완료통지</div>
          </div>
          <div className="top-info-box non-clickable" style={{backgroundColor:"rgb(70,88,188)",gridColumn:"span 3", paddingTop:"10px"}}>
            <div className="info-text">장애가 발생하여 복구가 7일 이상 소요될 것으로 예상되는 경우 고장통지하여야 하며,</div>
            <div className="info-text">고장통지를 아니한 자는 표시등 및 표지를 관리하지 아니한 자로 500만원 이하의 과태료가 부과될 수 있음</div>
          </div>
          <div className="bottom-info-box"
            onClick={()=>navigate('estimate/install')}>
            <div className="triangle"/>
            <div className="half-circle">
              <img className="top-icon" src={filghtTakeoffIcon}/>
            </div>
            <div className="info-box-title" style={{marginTop:"60px"}}>
              설치대상 판단 서비스
            </div>
            <div className="info-text">항공장애표시등에 대한 <br className="pc-only"/>
            설치 대상 여부, 종류, <br className="pc-only"/>
            수량 배치 판단을 미리 <br className="pc-only"/>
            확인하실 수 있습니다.</div>
          </div>
          <div className="bottom-info-box"
            onClick={()=>navigate('estimate/exemption')}>
            <div className="triangle"/>
            <div className="half-circle">
              <img className="top-icon" src={gavelIcon}/>
            </div>
            <div className="info-box-title" style={{marginTop:"60px"}}>
              설치면제 판단 서비스
            </div>
            <div className="info-text">항공장애표시등에 대한 <br className="pc-only"/>
            설치면제대상 판단을 <br className="pc-only"/>
            도와드립니다.</div>
          </div>
          <div className="bottom-info-box"
            onClick={()=>navigate('help/complaint_example')}>
            <div className="triangle"/>
            <div className="info-box-title pc-only" style={{marginTop:"20px", marginBottom:"10px"}}>
              신고서 작성전
            </div>
            <div className="info-box-title pc-only" style={{textAlign:"right",marginRight:"10px", marginBottom:"35px"}}>
              준비사항
            </div>
            <div className="info-box-title mobile-only">
              신고서 작성전 준비사항
            </div>
            <div className="info-text">* 신고서 작성 예시</div>
            <div className="info-text">* 장애물제한구역 확인방법</div>
            <div className="info-text">* 제출서류 예시</div>
            <div className="info-text">* 서명파일 예시 (등록방법)</div>
          </div>
          <div className="bottom-info-box  non-clickable" style={{gridColumn:"span 2"}}>
            <div className="triangle"/>
            <div className="half-circle">
              <img className="top-icon" src={gavelIcon}/>
            </div>
            <div className="info-box-title" style={{marginTop:"20px", textAlign:"left", marginLeft:"50px"}}>
              고객문의 전화
            </div>
            <div className="info-text">- 항공장애표시등 민원 관련 문의</div>
            <div className="info-text">* 서울지방항공청: - 서울 (032-740-2280)</div>
            <div className="info-text">&nbsp;- 경기·충청 (032-740-2233)</div>
            <div className="info-text">&nbsp;- 인천·강원·전북 (032-740-2237)</div>
            <div className="info-text">* 부산지방항공청: - 부산·경북·경남 (051-974-2173)</div>
            <div className="info-text">&nbsp;- 전남 (051-974-2191)</div>
            <div className="info-text">* 제주지방항공청: 064-797-1789</div>
            <div className="info-text">- 시스템 관련 문의: 042-824-6340</div>
          </div>
          <div className="bottom-info-box pc-only" style={{backgroundColor:"#3A76B0", padding:"13px"}}
               onClick={()=> window.location.href = "https://kotsa.or.kr/alims/"}>
            <img src={Govlogo} style={{width:"100%"}}/>
            <img src={TsLogo} style={{width:"90%",paddingLeft:"7%"}}/>
            <div className="self-management-text">항공장애표시등</div>
            <div className="self-management-text">자체점검</div>
            <div className="self-management-text">관리시스템</div>
            <div className="self-management-text">바로가기</div>
            <img className="self-management-arrow-icon" src={RightArrowCircleIcon}/>
          </div>
        </div>
      </div>
    </div>
      <BrokenListPopup isOpen={isOpenBrokenPopup} onClose={()=>setOpenBrokenPopup(false)}/>
    </>
  )
}

