import React, {useEffect, useState} from 'react';
import "./MyInfo.scss"
import {useNavigate} from "react-router-dom";
import {checkLoginAndRedirect} from "../../util/loginManager";
import {Space} from "../../comp-shared/Space";
import {PageTitle} from "../../comp-shared/PageTitle";
import {Address, useDaumPostcodePopup} from "react-daum-postcode";
import axios from 'axios';
import {GetMyInfoRes, UpdateMyInfoReq, UpdateMyPasswordReq} from "../../server/user/myInfoDto";
import {initCache} from "swr/dist/utils/cache";
import {requestURL} from "../../config";
import {parseErrorMessage} from "../../util/errorParser";

export const MyInfo = () => {
  const navigate = useNavigate();
  const isOpenAddressPopup = useDaumPostcodePopup();
  const [updateMyInfoReq, setUpdateMyInfoReq] = useState<UpdateMyInfoReq>({
    nm:"", coNm:"", zip:null, addr:"", brno:null
  })
  
  const [updateMyPasswordReq, setUpdateMyPasswordReq] = useState<UpdateMyPasswordReq>({
    oldPassword:"", newPassword:"", newPasswordConfirm:""
  })

  useEffect(() => {
    checkLoginAndRedirect(navigate)
    init()
  },[]);
  
  const init = async () => {
    try {
      let res = await axios.get<GetMyInfoRes>(`${requestURL}/user/my-info`)
      setUpdateMyInfoReq(res.data)
    }catch (e){
      alert("회원정보를 가져오는데 실패했습니다.")
      navigate("/user/my-page")
    }
  }

  const onSelectAddress = (address:Address) => {
    setUpdateMyInfoReq({...updateMyInfoReq,zip:parseInt(address.zonecode),addr:address.address})
  }

  const onClickAddress = () => {
    isOpenAddressPopup({onComplete:onSelectAddress});
  };

  const onChangeAddress = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUpdateMyInfoReq({...updateMyInfoReq, addr: e.target.value});
  };
  
  const onChangeNm = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUpdateMyInfoReq({...updateMyInfoReq, nm: e.target.value});
  };
  
  const onChangeCoNm = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUpdateMyInfoReq({...updateMyInfoReq, coNm: e.target.value});
  };
  
  const onChangeBrNo = (e:React.ChangeEvent<HTMLInputElement>) => {
      setUpdateMyInfoReq({...updateMyInfoReq, brno: e.target.value === "" ? null : e.target.value});
  };
  const  onClickChangeMyInfo = async () => {
    try {
      await axios.post(`${requestURL}/user/my-info/edit`, updateMyInfoReq)
      alert("회원정보가 변경되었습니다.")
      navigate("/user/my-page")
    }catch (e){
      alert(parseErrorMessage(e))
    }
  };
  
  const onChangeOldPassword = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUpdateMyPasswordReq({...updateMyPasswordReq, oldPassword: e.target.value});
  };
  
  const onChangeNewPassword = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUpdateMyPasswordReq({...updateMyPasswordReq, newPassword: e.target.value});
  };
  
  const onChangeNewPasswordConfirm = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUpdateMyPasswordReq({...updateMyPasswordReq, newPasswordConfirm: e.target.value});
  };
  
  const onClickChangePassword = async () => {
    if (updateMyPasswordReq.newPassword !== updateMyPasswordReq.newPasswordConfirm){
      alert("신규 비밀번호가 일치하지 않습니다.")
      return;
    }
    try {
      await axios.post(`${requestURL}/user/my-password/edit`, updateMyPasswordReq)
      alert("비밀번호가 변경되었습니다.")
      navigate("/user/my-page")
    }catch (e){
      alert(parseErrorMessage(e))
    }
  }
  const onKeyDownMyInfo = (e:React.KeyboardEvent) => {
    if (e.key !== "Enter") return;
    onClickChangeMyInfo()
  };
  const onKeyDownMyPassword = (e:React.KeyboardEvent) => {
    if (e.key !== "Enter") return;
    onClickChangePassword()
  };
  return (
    <>
      <div className="MyInfo">
        <PageTitle title="회원정보"/>
        <div className="large-form-container" onKeyDown={onKeyDownMyInfo}>
          <div className="large-form-title">기본정보 변경</div>
          <div className="large-form-content">
            <div className="field-title">이름</div>
            <div className="field">
              <input className="input mb full-width" value={updateMyInfoReq.nm} 
                     onChange={onChangeNm} />
            </div>
            <div className="field-title">법인명</div>
            <div className="field">
              <input className="input mb full-width" value={updateMyInfoReq.coNm ?? undefined}
                     onChange={onChangeCoNm}/>
            </div>
            <div className="field-title">우편번호</div>
            <div className="field">
              <input className="input mb" placeholder="우편번호" readOnly={true}
                     onClick={onClickAddress}
                     value={updateMyInfoReq.zip ?? undefined}
                     onChange={()=>{}}
                     style={{width:"100px", marginRight:"10px"}}/>
              <input className="input mb full-width" placeholder="상세주소"
                     value={updateMyInfoReq.addr ?? undefined} onChange={onChangeAddress}/>
            </div>
            <div className="field-title">사업자번호</div>
            <div className="field">
              <input className="input mb full-width" value={updateMyInfoReq.brno ?? undefined}
                     onChange={onChangeBrNo}/>
            </div>
          </div>
        </div>
        <div className="bottom-bar">
          <Space/>
          <button className="button" onClick={onClickChangeMyInfo}>기본정보 변경</button>
        </div>

        <div className="line"/>

        <div className="large-form-container" onKeyDown={onKeyDownMyPassword}>
          <div className="large-form-title">비밀번호 변경</div>
          <div className="large-form-content">
            <div className="field-title">현재 비밀번호</div>
            <div className="field">
              <input className="input mb full-width" type="password" placeholder="현재 비밀번호"
                     onChange={onChangeOldPassword} value={updateMyPasswordReq.oldPassword}/>
            </div>
            <div className="field-title">신규 비밀번호</div>
            <div className="field">
              <input className="input mb full-width" type="password" placeholder="8~20자"
                     onChange={onChangeNewPassword} value={updateMyPasswordReq.newPassword}/>
            </div>
            <div className="field-title">비밀번호 확인</div>
            <div className="field">
              <input className="input mb full-width" type="password" placeholder="비밀번호 확인"
                     onChange={onChangeNewPasswordConfirm} value={updateMyPasswordReq.newPasswordConfirm}/>
            </div>
          </div>
        </div>

        <div className="bottom-bar">
          <Space/>
          <button className="button" onClick={onClickChangePassword}>비밀번호 변경</button>
        </div>
      </div>
      </>
  )
}

