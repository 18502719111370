export enum RunMode {
  DEV, TEST, PROD
}
const getMode = () => {
  if (process.env.REACT_APP_MODE == 'test') return RunMode.TEST
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') return RunMode.DEV
  return RunMode.PROD
};

export const currentRunMode = getMode()

const getRequestURL = () => {
  switch (currentRunMode) {
    case RunMode.DEV:
      return "http://localhost:8080/api"
    case RunMode.TEST:
      return "https://awlms.devncodi.com/api"
    case RunMode.PROD:
      return window.location.protocol + "//" + window.location.hostname + ":"+window.location.port+"/api"
  }
}
export const requestURL = getRequestURL();

const getBasePath = () => {
  switch (currentRunMode) {
    case RunMode.DEV:
      return undefined
    case RunMode.TEST:
      return "user"
    case RunMode.PROD:
      return "user"
  }
}

export const basePath = getBasePath();

