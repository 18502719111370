import React from 'react';
import "./MobileFoldMenuItem.scss"
import {useNavigate} from "react-router-dom";

interface Props{
  title:string;
  link:string;
  onClick:()=>void;
}
export const MobileFoldMenuItem = (props:Props) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(props.link);
    props.onClick();
  };
  return (
    <div className="MobileFoldMenuItem" onClick={onClick}>
      {props.title}
    </div>
  )
}

