import React, {useState} from 'react';
import "./Sign.scss"
import {Space} from "../Space";
import moment from "moment/moment";
import procedureImage from "../../resources/images/procedures.png";
import {FileUploadEvent, FileUploadInput, pictureAccept, signAccept} from "../FileUploadInput";
import {requestURL} from "../../config";
import {timeToKoreanDate} from "../../util/timeutil";

interface Props{
  text:string;
  name: string;
  fileId: number|null;
  onChangeSignFile: (e: number|null) => void;
  onChangeName: (e: string) => void;
  disabled:boolean;
  hideProcessImage?:boolean;
  signDate?:string;
}


export const Sign = (props:Props) => {
  let hideProcessImage = props.hideProcessImage ?? false;
  let signDate = props.signDate ?? moment().format("yyyy-MM-DD");
  console.log(signDate)

  const onChangeName = (e:React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeName(e.target.value);
  };

  const onChangeSignFile = (e:FileUploadEvent) => {
    props.onChangeSignFile(e.fileId);
  };
  return (
    <div className="Sign">
      <div className="declaration-text">
        {props.text}
      </div>

      <div className="declaration-date">
        <Space/>
        <div>{timeToKoreanDate(signDate)}</div>
      </div>
      <div className="declaration-sign">
        <Space/>
        <div className="headed-input-container mr">
          <span>신고인</span>
          <input className="input" value={props.name} onChange={onChangeName} disabled={props.disabled}/>
        </div>
        <div className="sign-container">
          {props.fileId != null ? 
          <img src={`${requestURL}/download-file/${props.fileId}`}/> :null
          }
          <div>(서명 또는 인)</div>
        </div>
      </div>
      <div className="declaration-to">
        <span>서울지방항공청장</span> 귀하
      </div>
      <div className="declaration-insert-sign">
        <Space/>
        <div className="mr">서명 등록</div>
        <FileUploadInput name="signer_sign_file_id" downloadName="서명" 
                         accept={signAccept}
                         value={props.fileId} onChange={onChangeSignFile} disabled={props.disabled}/>
      </div>
      <div className="flex">
        <Space/>
        (서명 또는 직인(도장) 이미지파일을 등록합니다.)
      </div>

      {!hideProcessImage ?<>
      <div>
        처리 절차
      </div>
      <img src={procedureImage} width="100%"/></>:null}
    </div>
  )
}

