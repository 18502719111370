import React, {ChangeEvent} from 'react';
import "./DeclarationBasicInfo.scss"
import {ComplaintBase} from "../../server/complaint/complaintBaseDto";
import {
  Airport,
  AirportByAviationAgency,
  AirportCode, ComplaintEditMode,
  Obstacle,
  ObstacleCode, RestrictZoneCode
} from "../../server/code";
import {Space} from "../Space";
import {Address, useDaumPostcodePopup} from "react-daum-postcode";


interface Props{
  selectedPage:number
  selectedDeclaration:ComplaintBase
  updateDeclaration: (callback: (draft: ComplaintBase) => void) => void;
  editMode : ComplaintEditMode
  declarationName:string
}
export const DeclarationBasicInfo = (props:Props) => {
  const isOpenAddressPopup = useDaumPostcodePopup();
  let selectedDeclaration = props.selectedDeclaration;
  let disabled = props.editMode === "readonly";

  const onChangeStr = (e:ChangeEvent<HTMLInputElement>) =>
    props.updateDeclaration(draft => {draft[e.target.name] = e.target.value})
  const onChangeSelect = (e:ChangeEvent<HTMLSelectElement>) =>
    props.updateDeclaration(draft => {draft[e.target.name] = e.target.value})
  const onChangeFloat = (e:ChangeEvent<HTMLInputElement>) =>
    props.updateDeclaration(draft => {draft[e.target.name] = parseFloat(e.target.value)})
  const onChangeNumber = (e:ChangeEvent<HTMLInputElement>) => {
    if (e.target.maxLength > 0 && e.target.value.length > e.target.maxLength) return;
    props.updateDeclaration(draft => {draft[e.target.name] = parseInt(e.target.value)})
  }
  const onChangeChecked = (e:ChangeEvent<HTMLInputElement>) =>
    props.updateDeclaration(draft => {draft[e.target.name] = e.target.checked})

  const onClickDeclarerZip = () => isOpenAddressPopup({onComplete: onSelectDeclarerZip});

  const onSelectDeclarerZip = (address:Address) =>
    props.updateDeclaration(draft => {
      draft.dclZip = address.zonecode;
      draft.dclAddr = address.address;
    })

  const onClickRestrictZone = (value:RestrictZoneCode) =>
    props.updateDeclaration(draft => {draft.rstrcZone = value})

  return (
    <div className="DeclarationBasicInfo declaration-grid">
      <div className="header yellow" style={{gridColumn:"auto / span 5"}}>
        {props.declarationName}({props.selectedPage})
      </div>
      <div className="header yellow">처리기간 15일</div>

      <div className="header yellow" style={{gridRow:"auto / span 2"}}>신고인</div>

      <div className="header yellow">성명 (법인명)</div>
      <div className="field">
        <input className="input" placeholder="oo주식회사 대표이사"
               value={selectedDeclaration.dclNm}
               name="dclNm" onChange={onChangeStr}
               disabled={disabled}/>
      </div>
      <div className="header yellow">
        <input type="checkbox" checked={selectedDeclaration.kepco}
               name="kepco" onChange={onChangeChecked}
               disabled={disabled}/>
        한전
      </div>
      <div className="header yellow">사업자등록번호</div>
      <div className="field">
        <input className="input" placeholder="숫자만 입력"
               type="number" maxLength={10}
               value={selectedDeclaration.dclBrno}
               name="dclBrno" onChange={onChangeNumber}
               disabled={disabled}/>
      </div>

      <div className="header yellow">주소</div>
      <div className="field" style={{gridColumn:"auto / span 4"}}>
        <input className="input" style={{width:"100px"}} readOnly={true} placeholder="우편번호"
               value={selectedDeclaration.dclZip ?? ""} onClick={onClickDeclarerZip}
               disabled={disabled}/>
        <input className="input" value={selectedDeclaration.dclAddr}
               name="dclAddr" onChange={onChangeStr} disabled={disabled}/>
      </div>

      <div className="header yellow" style={{gridRow:"auto / span 8"}}>장애물</div>
      <div className="header yellow">명칭</div>

      <div className="field" style={{gridColumn:"auto / span 2"}}>
        <input className="input" placeholder="예시) ○○○○ 현장 타워크레인"
               value={selectedDeclaration.obstNm}
               name="obstNm" onChange={onChangeStr} disabled={true}/>
      </div>
      <div className="header yellow" style={{gridRow:"auto / span 2"}}>제한구역</div>
      <div className="field" style={{gridRow:"auto / span 2"}}>
        <div>
          <div>
            <input type="radio" checked={selectedDeclaration.rstrcZoneCd === "001"}
                   onChange={()=>onClickRestrictZone("001")} disabled={true}/>장애물제한구역 안
          </div>
          <div>
            <input type="radio" checked={selectedDeclaration.rstrcZoneCd === "002"}
                   onChange={()=>onClickRestrictZone("002")} disabled={true}/>장애물제한구역 밖
          </div>
          <div>
            <input type="radio" checked={selectedDeclaration.rstrcZoneCd === "003"}
                   onChange={()=>onClickRestrictZone("003")} disabled={true}/>비행안전구역
          </div>
        </div>
      </div>

      <div className="header yellow">설치연월일</div>
      <div className="field" style={{gridColumn:"auto / span 2"}}>
        <input className="input" type="date" value={selectedDeclaration.instlYmd}
               name="instlYmd" onChange={onChangeStr} disabled={true}/>
      </div>

      <div className="header yellow">좌표(WGS-84)</div>
      <div className="field" style={{gridColumn:"auto / span 4"}}>
        <div className="headed-input-container grow">
          <span>북위</span>
          <input className="input" placeholder="작성예시) 35-16-25"
                 value={selectedDeclaration.crdNorth}
                 name="crdNorth" onChange={onChangeStr} disabled={true}/>
        </div>
        <div className="headed-input-container grow">
          <span>동경</span>
          <input className="input" placeholder="작성예시) 35-16-25"
                 value={selectedDeclaration.crdEast}
                 name="crdEast" onChange={onChangeStr} disabled={true}/>
        </div>
      </div>
      <div className="header yellow">소재지 표고</div>
      <div className="field" style={{gridColumn:"auto / span 2"}}>
        <div className="headed-input-container grow">
          <span>해면상(A)</span>
          <input className="input" placeholder="미터(m)"  type="number"
                 value={selectedDeclaration.lctnHgt ?? ""}
                 name="lctnHgt"
                 onChange={onChangeFloat} disabled={disabled}/>
        </div>
      </div>
      <div className="header yellow">장애물 종류</div>
      <div className="field">
        <select className="select" value={selectedDeclaration.obstType}
                name="obstType"
                onChange={onChangeSelect} disabled={true}>
          {Object.keys(Obstacle).map((key) => {
            return <option value={key} key={key}>{Obstacle[key as ObstacleCode]}</option>
          })}
        </select>
      </div>

      <div className="header yellow">높이</div>
      <div className="field" style={{gridColumn:"auto / span 4",paddingTop:"5px",paddingBottom:"5px"}}>
        <div style={{width:"100%"}}>
          <div className="flex ml" style={{fontSize:"12px"}}>
            (피뢰침 등 부속구조물 포함)
            <Space/>
          </div>
          <div className="flex">
            <div className="headed-input-container grow">
              <span>지면상(B)</span>
              <input className="input" placeholder="미터(m)" type="number"
                     value={selectedDeclaration.hgtFromGrnd ?? ""}
                     name="hgtFromGrnd"
                     onChange={onChangeFloat} disabled={true}/>
            </div>
            <div className="headed-input-container grow">
              <span>해면상(A+B)</span>
              <input className="input" placeholder="미터(m)" type="number"
                     value={selectedDeclaration.hgtFromSea ?? ""}
                     name="hgtFromSea"
                     onChange={onChangeFloat} disabled={true}/>
            </div>
          </div>
        </div>
      </div>
      <div className="header yellow">크기</div>
      <div className="field" style={{gridColumn:"auto / span 4"}}>
        <div className="headed-input-container grow">
          <span>가로</span>
          <input className="input" placeholder="미터(m)" type="number"
                 value={selectedDeclaration.horizontalWdth ?? ""}
                 name="horizontalWdth"
                 onChange={onChangeFloat} disabled={true}/>
        </div>
        <div className="headed-input-container grow">
          <span>세로</span>
          <input className="input" placeholder="미터(m)" type="number"
                 value={selectedDeclaration.vrtcWdth ?? ""}
                 name="vrtcWdth"
                 onChange={onChangeFloat} disabled={disabled}/>
        </div>
      </div>
      <div className="header yellow">비행장</div>
      <div className="field" style={{gridColumn:"auto / span 2"}}>
        <select className="select" value={selectedDeclaration.airport}
                name="airport"
                onChange={onChangeSelect} disabled={true}>
          {AirportByAviationAgency[selectedDeclaration.aviationAgencyCd ?? "001"]
            .map((code) => {
              return <option value={code} key={code}>{Airport[code as AirportCode]}</option>
            })}
        </select>
        <div className="headed-input-container" >
          <span>거리</span>
          <input className="input" placeholder="Km" type="number"
                 value={selectedDeclaration.distFromAirport ?? ""}
                 name="distFromAirport"
                 onChange={onChangeFloat} disabled={true}/>
        </div>
      </div>

      <div className="header yellow">방위</div>
      <div className="field">
        <input className="input" value={selectedDeclaration.bearingFromAirport}
               name="bearingFromAirport"
               onChange={onChangeStr} disabled={disabled}/>
      </div>

      <div className="header yellow">군집물체</div>
      <div className="field" style={{gridColumn:"auto / span 4"}}>
        <input type="checkbox" checked={selectedDeclaration.cluster}
               name="cluster"
               onChange={onChangeChecked} disabled={true}/>
        단일물체나 서로 떨어져있는 여러개의 물체들이 밀접하게 모여 형성된 하나의 집단
        <Space/>
      </div>

      <div className="header yellow" style={{gridRow:"auto / span 2"}}>관리 <br/>담당자</div>
      <div className="header yellow">성명</div>
      <div className="field" style={{gridColumn:"auto / span 2"}}>
        <input className="input" name="mngrNm"
               value={selectedDeclaration.mngrNm}
               onChange={onChangeStr} disabled={disabled}/>
      </div>
      <div className="header yellow">직책</div>
      <div className="field">
        <input className="input" name="mngrJbttl"
               value={selectedDeclaration.mngrJbttl}
               onChange={onChangeStr} disabled={disabled}/>
      </div>
      <div className="header yellow">연락처</div>
      <div className="field" style={{gridColumn:"auto / span 2"}}>
        <input className="input" name="mngrTel"
               value={selectedDeclaration.mngrTel}
               onChange={onChangeStr} disabled={disabled}/>
      </div>
      <div className="header yellow">팩스</div>
      <div className="field">
        <input className="input" name="mngrFax"
               value={selectedDeclaration.mngrFax}
               onChange={onChangeStr} disabled={disabled}/>
      </div>
    </div>
  )
}

