import React from 'react';
import "./TempLoaderModal.scss"
import PopupPanel from "../Popup/PopupPanel";

interface Props{
  isOpen:boolean;
  onClose:()=>void
  tempItems:TempItem[];
  onLoad:(tempItem:TempItem)=>void;
  onDelete:(id:number)=>void;
  type:string;
}

export interface TempItem{
  id:number;
  createdAt:string;
  complaint:any;
}

export const TempLoaderModal = (props:Props) => {
  const onLoad = (temp: TempItem) => {
    props.onClose()
    props.onLoad(temp)
  };
  return (
    <PopupPanel isOpen={props.isOpen} title="임시저장 불러오기" onClose={props.onClose} customStyle={{width:"500px"}}>
      <div>
        {props.tempItems.length === 0 ? <div>저장된 내역이 없습니다.</div> :
        <table className="table clickable" style={{marginBottom:"20px"}}>
          <thead>
          <tr>
            <th>종류</th>
            <th>날짜</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {props.tempItems.map((temp,index) => (
            <tr key={temp.id} >
              <td>{props.type}</td>
              <td onClick={()=>onLoad(temp)}> {temp.createdAt}</td>
              <td onClick={()=>props.onDelete(temp.id)}>삭제</td>
            </tr>
          ))}
          </tbody>
        </table>}
      </div>
    </PopupPanel>
  )
}

