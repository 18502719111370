
export const parseErrorMessage = (e: any, fieldmap: { [index: string]: string } = {}): string => {
  try {
    if (!e.response || !e.response.status) {
      console.log(e)
      if (e.message) return `식별되지 않은 에러입니다. (${e.message})`
      else return "알 수 없는 에러입니다."
    }

    if (e.response.status === 403) return "권한이 없습니다."
    if (e.response.status === 500) return "서버 에러입니다. 잠시 후 다시 시도해주세요."
    
    const detail = e.response.data.detail
    if (!Array.isArray(detail)) return e.response.data.msg;

    let msgs = "";
    if (detail.length > 10) detail.splice(0, 10)
    
    detail.forEach((item: any) => {
      let index = getIndexFromStr(item.field);
      msgs += index >= 0 ? 
        `(${index + 1}번째 신고서) ${item.msg}\n`:
        `${item.msg}\n`;
    })
    return msgs;
  } catch (e) {
    console.log(e)
    return "알 수 없는 에러입니다."
  }
}


export const getIndexFromStr = (str: string): number => {
  const match = str.match(/\[(\d+)]/);
  if (match) return parseInt(match[1]);
  else return -1;
}