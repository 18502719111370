import React, {useState} from 'react';
import "./TopNavigator.scss"
import govIcon from "../../resources/images/mark.svg";
import {useNavigate} from "react-router-dom";
import {parseJWT} from "../../util/loginManager";
import {Space} from "../../comp-shared/Space";
import clsx from "clsx";
import {MobileFoldMenu} from "./TopNavigator/MobileFoldMenu";
import {MobileFoldMenuItem} from "./TopNavigator/MobileFoldMenuItem";

export const TopNavigator = () => {
  const [isShowSubmenu, setIsShowSubmenu] = useState<boolean>(false)
  const navigate = useNavigate();
  const accessToken = parseJWT();

  const logoWidth = "300px";
  const complainWidth = "148px";
  const brokenComplainWidth = "148px";
  const subServiceWidth = "208px";
  const noticeWidth = "198px";
  const userServiceWidth = "148px";

  const onMouseEnter = () => setIsShowSubmenu(true);

  const closeSubmenu = () => setIsShowSubmenu(false);
  return (
    <>
    <div className="TopNavigator container" >
      <div className="container-inbox pc-only"
           onMouseEnter={onMouseEnter} onMouseLeave={closeSubmenu}>
        <div onClick={()=>{navigate("/"); setIsShowSubmenu(false)}}
             className="logo" style={{width:logoWidth}}>
          <img className="gov-icon" src={govIcon}/>
          <div className="title-section">
            <div className="title">항공장애 표시등</div>
            <div className="subtitle">민원서비스</div>
          </div>
        </div>
        <div className="category" style={{width:complainWidth}}>민원신고</div>
        <div className="category" style={{width:brokenComplainWidth}}>고장통지</div>
        <div className="category" style={{width:subServiceWidth}}>설치/면제 판단서비스</div>
        <div className="category" style={{width:noticeWidth}}>알림마당</div>
        <div className="category" style={{width:userServiceWidth}}>회원서비스</div>
      </div>
      <div className="container-inbox mobile-only">
        <button className="hamburger" onClick={()=>setIsShowSubmenu(true)}>
          <svg viewBox="0 0 100 80" width="20" height="20" fill="gray">
            <rect width="100" height="20" rx="8"></rect>
            <rect y="30" width="100" height="20" rx="8"></rect>
            <rect y="60" width="100" height="20" rx="8"></rect>
          </svg>
        </button>
        <Space disablePC={true}/>
        <div onClick={()=>navigate("/")} className="logo" style={{width:logoWidth}}>
          <img className="gov-icon" src={govIcon}/>
          <div className="title-section">
            <div className="title">항공장애 표시등 민원서비스</div>
          </div>
        </div>
        <Space disablePC={true}/>
      </div>
    </div>
        <div className={clsx("submenu container pc-only",{"isOpened":isShowSubmenu})}
            onClick={closeSubmenu}
             onMouseEnter={onMouseEnter} onMouseLeave={closeSubmenu}>
          <div className="container-inbox">
            <div style={{width:logoWidth}}/>
            <div className="category-border" style={{width:complainWidth}}>
              <button onClick={()=>navigate("/complaint/install")}>설치신고</button>
              <button onClick={()=>navigate("/complaint/change")}>변경신고</button>
              <button onClick={()=>navigate("/complaint/demolish")}>철거신고</button>
            </div>
            <div className="category-border" style={{width:brokenComplainWidth}}>
              <button onClick={()=>navigate("/broken/broken")}>고장통지</button>
              <button onClick={()=>navigate("/broken/rebroken")}>재통지</button>
              <button onClick={()=>navigate("/broken/restore")}>복구완료 통지</button>
            </div>
            <div className="category-border" style={{width:subServiceWidth}}>
              <button onClick={()=>navigate("/estimate/install")}>설치대상 판단서비스</button>
              <button onClick={()=>navigate("/estimate/exemption")}>면제대상 판단서비스</button>
            </div>
            <div className="category-border" style={{width:noticeWidth}}>
              <button onClick={()=>navigate("/notice/list")}>공지사항</button>
              <button onClick={()=>navigate("/help/complaint-example")}>신고서 작성전 준비사항</button>
            </div>
            <div className="category-border" style={{width:userServiceWidth}}>
              {
                !accessToken.user ? <>
                  <button onClick={()=>navigate("/user/login")}>로그인</button>
                  <button onClick={()=>navigate("/user/join")}>회원가입</button>
                  <button onClick={()=>navigate("/user/find-password")}>비밀번호 찾기</button>
                </>:<>
                  <button onClick={()=>navigate("/user/my-complaint")}>나의 민원</button>
                  <button onClick={()=>navigate("/user/my-info")}>회원정보</button>
                </>
              }
            </div>
          </div>
        </div>
        <div className={clsx("mobile-submenu mobile-only",{"isOpened":isShowSubmenu})}>
          <div className="header">
            <div className="title">메뉴</div>
            <Space/>
            <button className="button-circle close-button"
              onClick={()=>setIsShowSubmenu(false)}>×</button>
          </div>

          <MobileFoldMenu title="민원신고">
            <MobileFoldMenuItem title="설치신고" link="/complaint/install" onClick={closeSubmenu}/>
            <MobileFoldMenuItem title="변경신고" link="/complaint/change" onClick={closeSubmenu}/>
            <MobileFoldMenuItem title="철거신고" link="/complaint/demolish" onClick={closeSubmenu}/>
          </MobileFoldMenu>
          
          <MobileFoldMenu title="고장통지">
            <MobileFoldMenuItem title="고장통지" link="/broken/broken" onClick={closeSubmenu}/>
            <MobileFoldMenuItem title="재통지" link="/broken/rebroken" onClick={closeSubmenu}/>
            <MobileFoldMenuItem title="복구완료통지" link="/broken/restore" onClick={closeSubmenu}/>
          </MobileFoldMenu>
          
          <MobileFoldMenu title="설치/면제 판단서비스">
            <MobileFoldMenuItem title="설치대상 판단서비스" link="/estimate/install" onClick={closeSubmenu}/>
            <MobileFoldMenuItem title="면제대상 판단서비스" link="/estimate/exemption" onClick={closeSubmenu}/>
          </MobileFoldMenu>
          
          <MobileFoldMenu title="알림마당">
            <MobileFoldMenuItem title="공지사항" link="/notice/list" onClick={closeSubmenu}/>
            <MobileFoldMenuItem title="신고서 작성전 준비사항" link="/help/complaint-example" onClick={closeSubmenu}/>
            <MobileFoldMenuItem title="장애물제한구역 확인방법" link="/help/restrict" onClick={closeSubmenu}/>
            <MobileFoldMenuItem title="제출서류 예시" link="/help/document-sample" onClick={closeSubmenu}/>
            <MobileFoldMenuItem title="서명파일 예시(등록방법)" link="/help/sign-sample" onClick={closeSubmenu}/>
          </MobileFoldMenu>
          
          <MobileFoldMenu title="회원서비스">
            {
              !accessToken.user ? <>
                <MobileFoldMenuItem title="로그인" link="/user/login" onClick={closeSubmenu}/>
                <MobileFoldMenuItem title="회원가입" link="/user/join" onClick={closeSubmenu}/>
                <MobileFoldMenuItem title="비밀번호 찾기" link="/user/find-password" onClick={closeSubmenu}/>
              </>:<>
                <MobileFoldMenuItem title="나의 민원" link="/user/my-complaint" onClick={closeSubmenu}/>
                <MobileFoldMenuItem title="회원정보" link="/user/my-info" onClick={closeSubmenu}/>
              </>
            }
          </MobileFoldMenu>
        </div>
    </>
  )
}

