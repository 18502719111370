function Answer197() {
  return <li className="answer-97">문의하신 장애물은 장애물제한구역 안의 장애물로서 진입, 전이, 수평,
    원추표면보다 높은 경우 항공장애표시등 및 항공장애주간표지 설치대상되므로 침투여부 확인이 필요합니다.
    따라서 인천국제공항공사 또는 한국공항공사로 문의하시기 바랍니다.
  </li>;
}

function Answer198() {
  return <li className="answer-98">문의하신 장애물은 비행안전구역내에 있으므로 관할 군부대장에게 문의하시기 바랍니다.</li>;
}

function Answer101() {
  return <li className="answer-1">
    <div className="answer-text">
      높이가 지표 또는 수면으로부터 150m 이상인 물체나 구조물에는 항공장애표시등 및 항공장애주간표지를 설치하여야 합니다.
    </div>
  </li>;
}

function Answer102() {
  return <li className="answer-2">
    <div className="answer-text">
      높이가 지표 또는 수면으로부터 60m 이상인 물체나 구조물에는 항공장애표시등 및 항공장애주간표지를 설치하여야 합니다.
    </div>
  </li>;
}

function Answer103() {
  return <li className="answer-3">
    <div className="answer-text">영구적인 사용을 위하여 건설 중인 구조물에는 높이가 지표 또는 수면으로부터
      150m 이상인 경우 항공장애표시등 및 항공장애주간표지를 설치하여야 합니다.
    </div>
  </li>;
}

export const Answer1 = (props: {
  qnaCheck1: string
  qnaCheck2: string
}) => {
  if(props.qnaCheck1 === '1')
    return (<Answer197/>)

  if(props.qnaCheck1 === '3')
    return (<Answer198/>)

  if(props.qnaCheck1 === '2') {
    if(props.qnaCheck2 === '1')
      return (<Answer101/>)

    if(props.qnaCheck2 === '2'
      || props.qnaCheck2 === '3'
      || props.qnaCheck2 === '4'
      || props.qnaCheck2 === '5'
      || props.qnaCheck2 === '6'
      || props.qnaCheck2 === '7')
      return (<Answer102/>)

    if(props.qnaCheck2 === '8')
      return (<Answer103/>)
  }
}