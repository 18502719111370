import React from "react";

export const QuestionBtn = (props: {
  value: string
  checkedValue: string
  setCheckedValue: (value: string) => void
  label: string
}) => {
  return (
    <div>
      <input type="radio" value={props.value}
             checked={props.value === props.checkedValue}
             onChange={e => props.setCheckedValue(e.target.value)}/>
      <label
        onClick={e => props.setCheckedValue(props.value)}>{props.label}
      </label><br/>
    </div>
  )
}