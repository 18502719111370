import React, {useState} from 'react';
import "./DocumentSample.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import {ImagePopup} from "../../comp-shared/Popup/ImagePopup";
import {PlanSamplePopup} from "../../comp-pages/Complaint/InstallComplaint/PlanSamplePopup";
import sampleImage2 from "../../resources/sampleimages/2_sample.png";
import sampleImage3 from "../../resources/sampleimages/3_sample_1.png";
import sampleImage5 from "../../resources/sampleimages/5_sample.png";
import sampleImage6 from "../../resources/sampleimages/6_sample.png";

export const DocumentSample = () => {
  const [isOpenImagePopup, setIsOpenImagePopup] = useState<boolean>(false);
  const [imagePopupSrc, setImagePopupSrc] = useState<string>("");
  const [isOpenPlanSamplePopup, setIsOpenPlanSamplePopup] = useState<boolean>(false);

  const onCloseImagePopup = () => setIsOpenImagePopup(false);

  const onClosePlanSamplePopup = () => setIsOpenPlanSamplePopup(false);
  const onClickOpenPlanSamplePopup = () => setIsOpenPlanSamplePopup(true);

  const onClickOpenImagePopup = (imgSrc:string) => {
    setIsOpenImagePopup(true);
    setImagePopupSrc(imgSrc);
  };

  return (
    <>
      <div className="DocumentSample">
        <PageTitle title="제출서류 예시"/>
        <div className="middle-info-box declaration-grid">
          <div className="header yellow" style={{gridRow: "auto / span 6"}}>제출서류</div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            1. 표시등 종류, 수량 및 설치위치가 포함된 도면&nbsp;
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <a className="href" onClick={onClickOpenPlanSamplePopup}>[예시]</a>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            2. 주간표지 설치도면 (주간표지 미설치 시 첨부 생략 가능)&nbsp;
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <a className="href" onClick={() => onClickOpenImagePopup(sampleImage2)}>[예시]</a>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            3. 설치사진 (전체적인 위치를 나타내는 사진)&nbsp;
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <a className="href" onClick={() => onClickOpenImagePopup(sampleImage3)}>[예시]</a>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 5"}}>
            4. 최근 2년 이내에 시험한 성적서 (항공장애 표시등의 제품명을 선택하거나 시험성적서 파일첨부)
          </div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            5. 제어반 사진 및 입력 전압, 전류값&nbsp;
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <a className="href" onClick={() => onClickOpenImagePopup(sampleImage5)}>[예시]</a>
          </div>
          <div className="field left" style={{gridColumn: "auto / span 3"}}>
            6. 표시등 및 제어반 부품 내역&nbsp;
          </div>
          <div className="field left" style={{gridColumn: "auto / span 2"}}>
            <a className="href" onClick={() => onClickOpenImagePopup(sampleImage6)}>[예시]</a>
          </div>
        </div>
      </div>
      <ImagePopup isOpen={isOpenImagePopup} onClose={onCloseImagePopup}
                  imgSrc={imagePopupSrc}/>
      <PlanSamplePopup isOpen={isOpenPlanSamplePopup} onClose={onClosePlanSamplePopup}/>
    </>
  )
}

