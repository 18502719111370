import React from 'react';
import "./SignSample.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import SignStep from "../../resources/images/sign_step.png";
import {Sign} from "../../comp-shared/Complaint/Sign";

export const SignSample = () => {
  return (
    <div className="SignSample">
      <PageTitle title="서명파일 예시"/>
      <img src={SignStep} alt="서명파일 예시" style={{width:"100%"}}/>
      <div>1. 직인을 빈 종이에 찍습니다.</div>
      <div>2. 종이를 스캐너 또는 복합기 등을 이용하여 PC로 전송합니다. (또는 스마트폰 촬영 등을 이용하여 전송)</div>
      <div>3. PC 로 전송된 직인을 그림판등을 이용하여 직인이 찍힌 영역을 반듯이 잘라내어 아래와 같이 서명 첨부파일에 업로드합니다.</div>
      <hr/>
      <Sign text="「공항시설법」 제36조제5항 및 같은 법 시행규칙 제29조제1항에 따라 위와 같이 신고합니다." name="홍길동"
            fileId={null} onChangeSignFile={()=>{}} onChangeName={()=>{}} disabled={true}/>
    </div>
  )
}

