import React, {createContext, useEffect} from "react";
import "./PopupPanel.scss";
import {Space} from "../Space";
import CloseIcon from "../../resources/icons/close_FILL0_wght400_GRAD0_opsz48.svg";


interface Props {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  customStyle?: React.CSSProperties;
  renderMiddleTitle?:React.ReactNode;
}

const PopupPanel = (props: Props) => {
  const onClickOuter = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      props.onClose()
    }
  }
  const onClickCloseButton = () => {
    props.onClose()
  }
  return <div className={`Popup ${props.isOpen?"opened":""}`}
              onMouseDown={onClickOuter}>
    <div className="inner-item" style={props.customStyle ?? {}}>
      <div style={{display:"flex", marginBottom:"10px", height:"25px", borderBottom:"1px solid #DDDDDD"}}>
        <div className="title">{props.title}</div>
        <Space/>
        {props.renderMiddleTitle ? props.renderMiddleTitle : null}
        <Space/>
        <div className="close-button" onClick={onClickCloseButton}>
          <img src={CloseIcon} width={24} height={24}/>
        </div>
      </div>
      {props.children}
    </div>
  </div>
}

export default PopupPanel;
