import React, {Children} from 'react';
import "./LeftNaviagtor.scss"
interface Props{
  title:string;
  children?:React.ReactNode;
}

export const LeftNaviagtor = (props:Props) => {
  return (
    <div>
      <div className="LeftNaviagtor pc-only">
        <div className="header"> {props.title} </div>
        {props.children}
      </div>
    </div>
  )
}

