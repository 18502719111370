import React from 'react';
import "./Complaint.scss"
import {BreadCrumb} from "../comp-shared/BreadCrumb";
import {LeftNaviagtor} from "../comp-shared/LeftNaviagtor";
import {LeftNavigatorItem} from "../comp-shared/LeftNavigator/LeftNavigatorItem";
import {Route, Routes, useLocation} from "react-router-dom";
import {Login} from "./User/Login";
import {Join} from "./User/Join";
import {FindPassword} from "./User/FindPassword";
import {MyComplaint} from "./User/MyComplaint";
import {MyInfo} from "./User/MyInfo";
import {InstallComplaint} from "./Complaint/InstallComplaint";
import {ChangeComplaint} from "./Complaint/ChangeComplaint";
import {DemolishComplaint} from "./Complaint/DemolishComplaint";
import {InquiryBox} from "../comp-shared/InquiryBox";

export const Complaint = () => {
  const location = useLocation();
  const getBreadcrumbPath = () => {
    if (location.pathname.includes("install")) return ["민원신청","설치신고"];
    if (location.pathname.includes("change")) return ["민원신청","변경신고"];
    if (location.pathname.includes("demolish")) return ["민원신청","철거신고"];
    return []
  };
  return (
    <div className="Complaint">
      <BreadCrumb path={getBreadcrumbPath()}/>
      <div className="container">
        <div className="container-inbox">
          <div>
            <LeftNaviagtor title="민원신청">
              <LeftNavigatorItem path="/complaint/install"> 설치신고 </LeftNavigatorItem>
              <LeftNavigatorItem path="/complaint/change"> 변경신고 </LeftNavigatorItem>
              <LeftNavigatorItem path="/complaint/demolish"> 철거신고</LeftNavigatorItem>
            </LeftNaviagtor>
            <InquiryBox />
          </div>
          <Routes>
            <Route path="install" element={<InstallComplaint/>}/>
            <Route path="change" element={<ChangeComplaint/>}/>
            <Route path="demolish" element={<DemolishComplaint/>}/>
            <Route path="*" element={<Login/>}/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

