import {useState} from "react";
import {getDefaultCommonBrokenInfoRes, CommonBrokenInfoRes} from "../../server/user/myComplaintDto";

export function useMyBrokenComplaint() {
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [rebrokenComplaint, setRebrokenComplaint]
    = useState<CommonBrokenInfoRes[]>([getDefaultCommonBrokenInfoRes()]);
  const selectedRebrokenComplaint = rebrokenComplaint[selectedPage-1];

  const isRestored = selectedRebrokenComplaint.myRebrokenComplaintList
    .some(rebroken => rebroken.stts === 1);
  const isOnRestore = selectedRebrokenComplaint.myRebrokenComplaintList
    .some(rebroken => rebroken.rstrCmptnYmd !== null);
  const isCycleEnd = selectedRebrokenComplaint.myRebrokenComplaintList.length >= 5;

  return {
    selectedPage,
    setSelectedPage,
    rebrokenComplaint,
    setRebrokenComplaint,
    selectedRebrokenComplaint,
    isRestored,
    isOnRestore,
    isCycleEnd
  };
}