import React, {ChangeEvent} from 'react';
import "./ObstacleAddress.scss"
import {
  AirportByAviationAgency, AirportCode,
  AviationAgency,
  AviationAgencyBySubarea, ComplaintEditMode,
  getSubareaByAddress,
  Subarea
} from "../../server/code";
import {Address, useDaumPostcodePopup} from "react-daum-postcode";
import {ComplaintBase} from "../../server/complaint/complaintBaseDto";

interface Props{
  selectedDeclaration:ComplaintBase
  updateDeclaration: (callback: (draft: ComplaintBase) => void) => void;
  editMode : ComplaintEditMode
}

export const ObstacleAddress = (props:Props) => {
  const isOpenAddressPopup = useDaumPostcodePopup();
  
  const onClickObstacleZip = () => {
    isOpenAddressPopup({onComplete:onSelectObstacleZip});
  };
  
  const onSelectObstacleZip = (address:Address) => {
    try {
      let regionCode = getSubareaByAddress(address.address)
      let aviationAgencyCode = AviationAgencyBySubarea[regionCode]
      props.updateDeclaration(draft => {
        draft.obstZip = address.zonecode;
        draft.obstAddr = address.address;
        draft.aviationAgencyCd = aviationAgencyCode;
        draft.sareaCd = regionCode;
        draft.airport = AirportByAviationAgency[aviationAgencyCode][0] as AirportCode;
      });
    }catch (e){
      alert("지역 선택에 실패했습니다."+e);
    }
  }

  const onChangeObstAddr = (e:ChangeEvent<HTMLInputElement>) => {
    props.updateDeclaration(draft => {
      draft.obstAddr = e.target.value;
    });
  };
  return (
    <div className="ObstacleAddress declaration-grid">
      <div className="header yellow">장애물 소재지</div>
      <div className="field" style={{gridColumn:"auto / span 3"}}>
        <input className="input" style={{width:"100px"}} readOnly={true} placeholder="우편번호"
               onClick={onClickObstacleZip} value={props.selectedDeclaration.obstZip ?? ""}
               disabled={props.editMode === "readonly"}/>
        <input className="input" value={props.selectedDeclaration.obstAddr}
               name="obstAddr" onChange={onChangeObstAddr} disabled={props.editMode === "readonly"}/>
      </div>
      <div className="header yellow">항공청</div>
      <div className="field">
        {props.selectedDeclaration.aviationAgencyCd ? AviationAgency[props.selectedDeclaration.aviationAgencyCd] : ""}
      </div>
      <div className="header yellow">권역</div>
      <div className="field">
        {props.selectedDeclaration.sareaCd ? Subarea[props.selectedDeclaration.sareaCd] : ""}
      </div>
      {props.selectedDeclaration.mngCardNo !== null && props.selectedDeclaration.mngCardNo !== "" ?
        <>
        <div className="header yellow">관리번호</div>
        <div className="field" style={{gridColumn:"auto / span 3"}}>{props.selectedDeclaration.mngCardNo}</div>
        </>:null}

    </div>
  )
}

