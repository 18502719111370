import React, {useState} from 'react';
import "./ComplaintSample.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import {TabSelector} from "../../comp-shared/TabSelector";
import {Space} from "../../comp-shared/Space";
import sampleImage2 from "../../resources/sampleimages/2_sample.png";
import sampleImage3 from "../../resources/sampleimages/3_sample_1.png";
import sampleImage5 from "../../resources/sampleimages/5_sample.png";
import sampleImage6 from "../../resources/sampleimages/6_sample.png";
import sampleImage7 from "../../resources/sampleimages/7_sample.png";
import sampleImage8 from "../../resources/sampleimages/8_sample.png";
import sampleImage9 from "../../resources/sampleimages/9_sample.png";
import {ImagePopup} from "../../comp-shared/Popup/ImagePopup";
import {PlanSamplePopup} from "../../comp-pages/Complaint/InstallComplaint/PlanSamplePopup";

export const ComplaintSample = () => {
  const [selectedPage, setSelectedPage] = React.useState(1);
  return (
    <div className="ComplaintSample">
      <PageTitle title="신고서 작성예시"/>
      <TabSelector selectedPage={selectedPage} setSelectedPage={setSelectedPage}
                   data={[1,2,3,4]} pageNameList={["설치신고서","변경신고서","철거신고서","고장통지서"]} />
      {selectedPage === 1 && <InstallSample/>}
      {selectedPage === 2 && <ChangeSample/>}
      {selectedPage === 3 && <DemolishSample/>}
      {selectedPage === 4 && <BrokenSample/>}
    </div>
  )
}

export const InstallSample = () => {
  const [isOpenImagePopup, setIsOpenImagePopup] = useState<boolean>(false);
  const [imagePopupSrc, setImagePopupSrc] = useState<string>("");
  const [isOpenPlanSamplePopup, setIsOpenPlanSamplePopup] = useState<boolean>(false);

  const onCloseImagePopup = () => setIsOpenImagePopup(false);
  const onClosePlanSamplePopup = () => setIsOpenPlanSamplePopup(false);
  const onClickOpenPlanSamplePopup = () => setIsOpenPlanSamplePopup(true);

  const onClickOpenImagePopup = (imgSrc:string) => {
    setIsOpenImagePopup(true);
    setImagePopupSrc(imgSrc);
  };
  
  return <>
    <div className="address-box declaration-grid">
      <div className="header yellow">장애물 소재지</div>
      <div className="field" style={{gridColumn: "auto / span 3"}}>
        <input className="input" style={{width: "100px"}} disabled={true} value="22382"/>
        <input className="input" value="인천광역시 중구 공항로424번길 47 (서울지방항공청)"
               name="obstAddr" disabled={true}/>
      </div>
      <div className="header yellow">항공청</div>
      <div className="field">서울지방항공청</div>
      <div className="header yellow">권역</div>
      <div className="field"> 서울</div>
    </div>

    <div className="middle-info-box declaration-grid">
      <div className="header yellow" style={{gridColumn: "auto / span 5"}}>설치신고서</div>
      <div className="header yellow">처리기간 15일</div>

      <div className="header yellow" style={{gridRow: "auto / span 2"}}>신고인</div>

      <div className="header yellow">성명 (법인명)</div>
      <div className="field">
        <input className="input" value="oo주식회사 대표이사" disabled={true}/>
      </div>
      <div className="header yellow">
        <input type="checkbox" checked={false} disabled={true}/>
        한전
      </div>
      <div className="header yellow">사업자등록번호</div>
      <div className="field">
        <input className="input" type="number" maxLength={10} value="0123456789" disabled={true}/>
      </div>

      <div className="header yellow">주소</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <input className="input" style={{width: "100px"}} readOnly={true} value="우편번호"
               disabled={true}/>
        <input className="input" value="서울특별시 강남구 봉은사로71길 50(삼성동)"
               disabled={true}/>
      </div>

      <div className="header yellow" style={{gridRow: "auto / span 9"}}>장애물</div>
      <div className="header yellow">명칭</div>

      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <input className="input" value="서울지방항공청 장애물" disabled={true}/>
      </div>
      <div className="header yellow" style={{gridRow: "auto / span 2"}}>제한구역</div>
      <div className="field" style={{gridRow: "auto / span 2"}}>
        <div>
          <div><input type="radio" checked={true} disabled={true}/>장애물제한구역 안</div>
          <div><input type="radio" checked={false} disabled={true}/>장애물제한구역 밖</div>
          <div><input type="radio" checked={false} disabled={true}/>비행안전구역</div>
        </div>
      </div>

      <div className="header yellow">설치연월일</div>
      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <input className="input" type="date" value="2021-01-01"
               name="instlYmd" disabled={true}/>
      </div>
      <div className="field" style={{gridColumn: "auto / span 5"}}>
        설치한 날로부터 15일 이내에 신고하지 않은 경우 과태료가 부과됩니다.
      </div>

      <div className="header yellow">좌표(WGS-84)</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container grow">
          <span>북위</span>
          <input className="input" value="35-16-25" disabled={true}/>
        </div>
        <div className="headed-input-container grow">
          <span>동경</span>
          <input className="input" placeholder="35-16-25" disabled={true}/>
        </div>
      </div>
      <div className="header yellow">소재지 표고</div>
      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <div className="headed-input-container grow">
          <span>해면상(A) (m)</span>
          <input className="input" type="number" value="50" disabled={true}/>
        </div>
      </div>
      <div className="header yellow">장애물 종류</div>
      <div className="field">
        <select className="select" name="obstType" disabled={true}>
          <option>철탑</option>
        </select>
      </div>

      <div className="header yellow">높이</div>
      <div className="field"
           style={{gridColumn: "auto / span 4", paddingTop: "5px", paddingBottom: "5px"}}>
        <div style={{width: "100%"}}>
          <div className="flex ml" style={{fontSize: "12px"}}>
            (피뢰침 등 부속구조물 포함)
            <Space/>
          </div>
          <div className="flex">
            <div className="headed-input-container grow">
              <span>지면상(B) (m)</span>
              <input className="input" type="number" value="90" disabled={true}/>
            </div>
            <div className="headed-input-container grow">
              <span>해면상(A+B) (m)</span>
              <input className="input" type="number" value="140" disabled={true}/>
            </div>
          </div>
        </div>
      </div>
      <div className="header yellow">크기</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container grow">
          <span>가로 (m)</span>
          <input className="input" type="number" value="24" disabled={true}/>
        </div>
        <div className="headed-input-container grow">
          <span>세로 (m)</span>
          <input className="input" type="number" value="30" disabled={true}/>
        </div>
      </div>
      <div className="header yellow">비행장</div>
      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <select className="select" disabled={true}>
          <option>인천</option>
        </select>
        <div className="headed-input-container">
          <span>거리 (km)</span>
          <input className="input" type="number" value="5" disabled={true}/>
        </div>
      </div>

      <div className="header yellow">방위</div>
      <div className="field">
        <input className="input" value="공항으로부터 북동쪽" disabled={true}/>
      </div>

      <div className="header yellow">군집물체</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <input type="checkbox" checked={false} disabled={true}/>
        단일물체나 서로 떨어져있는 여러개의 물체들이 밀접하게 모여 형성된 하나의 집단
        <Space/>
      </div>

      <div className="header yellow" style={{gridRow: "auto / span 8"}}>항공장애<br/>표시등</div>

      <div className="header yellow">고광도</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>A</span>
          <input className="input" type="number" value="2" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>B</span>
          <input className="input" type="number" value="3" disabled={true}/>
        </div>
        <div>
          <input type="checkbox" checked={true} name="awlHighConcrnt" disabled={true}/>동시섬광
        </div>
        <Space/>
      </div>

      <div className="header yellow">고광도 <br/>설치사진</div>
      <div className="field vertical">
        <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage9)}>[예시]</a>
      </div>
      <div className="header yellow">제품명</div>

      <div className="field vertical">
      </div>

      <div className="field vertical">
        [생략]
      </div>

      <div className="header yellow">중광도 A</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>A</span>
          <input className="input" type="number" value="2" disabled={true}/>
        </div>
        <div>
          <input type="checkbox" checked={false} disabled={true}/>동시섬광
        </div>
        <Space/>
      </div>

      <div className="header yellow">중광도 A <br/>설치사진</div>
      <div className="field vertical">
        <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage8)}>[예시]</a>
      </div>

      <div className="header yellow">제품명</div>
      <div className="field vertical">
      </div>

      <div className="field vertical">
        [생략]
      </div>

      <div className="header yellow">중광도 B, C</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>B</span>
          <input className="input" value="2" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>C</span>
          <input className="input" value="3" type="number" disabled={true}/>
        </div>
        <div>
          <input type="checkbox" checked={false} disabled={true}/>동시섬광
        </div>
        <Space/>
      </div>

      <div className="header yellow">중광도 B, C <br/>설치사진</div>
      <div className="field vertical">
        <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage8)}>[예시]</a>
      </div>
      <div className="header yellow">제품명</div>
      <div className="field vertical">
      </div>

      <div className="field vertical">
        [생략]
      </div>

      <div className="header yellow">저광도</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>A</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>B</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>C</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>D</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>E</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div>
          <input type="checkbox" checked={false} disabled={true}/>동시섬광
        </div>
        <Space/>
      </div>

      <div className="header yellow">저광도 <br/>설치사진</div>
      <div className="field vertical">
        <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage8)}>[예시]</a>
      </div>
      <div className="header yellow">제품명</div>
      <div className="field vertical">
      </div>

      <div className="field vertical">
        [생략]
      </div>

      <div className="header yellow" style={{gridRow: "auto / span 11"}}>항공장애<br/>주간표지</div>
      <div className="header yellow" style={{gridRow: "auto / span 11"}}>주간표지 <br/>종류 택 1</div>

      <div className="field" style={{gridRow: "auto / span 3", gridColumn: "auto / span 2"}}>
        <input type="radio" disabled={true} checked={true}/>
        주간표지 없음(표시등으로 대체)
        <Space/>
      </div>
      <div className="header yellow" style={{gridRow: "auto / span 3"}}>대체</div>
      <div className="field">
        <input type="radio" disabled={true}/>
        고광도 A로 대체
      </div>
      <div className="field">
        <input type="radio" disabled={true} checked={true}/>
        고광도 B로 대체
      </div>
      <div className="field">
        <input type="radio" disabled={true}/>
        중광도 A로 대체
      </div>

      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <input type="radio" disabled={true}/>
        도색
        <Space/>
      </div>
      <div className="header yellow">도색등분</div>
      <div className="field">
        <input className="input" placeholder="예) 7등분, 5/9" disabled={true}/>
      </div>

      <div className="field" style={{gridRow: "auto / span 3", gridColumn: "auto / span 2"}}>
        <input type="radio" disabled={true}/>
        표지구
        <Space/>
      </div>
      <div className="header yellow">적색 개수</div>
      <div className="field">
        <input className="input" type="number" disabled={true}/>
      </div>
      <div className="header yellow">백색 개수</div>
      <div className="field">
        <input className="input" type="number" disabled={true}/>

      </div>
      <div className="header yellow">경간</div>
      <div className="field">
        <input className="input"  type="number" disabled={true}/>
      </div>

      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <input type="radio" disabled={true}/>
        백색
        <Space/>
      </div>

      <div className="field" style={{gridRow: "auto / span 3", gridColumn: "auto / span 2"}}>
        <input type="radio" disabled={true}/>
        깃발
        <Space/>
      </div>
      <div className="header yellow">단일색</div>
      <div className="field">
        <input className="input"  type="number" disabled={true}/>
      </div>
      <div className="header yellow">체크무늬</div>
      <div className="field">
        <input className="input"  type="number" disabled={true}/>
      </div>
      <div className="header yellow">백/주황색</div>
      <div className="field">
        <input className="input"  type="number" disabled={true}/>
      </div>

      <div className="header yellow" style={{gridRow: "auto / span 2"}}>관리 <br/>담당자</div>
      <div className="header yellow">성명</div>
      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <input className="input"  disabled={true} value="관리담당자 명"/>
      </div>
      <div className="header yellow">직책</div>
      <div className="field">
        <input className="input"  disabled={true} value="관리담당자 직책"/>
      </div>
      <div className="header yellow">연락처</div>
      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <input className="input"  disabled={true} value="관리담당자 연락처"/>
      </div>
      <div className="header yellow">팩스</div>
      <div className="field">
        <input className="input"  disabled={true} value="관리담당자 팩스"/>
      </div>

      <div className="header yellow" style={{gridRow: "auto / span 6"}}>제출서류</div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        1. 표시등 종류, 수량 및 설치위치가 포함된 도면&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={onClickOpenPlanSamplePopup}>[예시]</a>
      </div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        2. 주간표지 설치도면 (주간표지 미설치 시 첨부 생략 가능)&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={() => onClickOpenImagePopup(sampleImage2)}>[예시]</a>
      </div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        3. 설치사진 (전체적인 위치를 나타내는 사진)&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={() => onClickOpenImagePopup(sampleImage3)}>[예시]</a>
      </div>
      <div className="field left" style={{gridColumn: "auto / span 5"}}>
        4. 최근 2년 이내에 시험한 성적서 (항공장애 표시등의 제품명을 선택하거나 시험성적서 파일첨부)
      </div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        5. 제어반 사진 및 입력 전압, 전류값&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={() => onClickOpenImagePopup(sampleImage5)}>[예시]</a>
      </div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        6. 표시등 및 제어반 부품 내역&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={() => onClickOpenImagePopup(sampleImage6)}>[예시]</a>
      </div>
    </div>
    <ImagePopup isOpen={isOpenImagePopup} onClose={onCloseImagePopup}
                imgSrc={imagePopupSrc}/>
    <PlanSamplePopup isOpen={isOpenPlanSamplePopup} onClose={onClosePlanSamplePopup}/>
  </>
}

export const ChangeSample = () => {
  const [isOpenImagePopup, setIsOpenImagePopup] = useState<boolean>(false);
  const [imagePopupSrc, setImagePopupSrc] = useState<string>("");
  const [isOpenPlanSamplePopup, setIsOpenPlanSamplePopup] = useState<boolean>(false);

  const onCloseImagePopup = () => setIsOpenImagePopup(false);

  const onClosePlanSamplePopup = () => setIsOpenPlanSamplePopup(false);
  const onClickOpenPlanSamplePopup = () => setIsOpenPlanSamplePopup(true);

  const onClickOpenImagePopup = (imgSrc:string) => {
    setIsOpenImagePopup(true);
    setImagePopupSrc(imgSrc);
  };
  return <>
    <div className="declaration-grid middle-info-box">
      <div className="header yellow" style={{gridColumn:"auto / span 4"}}>변경내용</div>
      <div className="header yellow">변경연월일</div>
      <div className="field">
        <input className="input" type="date" name="chgYmd" value="2021-01-01"/>
      </div>
      <div className="field" style={{gridColumn:"auto /span 6"}}>
        변경한 날로부터 15일 이내에 신고하지 않은 경우 과태료가 부과됩니다. 변경연월일을 다시 확인하시기 바랍니다.
      </div>
      <div className="header yellow" style={{gridRow: "auto / span 8"}}>항공장애<br/>표시등</div>

      <div className="header yellow">고광도</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>A</span>
          <input className="input" type="number" value="2" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>B</span>
          <input className="input" type="number" value="3" disabled={true}/>
        </div>
        <div>
          <input type="checkbox" checked={true} name="awlHighConcrnt" disabled={true}/>동시섬광
        </div>
        <Space/>
      </div>

      <div className="header yellow">고광도 <br/>설치사진</div>
      <div className="field vertical">
        <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage9)}>[예시]</a>
      </div>
      <div className="header yellow">제품명</div>

      <div className="field vertical">
      </div>

      <div className="field vertical">
        [생략]
      </div>

      <div className="header yellow">중광도 A</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>A</span>
          <input className="input" type="number" value="2" disabled={true}/>
        </div>
        <div>
          <input type="checkbox" checked={false} disabled={true}/>동시섬광
        </div>
        <Space/>
      </div>

      <div className="header yellow">중광도 A <br/>설치사진</div>
      <div className="field vertical">
        <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage8)}>[예시]</a>
      </div>

      <div className="header yellow">제품명</div>
      <div className="field vertical">
      </div>

      <div className="field vertical">
        [생략]
      </div>

      <div className="header yellow">중광도 B, C</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>B</span>
          <input className="input" value="2" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>C</span>
          <input className="input" value="3" type="number" disabled={true}/>
        </div>
        <div>
          <input type="checkbox" checked={false} disabled={true}/>동시섬광
        </div>
        <Space/>
      </div>

      <div className="header yellow">중광도 B, C <br/>설치사진</div>
      <div className="field vertical">
        <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage8)}>[예시]</a>
      </div>
      <div className="header yellow">제품명</div>
      <div className="field vertical">
      </div>

      <div className="field vertical">
        [생략]
      </div>

      <div className="header yellow">저광도</div>
      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>A</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>B</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>C</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>D</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div className="headed-input-container" style={{width: "100px"}}>
          <span>E</span>
          <input className="input" value="" type="number" disabled={true}/>
        </div>
        <div>
          <input type="checkbox" checked={false} disabled={true}/>동시섬광
        </div>
        <Space/>
      </div>

      <div className="header yellow">저광도 <br/>설치사진</div>
      <div className="field vertical">
        <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage8)}>[예시]</a>
      </div>
      <div className="header yellow">제품명</div>
      <div className="field vertical">
      </div>

      <div className="field vertical">
        [생략]
      </div>

      <div className="header yellow" style={{gridRow: "auto / span 11"}}>항공장애<br/>주간표지</div>
      <div className="header yellow" style={{gridRow: "auto / span 11"}}>주간표지 <br/>종류 택 1</div>

      <div className="field" style={{gridRow: "auto / span 3", gridColumn: "auto / span 2"}}>
        <input type="radio" disabled={true} checked={true}/>
        주간표지 없음(표시등으로 대체)
        <Space/>
      </div>
      <div className="header yellow" style={{gridRow: "auto / span 3"}}>대체</div>
      <div className="field">
        <input type="radio" disabled={true}/>
        고광도 A로 대체
      </div>
      <div className="field">
        <input type="radio" disabled={true} checked={true}/>
        고광도 B로 대체
      </div>
      <div className="field">
        <input type="radio" disabled={true}/>
        중광도 A로 대체
      </div>

      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <input type="radio" disabled={true}/>
        도색
        <Space/>
      </div>
      <div className="header yellow">도색등분</div>
      <div className="field">
        <input className="input" placeholder="예) 7등분, 5/9" disabled={true}/>
      </div>

      <div className="field" style={{gridRow: "auto / span 3", gridColumn: "auto / span 2"}}>
        <input type="radio" disabled={true}/>
        표지구
        <Space/>
      </div>
      <div className="header yellow">적색 개수</div>
      <div className="field">
        <input className="input" type="number" disabled={true}/>
      </div>
      <div className="header yellow">백색 개수</div>
      <div className="field">
        <input className="input" type="number" disabled={true}/>

      </div>
      <div className="header yellow">경간</div>
      <div className="field">
        <input className="input"  type="number" disabled={true}/>
      </div>

      <div className="field" style={{gridColumn: "auto / span 4"}}>
        <input type="radio" disabled={true}/>
        백색
        <Space/>
      </div>

      <div className="field" style={{gridRow: "auto / span 3", gridColumn: "auto / span 2"}}>
        <input type="radio" disabled={true}/>
        깃발
        <Space/>
      </div>
      <div className="header yellow">단일색</div>
      <div className="field">
        <input className="input"  type="number" disabled={true}/>
      </div>
      <div className="header yellow">체크무늬</div>
      <div className="field">
        <input className="input"  type="number" disabled={true}/>
      </div>
      <div className="header yellow">백/주황색</div>
      <div className="field">
        <input className="input"  type="number" disabled={true}/>
      </div>

      <div className="header yellow" style={{gridRow: "auto / span 2"}}>관리 <br/>담당자</div>
      <div className="header yellow">성명</div>
      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <input className="input"  disabled={true} value="관리담당자 명"/>
      </div>
      <div className="header yellow">직책</div>
      <div className="field">
        <input className="input"  disabled={true} value="관리담당자 직책"/>
      </div>
      <div className="header yellow">연락처</div>
      <div className="field" style={{gridColumn: "auto / span 2"}}>
        <input className="input"  disabled={true} value="관리담당자 연락처"/>
      </div>
      <div className="header yellow">팩스</div>
      <div className="field">
        <input className="input"  disabled={true} value="관리담당자 팩스"/>
      </div>

      <div className="header yellow" style={{gridRow: "auto / span 6"}}>제출서류</div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        1. 표시등 종류, 수량 및 설치위치가 포함된 도면&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={onClickOpenPlanSamplePopup}>[예시]</a>
      </div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        2. 주간표지 설치도면 (주간표지 미설치 시 첨부 생략 가능)&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={() => onClickOpenImagePopup(sampleImage2)}>[예시]</a>
      </div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        3. 설치사진 (전체적인 위치를 나타내는 사진)&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={() => onClickOpenImagePopup(sampleImage3)}>[예시]</a>
      </div>
      <div className="field left" style={{gridColumn: "auto / span 5"}}>
        4. 최근 2년 이내에 시험한 성적서 (항공장애 표시등의 제품명을 선택하거나 시험성적서 파일첨부)
      </div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        5. 제어반 사진 및 입력 전압, 전류값&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={() => onClickOpenImagePopup(sampleImage5)}>[예시]</a>
      </div>
      <div className="field left" style={{gridColumn: "auto / span 3"}}>
        6. 표시등 및 제어반 부품 내역&nbsp;
      </div>
      <div className="field left" style={{gridColumn: "auto / span 2"}}>
        <a className="href" onClick={() => onClickOpenImagePopup(sampleImage6)}>[예시]</a>
      </div>
    </div>
    <ImagePopup isOpen={isOpenImagePopup} onClose={onCloseImagePopup}
                imgSrc={imagePopupSrc}/>
    <PlanSamplePopup isOpen={isOpenPlanSamplePopup} onClose={onClosePlanSamplePopup}/>
  </>
}

export const DemolishSample = () => {
  const [isOpenImagePopup, setIsOpenImagePopup] = useState<boolean>(false);
  const [imagePopupSrc, setImagePopupSrc] = useState<string>("");

  const onCloseImagePopup = () => setIsOpenImagePopup(false);

  const onClickOpenImagePopup = (imgSrc:string) => {
    setIsOpenImagePopup(true);
    setImagePopupSrc(imgSrc);
  };
  
  return <>
    <div className="declaration-grid middle-info-box">
      <div className="header green" style={{gridColumn:"auto / span 4"}}>철거내용</div>
      <div className="header green">철거연월일</div>
      <div className="field">
        <input className="input" type="date" disabled={true} value="2021-01-01"/>
      </div>
      <div className="field" style={{gridColumn:"auto /span 6"}}>
        철거한 날로부터 15일 이내에 신고하지 않은 경우 과태료가 부과됩니다. 철거연월일을 다시 확인하시기 바랍니다.
      </div>
      <div className="header green">제출서류</div>
      <div className="field left" style={{gridColumn:"auto / span 3"}}>
        <div>
          1. 항공장애 표시등 또는 항공장애 주간표지 철거사진<br/>(전체적 위치를 나타내는 것)&nbsp;
        </div>
      </div>
      <div className="field left" style={{gridColumn:"auto / span 2"}}>
        <a className="href" onClick={()=>onClickOpenImagePopup(sampleImage7)}>[예시]</a>
      </div>
    </div>
    <ImagePopup isOpen={isOpenImagePopup} onClose={onCloseImagePopup}
                imgSrc={imagePopupSrc}/>
  </>
}

export const BrokenSample = () => {
  return <>
    <div className="declaration-grid broken-middle-info-box">
      <div className="header green" style={{gridRow:"auto / span 2", gridColumn:"auto / span 2"}}>고장연월일</div>
      <div className="field" style={{gridColumn:"auto / span 3"}} >
        <input className="input" type="date" disabled={true} value="2021-01-01" />
      </div>
      <div className="field" style={{gridColumn:"auto / span 3"}} >
        고장일로부터 6일 이내에 신고하지 않은 경우 과태료가 부과됩니다. 고장연원일을 다시 확인하시기 바랍니다.
      </div>
      <div className="header green" style={{gridRow:"auto / span 4"}}>고장내용</div>
      <div className="header green">종류 및 수량</div>
      <div className="field" style={{gridColumn:"auto / span 3"}} >
        <input className="input" disabled={true} value="LED 전원"/>
      </div>
      <div className="header green">고장내용</div>
      <div className="field" style={{gridColumn:"auto / span 3"}} >
            <textarea className="input" disabled={true} value="부품 파손"/>
      </div>
      <div className="header green">복구 예정일자</div>
      <div className="field" style={{gridColumn:"auto / span 3"}} >
        <input className="input" type="date" disabled={true} value="2021-01-01"/>
      </div>
      <div className="header green">복구 지연사유</div>
      <div className="field">
        <textarea className="input" name="rstrDlyRsn" disabled={true} value="기상악화로 인한 지연"/>
      </div>
      <div className="header green">파일첨부</div>
      <div className="field">
      </div>
      <div className="header green" style={{gridRow:"auto / span 2"}}>통지인</div>
      <div className="header green">연락처</div>
      <div className="field">
        <input className="input" name="tel"
               disabled={true} value="042-111-1111"/>
      </div>
      <div className="header green">직책</div>
      <div className="field">
        <input className="input" disabled={true} value="관리팀장"/>
      </div>
      <div className="header green">팩스</div>
      <div className="field">
        <input className="input" disabled={true} value="042-111-1111"/>
      </div>
      <div className="header green" style={{gridColumn:"auto / span 2"}}></div>
    </div> 
  </>
}

