import React from 'react';
import "./NoticeList.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import {AviationAgency, DeclarationKind, DeclarationStts} from "../../server/code";
import {timeToKoreanDate} from "../../util/timeutil";
import ListPager from "../../comp-shared/ListPager";
import {useSWRNtcList} from "../../server/server";
import moment from "moment/moment";
import {GetNtcRes} from "../../server/ntc/ntcDto";
import {useNavigate} from "react-router-dom";

export const NoticeList = () => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState<number>(0);
  const {data:noticeList} = useSWRNtcList(page,10);
  const onClickNotice = (notice: GetNtcRes) => {
    navigate(`/notice/detail?id=${notice.id}`);
  };
  return (
    <div className="NoticeList">
      <PageTitle title="공지사항"/>
      <table className="table clickable" style={{tableLayout:"fixed"}}>
        <thead>
        <tr>
          <th>제목</th>
          <th style={{width:"150px"}} className="pc-only">작성자</th>
          <th style={{width:"150px"}}>작성일</th>
        </tr>
        </thead>
        <tbody>
        {noticeList?.content.map((notice) => (
          <tr key={notice.id} onClick={()=>onClickNotice(notice)}>
              <td className="title-cell">
                {notice.ttl}
              </td>
            <td className="pc-only">{notice.wrtr}</td>
            <td>{moment(notice.regDt).format("yyyy-MM-DD")}</td>
          </tr>
        ))}
        </tbody>
      </table>

      <ListPager page={page} pageItem={noticeList} onChangePage={setPage}/>
    </div>
  )
}

