import "./Exemption.scss"
import {PageTitle} from "../../comp-shared/PageTitle";
import React, {useEffect, useRef, useState} from "react";
import exEstimateSample2 from "../../resources/sampleimages/exEstimate_sample_2.png"
import exEstimateResult from "../../resources/sampleimages/exEstimate_result.png"
import axios from "axios";
import {ExEstimateReq} from "../../server/user/exEstimateDto";
import html2canvas from "html2canvas";
import {saveAs} from "file-saver";
export const Exemption = () => {
  const [address, setAddress] = useState('')
  const [standardList, setStandardList] =
    useState<ExEstimateReq[]>([])
  const [selectedStandard, setSelectedStandard] =
    useState<ExEstimateReq>()
  const [obstShieldingHgt, setObstShieldingHgt] =
    useState('0')
  const [obstHgt, setObstHgt] =
    useState(0)
  const [distance, setDistance] =
    useState(0)
  const [answerNum, setAnswerNum] =
    useState(0)
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    writeDistance(distance)
  }, [selectedStandard, distance]);

  const searchAddress = async () => {
    if(address === '') {return alert('주소를 입력해주세요.')}
    const res = await axios.post<ExEstimateReq[]>(
      `/exestimate/search`,{address:address})
    setStandardList(res.data)
  }

  const selectStandard = (standard: ExEstimateReq) => {setSelectedStandard(standard)}

  const writeDistance = (distance: number) => {
    if(!selectedStandard) return
    if (distance <= 600) {
      const height = (selectedStandard.hgtFromGrnd - (distance * 0.1)).toFixed(2);
      setObstShieldingHgt(height)
    }
  }

  const check = () => {
    const obstShieldingHgtNum = Number(obstShieldingHgt)
    if (selectedStandard?.hgtFromGrnd === 0 ||
      distance === 0 ||
      obstHgt === 0 ||
      obstShieldingHgtNum === 0) {
      alert('모든사항을 기재해주시기 바랍니다.')
      return
    }

    if(!selectedStandard) return;
    if (obstShieldingHgtNum >= selectedStandard.hgtFromGrnd) {
      alert('설치예정장애물의 차폐면 높이는 기준장애물 높이보다 낮아야합니다.')
      return
    }

    if (distance > 600) {
      alert('600m 이내에서 기준장애물을 선택하시기 바랍니다.')
      return
    }

    if (obstShieldingHgtNum > obstHgt) {
      setAnswerNum(1)
    } else {
      setAnswerNum(2)
    }
  }

  const downloadImg = async () => {
    if(!divRef.current) return
    try {
      const div = divRef.current
      const canvas = await html2canvas(div, {scale: 2})
      canvas.toBlob(blob => {
        if(blob != null) {
          saveAs(blob, "면제대상결과.png")
        }
      })
    } catch (error) {
      console.error("Error converting div to image:", error);
    }
  }

  return (
    <div className="Exemption">
      <PageTitle title="면제대상 판단서비스"/>
      {/*안내문구 박스*/}
      <div className="box t-red">
        면제대상 판단서비스는 「항공장애물 관리 및 비행안전확인 기준」 제31조제1항의 설치면제대상에 해당되는지에 대한 판단을 도와드립니다.
        만일 설치면제대상에 해당된다면 관할 지방항공청 담당자에게 연락하여 설치면제를 위한 행정절차를 진행하시기 바랍니다.
      </div>


      {/*기준문구 박스*/}
      <div className="box" style={{fontWeight: 'bold'}}>
        * 항공장애물 관리 및 비행안전확인 기준
        제31조(표시등과 주간표지의 설치면제) ① 제37조와 제38조의 규정에도 불구하고 다음 각 호에 해당하는 경우에는 표시등을 설치하지 아니할 수 있다.
        <br/>
        1. 표시등이 설치된 물체로부터 반지름 600m 이내에 위치한 물체로서 그 높이가 장애물차폐면보다 낮은 물체
        <br/>
        <img src={exEstimateSample2} alt="면제대상" style={{width: "100%", opacity: "0.75"}}/>
        <br/>
        2. 표시등이 설치된 물체로부터 반지름 45m 이내의 지역에 위치한 물체로서 그 높이가 표시등이 설치된 물체와 같거나 그보다 더 낮은 물체
      </div>


      {/*설치면제판단 질문*/}
      <div>
        <div><h2>※ 설치면제대상 판단</h2></div>
        <div>
          <h3>기준장애물을 선택하시기 바랍니다.</h3>
          <div style={{marginBottom: '5px'}}>
            <input type="text" id="search-address" className="input" maxLength={100}
                   placeholder="구 또는 동을 입력해주세요."
                   onChange={e =>
                     setAddress(e.target.value)}
                   onKeyDown={e =>
                   {if(e.key === 'Enter') searchAddress().then()}}/>
            <button className="button" type="button" style={{marginLeft: '5px'}}
                    onClick={searchAddress}>열람</button>
          </div>
          <div id="search-result"></div>
        </div>

        {/*기준장애물 리스트*/}
        <div style={{maxHeight: '300px', overflow: 'auto'}}>
          <table className={'obstacle-table'}>
            {/*헤더*/}
            <thead>
            <tr>
              <th>명칭</th>
              <th>주소</th>
              <th>높이(m)</th>
            </tr>
            </thead>
            {/*바디*/}
            <tbody>
            {standardList.map((standard, idx) =>
              <tr className={'td-tr'} key={idx}
                  onClick={() => selectStandard(standard)}>
                <td>{standard.obstNm}</td>
                <td>{standard.obstAddr}</td>
                <td>{standard.hgtFromGrnd}</td>
              </tr>
            )}
            </tbody>
          </table>
        </div>


        <div>
          <h3>기준장애물과 설치예정장애물 간 떨어진 거리와 설치예정장애물의 높이를 입력하시기 바랍니다.</h3>
        </div>

        {/*설치면제판단 그림*/}
        <div className={'result-wrapper'} ref={divRef}>
          <div className="result-img" >
            {/*기준 장애물 높이*/}
            <div className={'result-item standard-obstacle-height'} style={{top: "40%", left: "-66px"}}>
              기준<br/>
              장애물<br/>
              높이(m)<br/>
              {selectedStandard?.hgtFromGrnd}
            </div>
            {/*설치 예정 장애물*/}
            <div className={"result-item install-obstacle-height"} style={{top: "45%", right: "235px"}}>
              설치예정장애물<br/>
              높이(m)
              <input type="number" id="obstacle_height" className="input" style={{width: "90px", display: 'block'}}
                     min="0" placeholder="m" value={obstHgt === 0 ? '' : obstHgt}
                     onChange={e => {setObstHgt(Number(e.target.value))}}/>
            </div>
            {/*장애물 차폐면 높이*/}
            <div className={"result-item obstacle-height"} style={{top: "40%", right: "40px"}}>
              장애물<br/>
              차폐면<br/>
              높이(m)<br/>
              {obstShieldingHgt}
            </div>
            {/*기준 장애물*/}
            <div className="result-item standard-obstacle-text" style={{top: "94%", left: "-26px"}}>기준장애물</div>
            <div className="result-item standard-obstacle-number" style={{top: "103%", left: "-26px"}}>{selectedStandard?.obstNm}</div>
            {/*설치 예정 장애물*/}
            <div className="result-item install-obstacle-text" style={{top: "98%", right: "104px"}}>설치예정 장애물</div>
            {/*기준장애물과 설치예정장애물간 떨어진 거리*/}
            <div style={{top: "72%", left: "20%"}} className="result-item stadard-install-between-distance">기준장애물과 설치예정장애물간 떨어진 거리(m)
              <input type="number" id="distance" className="input"
                     style={{width: "30%", display: 'block', textAlign: 'left'}} min="0"
                     max="600" placeholder="m" value={distance === 0 ? '' : distance}
                     onChange={e => setDistance(Number(e.target.value))}/>
            </div>
            {distance > 600 &&
                <div className="result-item t-red over-height-text" style={{top: "98%", left: "171px"}}>*600m 이내에서 기준장애물을 선택하시기 바랍니다.</div>}
            <img className={"obstacle-image"} src={exEstimateResult} style={{width: "85%"}} alt="exEstimate_result"/>
          </div>
        </div>

        <button type="button" className="button" onClick={check}>설치면제대상 확인</button>
      </div>


      {/*설치면제판단 결과*/}
      {answerNum !== 0 &&
          <div style={{maxWidth: '85%'}}>
              <canvas style={{display: 'none'}}></canvas>
              <div>
                  <h2>답변</h2>
                  <div className="detail-block" style={{marginBottom: "15px"}}>
                      <ul>
                        {answerNum === 1 &&
                            <li>
                                <div className="answer-text">설치예정 장애물 높이가 그 지점에서 장애물차폐면 높이보다 작아 설치면제대상입니다.</div>
                            </li>
                        }
                        {answerNum === 2 &&
                            <li>
                                <div className="answer-text">설치예정 장애물 높이가 그 지점에서 장애물차폐면 높이보다 크거나 같아 설치대상입니다.</div>
                            </li>
                        }
                      </ul>
                  </div>
                  <button type="button" className="button" onClick={downloadImg}>이미지
                      다운로드
                  </button>
              </div>
          </div>
      }
    </div>
  )
}