import {QuestionBtn} from "./QuestionBtn";
import React from "react";

export const Question4 = (props: {
  qnaCheck: string
  setQnaCheck: (value: string) => void;
}) => {
  return (
    <div id="question-step3">
      <div>
        <h3>단일물체 또는 군집물체</h3>
        <div className="detail-block">
          <QuestionBtn value={'1'} checkedValue={props.qnaCheck}
                       setCheckedValue={props.setQnaCheck}
                       label={'단일물체'}/>
          <QuestionBtn value={'2'} checkedValue={props.qnaCheck}
                       setCheckedValue={props.setQnaCheck}
                       label={'군집물체'}/>
        </div>
      </div>
    </div>
  )
}